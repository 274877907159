import WikiWebListIcon from '@wikicrm/backoffice/src/assets/icons/WikiWebListIcon'
import { Checkbox, Radio } from 'antd'
import { get, isNull } from 'lodash'
import React from 'react'
import Box from 'ui-box'
import { StyledForm } from './styled'

const SurveyQuestion = ({
  currentQuestionIndex,
  questions,
  answers,
  currentSurveyId,
  currentAttempt,
  saveParticipation,
  getSurveyDetail,
}) => {
  const currentQuestion = questions[currentQuestionIndex] || {}

  const onRadioChange = async (e) => {
    try {
      saveParticipation(currentSurveyId, {
        [currentQuestion.id]: [e.target.value],
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onCheckboxChange = async (e) => {
    try {
      saveParticipation(currentSurveyId, {
        [currentQuestion.id]: e,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const renderAnswers = () => {
    const questionAnswers = answers.filter(
      (answer) => answer.question === currentQuestion.id
    )

    const options = questionAnswers.map((answer) => {
      return {
        label: answer.text,
        value: answer.id,
        key: answer.id,
      }
    })

    switch (currentQuestion.type) {
      case 1:
        return (
          <Radio.Group
            value={get(currentAttempt, `data[${currentQuestion.id}][0]`, [])}
            key={!isNull(currentAttempt) ? currentAttempt.id : '-'}
            options={options}
            onChange={onRadioChange}
          />
        )

      case 2:
        return (
          <Checkbox.Group
            value={get(currentAttempt, `data[${currentQuestion.id}]`, [])}
            key={!isNull(currentAttempt) ? currentAttempt.id : '-'}
            options={options}
            onChange={onCheckboxChange}
          />
        )

      default:
        return null
    }
  }

  return (
    <StyledForm>
      <Box paddingX={80} paddingTop={21} paddingBottom={80}>
        <Box display="flex" alignItems="flex-start">
          <Box display="flex" alignItems="center">
            <Box
              is="button"
              padding={0}
              backgroundColor="white"
              border="none"
              cursor="pointer"
              onClick={getSurveyDetail}
              marginRight={30}
            >
              <WikiWebListIcon
                size={30}
                style={{ transform: 'rotate(180deg)' }}
              />
            </Box>
            <Box fontSize={24} fontWeight={300} marginRight={30}>
              {currentQuestionIndex + 1} / {questions.length}
            </Box>
          </Box>

          <Box fontSize={24} fontWeight={600} flex={1}>
            {currentQuestion.text}
          </Box>
        </Box>

        <Box paddingLeft={60}>{renderAnswers()}</Box>

        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box is="p" fontWeight="700" fontSize="18px" margin="0">
            {currentQuestionIndex + 1} / {questions.length}
          </Box>
          <Remove
            style={{ cursor: 'pointer', width: 40, height: 40 }}
            onClick={getSurveyDetail}
          />
        </Box>
      
        <Box paddingX="20px" overflowY="auto">
          <Box is="h5" fontSize="35px" marginBottom="0">
            {currentQuestionIndex + 1}. {currentQuestion.text}
          </Box>
      
          {renderAnswers()}
        </Box> */}
      </Box>
    </StyledForm>
  )
}

export default SurveyQuestion
