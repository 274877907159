import { isEmpty, isUndefined, values } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import Box from 'ui-box'
import { GlobalContext } from '../../../context/GlobalContext'
import { SurveysContext } from '../../../context/SurveysContext'
import { WikisContext } from '../../../context/WikisContext'
import FileViewer from '../File/FileViewer'

const WikiContentLecture = () => {
  const { state } = useContext(WikisContext)
  const { id } = useParams()
  const { state: globalState } = useContext(GlobalContext)
  const { state: surveysState } = useContext(SurveysContext)
  const currentWiki = state.data[id]
  const currentLecture =
    !isUndefined(currentWiki) &&
    currentWiki.allLectures.data[state.currentLectureId]
  const scale = 1

  const loading = useMemo(
    () =>
      state.genericWikiActionType.name === 'SET_CURRENT_FILE' &&
      state.genericWikiActionType.status === 'FETCHING',
    [state.genericWikiActionType.name, state.genericWikiActionType.status]
  )

  const renderViewer = () => {
    if (
      isEmpty(state.data[state.currentWikiId].allSections.data) &&
      !values(surveysState.data).filter((survey) => survey.wiki === id).length
    ) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Box is="h4" fontSize="20px" fontWeight="700">
            <FormattedMessage id="wiki.noSectionsOrSurveys" />
          </Box>
        </Box>
      )
    }

    if (isEmpty(globalState.activeItem)) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Box is="h4" fontSize="20px" fontWeight="700">
            <FormattedMessage id="wiki.selectContent" />
          </Box>
        </Box>
      )
    }

    return (
      <FileViewer
        currentLecture={currentLecture}
        loading={loading}
        scale={scale}
      />
    )
  }

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      background="white"
      zIndex={10}
    >
      {renderViewer()}
    </Box>
  )
}

export default WikiContentLecture
