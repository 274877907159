import styled from "styled-components";

export const HeaderSection = styled.div`
  min-height: 70px;
  width: 100%;
  border-bottom: ${({withoutDivider, theme}) => withoutDivider ? "none" : `1px solid ${theme.colors.borders}`};
  display: flex;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: center;
  padding: ${props => props.padding || '0'};
  overflow: hidden;
  position: relative;
  background: ${({color}) => (color ? color : "#fff")};
`;

export const HeaderTitleSection = styled(HeaderSection)`
  padding: 0 23px;
  overflow: hidden;
`;

export const HeaderTabSection = styled(HeaderSection)`
  padding: ${props => props.padding || '0'};
  height: ${({ heightAuto }) => heightAuto ? 'auto' : '70px'};
  min-height: ${({ heightAuto }) => heightAuto ? 'none' : '70px'};
  background-color: #FFFFFF;
  width: 100%;
`;