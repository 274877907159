function ariaSVGAttributes({ title, fill, size }) {
  return {
    role: 'img',
    'aria-label': title !== undefined ? '[title]' : null,
    fill,
    size,
  };
}

export default ariaSVGAttributes;
