import Api from '@wikicrm/api'
import { isNull, isUndefined } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { extractLectureData } from '../../../utils/tracking'

const FileIframe = ({ currentLecture }) => {
  const [isLoading, setLoading] = useState(true)

  const receiveMessage = useCallback(
    async (e) => {
      if (isJson(e.data)) {
        try {
          const messageData = JSON.parse(e.data)
          if (!isUndefined(messageData.event)) {
            const trackedEvent = {
              type: messageData.event,
              data: {
                ...extractLectureData(currentLecture),
                ...messageData.properties,
                time: moment().format(),
              },
            }

            await Api.Events.send(trackedEvent)
          }
        } catch (err) {
          console.log(err)
        }
      }
    },
    [currentLecture]
  )

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [receiveMessage])

  const isJson = (item) => {
    const el = typeof item !== 'string' ? JSON.stringify(item) : item
    let parsed

    try {
      parsed = JSON.parse(el)
    } catch (e) {
      return false
    }

    return typeof parsed === 'object' && !isNull(parsed) && !isUndefined(parsed)
  }

  const hideLoading = () => setLoading(false)

  return (
    <>
      {isLoading ? <FormattedMessage id="wiki.loadingIframe" /> : null}

      <iframe
        src={currentLecture.contentUrl}
        onLoad={hideLoading}
        title="Wiki Lecture"
        frameBorder="none"
        width="100%"
        height="100%"
        style={{ display: isLoading ? 'none' : 'block' }}
      />
    </>
  )
}

export default FileIframe
