import { BaseObject } from '../BaseObject'

class GroupsApi extends BaseObject {
  constructor(client) {
    super(client)
  }

  getAll() {
    return this.client.get(`api/v1/groups/`)
  }

  getAllFlat() {
    return this.client.get(`api/v1/groups/?flat=true`)
  }

  create(data) {
    return this.client.post(`api/v1/groups/`, data)
  }

  getDetails(groupId) {
    return this.client.get(`api/v1/groups/${groupId}/`)
  }

  delete(groupId) {
    return this.client.delete(`api/v1/groups/${groupId}/`)
  }

  patch(groupId, data) {
    return this.client.patch(`api/v1/groups/${groupId}/`, data)
  }

  getUsers(id) {
    return this.client.get(`api/v1/groups/${id}/users/`)
  }

  addUsers(id, data) {
    return this.client.post(`api/v1/groups/${id}/users/`, data)
  }

  removeUsers(id, data) {
    return this.client.delete(`api/v1/groups/${id}/users/`, { data: data })
  }

  getRoles(id, clientId) {
    return this.client.get(`api/v1/groups/${id}/role-mappings/clients/${clientId}/`)
  }

  getAvailableRoles(id, clientId) {
    return this.client.get(`api/v1/groups/${id}/role-mappings/clients/${clientId}/available/`)
  }

  getCompositeRoles(id, clientId) {
    return this.client.get(`api/v1/groups/${id}/role-mappings/clients/${clientId}/composite/`)
  }

  assignRoles(id, clientId, data) {
    return this.client.post(`api/v1/groups/${id}/role-mappings/clients/${clientId}/`, data)
  }

  removeRoles(id, clientId, data) {
    return this.client.delete(`api/v1/groups/${id}/role-mappings/clients/${clientId}/`, { data })
  }

  getSupervisors(id) {
    return this.client.get(`api/v1/groups/${id}/supervisors/`)
  }

  addSupervisor(id, data) {
    return this.client.post(`api/v1/groups/${id}/supervisors/`, data)
  }

  removeSupervisor(id, supervisorId) {
    return this.client.delete(`api/v1/groups/${id}/supervisors/${supervisorId}`)
  }
}

export default GroupsApi
