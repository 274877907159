import ErrorBoundary from '@wikicrm/backoffice/src/components/ErrorBoundary'
import { isUndefined } from 'lodash'
import Api from '@wikicrm/api'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { IntlProvider } from 'react-intl'
import Theme from './components/layout/Theme'
import AccessPermitted from './components/shared/AcceddPermitted'
import AccessDenied from './components/shared/AccessDenied'
import { GlobalContext } from './context/GlobalContext'
import KeycloakSessionContext from './context/KeycloakSessionContext'
import messagesEng from './locales/en-US'
import messagesIta from './locales/it-IT'
import { defaultLocale } from './utils/locales'
import { LOGIN_SESSION } from './constants'

const messages = {
  'it-IT': messagesIta,
  'en-US': messagesEng,
}

const App = ({ children, config }) => {
  const { state } = useContext(GlobalContext)
  const [keycloakSessionContext, setKeycloakSessionContext] = useState(null)
  const [hasPermission, setPermission] = useState(null)
  const logout = () => keycloakSessionContext.logout()

  const onAuthSuccess = (kc) => {
    const isOrgAdmin = kc.hasRealmRole('organization_admin')
    console.log('Wiki web running')

    if (isOrgAdmin) {
      setPermission(true)
    } else {
      const permission = kc.hasResourceRole('view-wikis', 'wikicrm-web')
      setPermission(permission)
    }

    Api.Events.send({
      type: LOGIN_SESSION,
      data: {
        time: moment().format(),
      }
    })
  }

  return (
    <IntlProvider
      locale={state.locale}
      messages={
        !isUndefined(messages[state.locale])
          ? messages[state.locale]
          : messages[defaultLocale]
      }
    >
      <KeycloakSessionContext
        keycloakConfig={{
          url: config.get('KEYCLOAK_URL'),
          clientId: config.get('KEYCLOAK_CLIENT_ID'),
          realm: config.get('KEYCLOAK_REALM'),
        }}
        initOptions={{
          checkLoginIframe: false,
        }}
        onLoadUserProfile={onAuthSuccess}
        ref={(el) => setKeycloakSessionContext(el)}
      >
        <ErrorBoundary>
          <Theme logout={logout} kc={keycloakSessionContext}>
            {hasPermission ? (
              <AccessPermitted>{children}</AccessPermitted>
            ) : (
              <AccessDenied />
            )}
          </Theme>
        </ErrorBoundary>
      </KeycloakSessionContext>
    </IntlProvider>
  )
}

export default App
