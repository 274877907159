import React from 'react'
import Box from 'ui-box'

const AccessPermitted = ({ children }) => (
  <Box height="100%" maxHeight="100%" width="100%" overflowY="auto">
    {children}
  </Box>
)

export default AccessPermitted
