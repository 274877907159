import SuccessCheckIcon from '@wikicrm/backoffice/src/assets/icons/SuccessCheck'
import { isNull } from 'lodash'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'

const SurveyPassed = ({ participation, calculate_score }) => {
  const score =
    calculate_score && !isNull(participation) && participation.score
      ? Math.round(participation.score)
      : null

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      height="100%"
      background="white"
      zIndex={20}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width={104} height={104} marginBottom={40}>
        <SuccessCheckIcon width="100%" height="100%" />
      </Box>
      <Box is="p" marginBottom={8} fontSize={24} fontWeight={700} color="#000">
        <FormattedMessage id="surveys.passed.congratulations" />
      </Box>
      {score ? (
        <Box is="p" margin={0} fontSize={24} fontWeight={400} color="#000">
          <FormattedMessage id="surveys.passed.text" values={{ score }} />
        </Box>
      ) : null}
    </Box>
  )
}

export default SurveyPassed
