import { isUndefined } from 'lodash'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { WikisContext } from '../../../context/WikisContext'
import getLectureDownloadUrl from '../../../services/getLectureDownloadUrl'
import * as actions from '../../../store/actions/actions'

const WikiLectureNavigationArrows = ({ theme }) => {
  const { state, dispatch } = useContext(WikisContext)
  const id = state.currentWikiId
  const currentWiki = state.data[id]
  const currentLecture =
    !isUndefined(currentWiki) &&
    currentWiki.allLectures.data[state.currentLectureId]

  const loadPrevLectureFile = () => {
    const orderedLectures = state.data[id].allLectures.ordered.filter(
      (lecture) => lecture.section === currentLecture.section
    )
    const nextSectionId = currentLecture.section
    const index = orderedLectures.findIndex(
      (lecture) => lecture.id === currentLecture.id
    )

    if (!isUndefined(orderedLectures[index - 1])) {
      try {
        const prevLectureId = orderedLectures[index - 1].id
        actions.setCurrentLecture(prevLectureId, nextSectionId, dispatch)
        getLectureDownloadUrl({
          dispatch,
          lectureId: prevLectureId,
          currentWiki,
          id,
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const loadNextLectureFile = () => {
    const orderedLectures = state.data[id].allLectures.ordered.filter(
      (lecture) => lecture.section === currentLecture.section
    )
    const nextSectionId = currentLecture.section
    const index = orderedLectures.findIndex(
      (lecture) => lecture.id === currentLecture.id
    )

    if (!isUndefined(orderedLectures[index + 1])) {
      try {
        const nextLectureId = orderedLectures[index + 1].id
        actions.setCurrentLecture(nextLectureId, nextSectionId, dispatch)
        getLectureDownloadUrl({
          dispatch,
          lectureId: nextLectureId,
          currentWiki,
          id,
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  const getPrevBtnDisabledStatus = () => {
    const orderedLectures = state.data[id].allLectures.ordered.filter(
      (lecture) => lecture.section === currentLecture.section
    )
    const index = orderedLectures.findIndex(
      (lecture) => lecture.id === currentLecture.id
    )

    if (orderedLectures.indexOf(orderedLectures[index]) === 0) {
      return true
    }

    return false
  }

  const getNextBtnDisabledStatus = () => {
    const orderedLectures = state.data[id].allLectures.ordered.filter(
      (lecture) => lecture.section === currentLecture.section
    )
    const index = orderedLectures.findIndex(
      (lecture) => lecture.id === currentLecture.id
    )

    if (
      orderedLectures.indexOf(orderedLectures[index]) ===
      orderedLectures.length - 1
    ) {
      return true
    }

    return false
  }

  return (
    <div>
      <Box
        is="button"
        border="none"
        background="white"
        cursor="pointer"
        color={getPrevBtnDisabledStatus() ? theme.greyLight : theme.greyDark}
        textTransform="uppercase"
        fontWeight={700}
        padding={0}
        marginRight={27}
        onClick={loadPrevLectureFile}
        disabled={getPrevBtnDisabledStatus()}
      >
        <FormattedMessage id="prev" />
      </Box>

      <Box
        is="button"
        border="none"
        background="white"
        cursor="pointer"
        color={getNextBtnDisabledStatus() ? theme.greyLight : theme.greyDark}
        padding={0}
        textTransform="uppercase"
        fontWeight={700}
        onClick={loadNextLectureFile}
        disabled={getNextBtnDisabledStatus()}
      >
        <FormattedMessage id="next" />
      </Box>
    </div>
  )
}

export default withTheme(WikiLectureNavigationArrows)
