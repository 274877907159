import React from 'react'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    info: null,
    eventId: null,
  }

  componentDidCatch = async (error, info) => {
    this.setState({
      hasError: true,
      error,
      info,
    })
  }

  render() {
    const {
      hasError,
      error,
    } = this.state

    if (hasError) {
      return (
        <div>
          <div>
            Error:
          </div>
          <div>
            {error}
          </div>
          <div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
