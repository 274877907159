import { withKeycloakSessionContext } from "../../components/context/KeycloakSessionContext"

const RoleChecker = props => {
  const {
    children,
    kc = null,
    roles = null,
    client = null,
    realms = null,
    checker = null,
    alwaysRender = null,
  } = props

  const ORGANIZATION_ADMIN = 'organization_admin'

  const canRender = () => {
    if (kc && kc.hasRealmRole(ORGANIZATION_ADMIN)) {
      return true
    }

    if (alwaysRender) {
      return true
    }

    if (kc && (!roles || !roles.length) && client) {
      return Boolean(kc.resourceAccess[client])
    }

    if (checker !== null && typeof(checker) === 'function') {
      return checker({ kc, roles, client })
    }
  }

  if (canRender()) {
    return children
  }

  return null
}

export default withKeycloakSessionContext(RoleChecker)
