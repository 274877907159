import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import { NavLink } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Box from 'ui-box'

const Navigation = ({ theme }) => {
  const { id } = useParams()

  const items = useMemo(() => {
    return [
      {
        text: 'Home',
        to: '/',
      },
      {
        text: <FormattedMessage id="wiki.content" />,
        to: `/wiki/${id}`,
      },
      {
        text: <FormattedMessage id="surveys" />,
        to: `/wiki/${id}/survey`,
      },
      {
        text: <FormattedMessage id="surveys.certificates" />,
        to: `/wiki/${id}/certificates`,
      },
      {
        text: <FormattedMessage id="search" />,
        to: `/wiki/${id}/search`,
      },
    ]
  }, [id])

  return (
    <Box is="ul" display="flex" alignItems="center" margin={0} padding={0}>
      {items.map((item) => (
        <Box key={item.to} is="li" listStyle="none" marginRight={18}>
          <Box
            is={NavLink}
            exact
            to={item.to}
            activeStyle={{ color: theme.greyDark }}
            color={theme.greyLight}
            fontWeight={700}
          >
            {item.text}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default withTheme(Navigation)
