import React from 'react';
import icon from '../../core/hoc/icon';

const SvgLecture = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(1.000000, 1.000000)"
        fillRule="nonzero"
        stroke="#C2D1D9"
        strokeWidth={2}
      >
        <polyline points="13 9 17 9 17 17 9 17 9 13" />
        <rect x="5" y="5" width="8" height="8" />
        <rect x="0" y="0" width="22" height="22" />
      </g>
    </g>
  </svg>
);

export default icon(SvgLecture);
