import { BaseObject } from '../BaseObject'

class UsersApi extends BaseObject {
  constructor(client) {
    super(client)
  }

  getAll() {
    return this.client.get(`api/v1/users/`)
  }

  getAllSimple() {
    return this.client.get(`api/v1/users/?simple=true`)
  }

  create(data) {
    return this.client.post(`api/v1/users/`, data)
  }

  count() {
    return this.client.get(`api/v1/users/count/`)
  }

  patch(userId, data) {
    return this.client.patch(`api/v1/users/${userId}/`, data)
  }

  delete(userId) {
    return this.client.delete(`api/v1/users/${userId}/`)
  }

  getDetails(userId) {
    return this.client.get(`api/v1/users/${userId}/`)
  }

  changeStatus(userId, data) {
    return this.client.post(`api/v1/users/${userId}/status/`, data)
  }

  getGroups(id) {
    return this.client.get(`api/v1/users/${id}/groups/`)
  }

  getRoles(id, clientId) {
    return this.client.get(`api/v1/users/${id}/role-mappings/clients/${clientId}/`)
  }

  getAvailableRoles(id, clientId) {
    return this.client.get(`api/v1/users/${id}/role-mappings/clients/${clientId}/available/`)
  }

  getCompositeRoles(id, clientId) {
    return this.client.get(`api/v1/users/${id}/role-mappings/clients/${clientId}/composite/`)
  }

  assignRoles(id, clientId, data) {
    return this.client.post(`api/v1/users/${id}/role-mappings/clients/${clientId}/`, data)
  }

  removeRoles(id, clientId, data) {
    return this.client.delete(`api/v1/users/${id}/role-mappings/clients/${clientId}/`, { data })
  }

  resetPassword(id, data) {
    return this.client.post(`api/v1/users/${id}/reset-password/`, data)
  }

  getRealms(id) {
    return this.client.get(`api/v1/users/${id}/role-mappings/realm/`)
  }

  getAvailableRealms(id) {
    return this.client.get(`api/v1/users/${id}/role-mappings/realm/available/`)
  }

  getCompositeRealms(id) {
    return this.client.get(`api/v1/users/${id}/role-mappings/realm/composite/`)
  }

  assignRealms(id, data) {
    return this.client.post(`api/v1/users/${id}/role-mappings/realm/`, data)
  }

  removeRealms(id, data) {
    return this.client.delete(`api/v1/users/${id}/role-mappings/realm/`, { data })
  }
}

export default UsersApi
