import { keyBy, map, isEmpty, values, uniqBy, differenceBy } from 'lodash'
import { formatMessage } from 'umi-plugin-locale'
import WikiApi from '@wikicrm/api'
import fromCamelToSnake from '../core/utils/fromCamelToSnake'

export const PROJECTS_NAMESPACE = 'projects'
export const FETCH_PROJECTS = `${PROJECTS_NAMESPACE}/fetchProjects`
export const ADD_PROJECT = `${PROJECTS_NAMESPACE}/addNewProjectEffect`
export const SET_CURRENT_PROJECT = `${PROJECTS_NAMESPACE}/setCurrentProject`
export const PATCH_PROJECT = `${PROJECTS_NAMESPACE}/patchProjectEffect`
export const DELETE_PROJECT = `${PROJECTS_NAMESPACE}/deleteProjectEffect`
export const GET_PROJECTS_DETAILS = `${PROJECTS_NAMESPACE}/getProjectDetailsEffect`
export const GET_ALL_LECTURES = `${PROJECTS_NAMESPACE}/getAllLecturesEffect`
export const ASSIGN_PEOPLE = `${PROJECTS_NAMESPACE}/assignPeopleEffect`
export const REMOVE_PEOPLE_FROM_PROJECT = `${PROJECTS_NAMESPACE}/removePeopleFromProjectEffect`
export const PUBLISH_PROJECT = `${PROJECTS_NAMESPACE}/publishProjectEffect`
export const GET_SECTIONS_AND_LECTURES = `${PROJECTS_NAMESPACE}/fetchSectionsAndLecturesEffect`

export const SET_CURRENT_SECTION = `${PROJECTS_NAMESPACE}/setCurrentSection`
export const GET_SECTION_ACTIVITY_FEED = `${PROJECTS_NAMESPACE}/getSectionActivityFeedEffect`
export const ADD_SECTION = `${PROJECTS_NAMESPACE}/addNewSectionEffect`
export const DELETE_SECTION = `${PROJECTS_NAMESPACE}/deleteSectionEffect`
export const PATCH_SECTION = `${PROJECTS_NAMESPACE}/patchSectionEffect`
export const ASSIGN_SECTION = `${PROJECTS_NAMESPACE}/assignSectionEffect`
export const GET_ASSIGNEES_SECTION = `${PROJECTS_NAMESPACE}/getAssigneesSectionEffect`
export const REMOVE_PEOPLE_FROM_SECTION = `${PROJECTS_NAMESPACE}/removePeopleFromSectionEffect`

export const SET_CURRENT_LECTURE = `${PROJECTS_NAMESPACE}/setCurrentLecture`
export const GET_LECTURE_ACTIVITY_FEED = `${PROJECTS_NAMESPACE}/getLectureActivityFeedEffect`
export const ADD_LECTURE = `${PROJECTS_NAMESPACE}/addNewLectureEffect`
export const DELETE_LECTURE = `${PROJECTS_NAMESPACE}/deleteLectureEffect`
export const PATCH_LECTURE = `${PROJECTS_NAMESPACE}/patchLectureEffect`
export const ASSIGN_LECTURE = `${PROJECTS_NAMESPACE}/assignLectureEffect`
export const GET_ASSIGNEES_LECTURE = `${PROJECTS_NAMESPACE}/getAssigneesLectureEffect`
export const REMOVE_PEOPLE_FROM_LECTURE = `${PROJECTS_NAMESPACE}/removePeopleFromLectureEffect`
export const DOWNLOAD_LECTURE_FILE = `${PROJECTS_NAMESPACE}/downloadLectureFileEffect`
export const GET_ASSIGNEES_PROJECT = `${PROJECTS_NAMESPACE}/getAssigneesProjectEffect`

export const DROP_SECTION = `${PROJECTS_NAMESPACE}/dropSectionEffect`
export const DROP_LAST_CHILD_SECTION = `${PROJECTS_NAMESPACE}/dropLastChildSectionEffect`
export const DROP_LECTURE = `${PROJECTS_NAMESPACE}/dropLectureEffect`
export const DROP_LAST_CHILD_LECTURE = `${PROJECTS_NAMESPACE}/dropLastChildLectureEffect`

export const GET_PROJECT_NOTIFICATION_SETTINGS = `${PROJECTS_NAMESPACE}/getNotificationsSettingsEffect`
export const PATCH_PROJECT_NOTIFICATION_SETTINGS = `${PROJECTS_NAMESPACE}/patchNotificationsSettingsEffect`

const initState = {
  data: {},
  status: null,
  currentProjectId: '',
  alreadyFetched: false,
  genericProjectAction: {
    name: null,
    status: null,
    err: {}
  },
}

export default {
  namespace: PROJECTS_NAMESPACE,
  state: initState,
  reducers: {
    setFetchingProjectsStatus(state, { payload: { status } }) {
      return {
        ...state,
        status
      }
    },
    setProjects(state, { payload: projects }) {
      return {
        ...state,
        alreadyFetched: true,
        data: projects,
      }
    },
    setProjectStatus(state, { payload: { id, actionType } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            actionType
          }
        }
      }
    },
    setGenericProjectStatus(state, { payload: { name, status, err } }) {
      return {
        ...state,
        genericProjectAction: {
          name,
          status,
          err,
        }
      }
    },
    setCurrentProject(state, { current, currentProjectId }) {
      return {
        ...state,
        data: {
          ...state.data,
          [current.id]: current,
        },
        currentProjectId
      }
    },
    addNewProject(state, { current, currentProjectId }) {
      return {
        ...state,
        data: {
          [current.id]: current,
          ...state.data,
        },
        currentProjectId
      }
    },
    deleteProject(state, { payload: { currentProjectId } }) {
      const newData = { ...state.data }
      delete newData[currentProjectId]

      return {
        ...state,
        data: newData,
        currentProjectId: ''
      }
    },
    patchProject(state, { payload: { id, patchedProject } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            name: patchedProject.name,
            access_type: patchedProject.access_type,
          }
        },
      }
    },
    updateProjectVersion(state, { id }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            version: state.data[id].version + 1
          }
        },
      }
    },
    assignPeople(state, { payload: { id, option, optionType } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            assignees: {
              ...state.data[id].assignees,
              [optionType]: state.data[id].assignees[optionType].concat(option)
            }
          }
        }
      }
    },
    assignPeopleError(state, { payload: { id, option, optionType } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            assignees: {
              ...state.data[id].assignees,
              [optionType]: state.data[id].assignees[optionType].filter(assignee => assignee.id !== option.id)
            }
          }
        }
      }
    },
    removePeopleFromProject(state, { payload: { id, optionId, optionType } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            assignees: {
              ...state.data[id].assignees,
              [optionType]: state.data[id].assignees[optionType].filter(assignee => assignee.id !== optionId)
            }
          }
        }
      }
    },
    setCurrentSection(state, { currentSection, currentSectionId }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allSections: {
              ...state.data[state.currentProjectId].allSections,
              data: {
                ...state.data[state.currentProjectId].allSections.data,
                [currentSectionId]: {
                  ...state.data[state.currentProjectId].allSections.data[currentSectionId],
                  ...currentSection
                }
              },
              currentSectionId
            },
            sectionsForSelect: {
              ...state.data[state.currentProjectId].sectionsForSelect,
              [currentSectionId]: currentSection
            }
          },
        },
      }
    },
    setCurrentLecture(state, { currentLecture, currentLectureId }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allLectures: {
              ...state.data[state.currentProjectId].allLectures,
              data: {
                ...state.data[state.currentProjectId].allLectures.data,
                [currentLectureId]: {
                  ...state.data[state.currentProjectId].allLectures.data[currentLectureId],
                  ...currentLecture
                }
              },
              currentLectureId
            }
          },
        },
      }
    },
    fetchSectionsAndLectures(state, { sections, lectures }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allSections: {
              ...state.data[state.currentProjectId].allSections,
              data: {
                ...state.data[state.currentProjectId].allSections.data,
                ...sections
              },
            },
            allLectures: {
              ...state.data[state.currentProjectId].allLectures,
              data: {
                ...state.data[state.currentProjectId].allLectures.data,
                ...lectures
              },
            }
          },
        },
      }
    },
    deleteSection(state, { id, projectId }) {
      const newData = { ...state.data }
      delete newData[projectId].allSections.data[id]
      delete newData[projectId].sectionsForSelect[id]

      return {
        ...state,
        data: {
          ...newData,
          [projectId]: {
            ...newData[projectId],
            allSections: {
              ...newData[projectId].allSections,
              currentSectionId: ''
            },
            sectionsForSelect: newData[projectId].sectionsForSelect
          }
        }
      }
    },
    deleteLecture(state, { id, projectId }) {
      const newData = { ...state.data }
      delete newData[projectId].allLectures.data[id]

      return {
        ...state,
        data: {
          ...newData,
          [projectId]: {
            ...newData[projectId],
            allLectures: {
              ...newData[projectId].allLectures,
              currentSectionId: ''
            }
          }
        }
      }
    },
    setSectionsForSelect(state, { id, sectionsForSelect }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            sectionsForSelect,
          },
        },
      }
    },
    assignSection(state, { currentSectionId, optionType, option }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allSections: {
              ...state.data[state.currentProjectId].allSections,
              data: {
                ...state.data[state.currentProjectId].allSections.data,
                [currentSectionId]: {
                  ...state.data[state.currentProjectId].allSections.data[currentSectionId],
                  assignees: {
                    ...state.data[state.currentProjectId].allSections.data[currentSectionId].assignees,
                    [optionType]: {
                      ...state.data[state.currentProjectId].allSections.data[currentSectionId].assignees[optionType],
                      [option.id]: option,
                    }
                  }
                }
              },
              currentSectionId
            }
          },
        },
      }
    },
    removePeopleFromSection(state, { payload: { sectionId, optionId, optionType } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allSections: {
              ...state.data[state.currentProjectId].allSections,
              data: {
                ...state.data[state.currentProjectId].allSections.data,
                [sectionId]: {
                  ...state.data[state.currentProjectId].allSections.data[sectionId],
                  assignees: {
                    ...state.data[state.currentProjectId].allSections.data[sectionId].assignees,
                    [optionType]: values(state.data[state.currentProjectId].allSections.data[sectionId].assignees[optionType]).filter(assignee => assignee.id !== optionId)
                  }
                }
              },
              sectionId
            }
          },
        },
      }
    },
    assignLecture(state, { currentLectureId, optionType, option }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allLectures: {
              ...state.data[state.currentProjectId].allLectures,
              data: {
                ...state.data[state.currentProjectId].allLectures.data,
                [currentLectureId]: {
                  ...state.data[state.currentProjectId].allLectures.data[currentLectureId],
                  assignees: {
                    ...state.data[state.currentProjectId].allLectures.data[currentLectureId].assignees,
                    [optionType]: {
                      ...state.data[state.currentProjectId].allLectures.data[currentLectureId].assignees[optionType],
                      [option.id]: option,
                    }
                  }
                }
              },
              currentLectureId
            }
          },
        },
      }
    },
    removePeopleFromLecture(state, { payload: { lectureId, optionId, optionType } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allLectures: {
              ...state.data[state.currentProjectId].allLectures,
              data: {
                ...state.data[state.currentProjectId].allLectures.data,
                [lectureId]: {
                  ...state.data[state.currentProjectId].allLectures.data[lectureId],
                  assignees: {
                    ...state.data[state.currentProjectId].allLectures.data[lectureId].assignees,
                    [optionType]: values(state.data[state.currentProjectId].allLectures.data[lectureId].assignees[optionType]).filter(assignee => assignee.id !== optionId)
                  }
                }
              },
              lectureId
            }
          },
        },
      }
    },
    dropSection(state, { sectionId, target, position }) {
      const newData = values({ ...state.data[state.currentProjectId].allSections.data })
      const draggedIndex = newData.indexOf(newData.find(sec => sec.id === sectionId))
      const draggedSec = newData[draggedIndex]
      const targetIndex = newData.indexOf(newData.find(sec => sec.id === target))
      const targetSec = newData[targetIndex]

      if (position === 'left' && draggedIndex > targetIndex) {
        newData.splice(targetIndex, 0, draggedSec)
      }

      if (position === 'left' && draggedIndex < targetIndex) {
        newData.splice(draggedIndex, 1)
        newData.splice(targetIndex - 1, 0, draggedSec)
      }

      if (position === 'right' && draggedIndex > targetIndex) {
        newData.splice(draggedIndex, 1)
        newData.splice(targetIndex + 1, 0, draggedSec)
      }

      if (position === 'right' && draggedIndex < targetIndex) {
        newData.splice(draggedIndex, 1)
        newData.splice(targetIndex, 0, draggedSec)
      }

      draggedSec.parent = targetSec.parent

      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allSections: {
              ...state.data[state.currentProjectId].allSections,
              data: keyBy(newData, 'id')
            },
          },
        },
      }
    },
    dropLastChildSection(state, { sectionId, target }) {
      const newData = values({ ...state.data[state.currentProjectId].allSections.data })
      const draggedIndex = newData.indexOf(newData.find(sec => sec.id === sectionId))
      const draggedSec = newData[draggedIndex]

      newData.splice(draggedIndex, 1)
      draggedSec.parent = target
      newData.push(draggedSec)

      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allSections: {
              ...state.data[state.currentProjectId].allSections,
              data: keyBy(newData, 'id')
            },
          },
        },
      }
    },
    dropLecture(state, { lectureId, target, position, sectionId }) {
      const newData = values({ ...state.data[state.currentProjectId].allLectures.data })
      const draggedIndex = newData.indexOf(newData.find(lec => lec.id === lectureId))
      const draggedLec = newData[draggedIndex]
      const targetIndex = newData.indexOf(newData.find(lec => lec.id === target))

      if (position === 'left' && draggedIndex > targetIndex) {
        newData.splice(targetIndex, 0, draggedLec)
      }

      if (position === 'left' && draggedIndex < targetIndex) {
        newData.splice(draggedIndex, 1)
        newData.splice(targetIndex - 1, 0, draggedLec)
      }

      if (position === 'right' && draggedIndex > targetIndex) {
        newData.splice(draggedIndex, 1)
        newData.splice(targetIndex + 1, 0, draggedLec)
      }

      if (position === 'right' && draggedIndex < targetIndex) {
        newData.splice(draggedIndex, 1)
        newData.splice(targetIndex, 0, draggedLec)
      }

      draggedLec.section = sectionId

      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allLectures: {
              ...state.data[state.currentProjectId].allLectures,
              data: keyBy(newData, 'id')
            }
          },
        },
      }
    },
    dropLastChildLecture(state, { lectureId, order, sectionId }) {
      const newData = values({ ...state.data[state.currentProjectId].allLectures.data })
      const draggedIndex = newData.indexOf(newData.find(lec => lec.id === lectureId))
      const draggedLec = newData[draggedIndex]
      const sectionLectures = newData.filter(lec => lec.section === sectionId)

      newData.splice(draggedIndex, 1)
      sectionLectures.splice(sectionLectures.length, 0, draggedLec)
      draggedLec.section = sectionId

      const diff = differenceBy(newData, sectionLectures, 'section')

      return {
        ...state,
        data: {
          ...state.data,
          [state.currentProjectId]: {
            ...state.data[state.currentProjectId],
            allLectures: {
              ...state.data[state.currentProjectId].allLectures,
              data: keyBy(diff.concat(sectionLectures), 'id')
            },
          },
        },
      }
    },
    setNotificationsSettingsOptions(state, { payload: { id, actions, data } }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            notificationsSettings: {
              alreadyFetched: true,
              actions,
              data,
            },
          }
        }
      }
    },
    setProjectActivities(state, { payload: { id, totalItems, data }  }) {
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            activities: {
              totalItems,
              data,
            },
          }
        }
      }
    }
  },
  effects: {
    fetchProjects: [
      function* (action, { put }) {
        yield put({
          type: 'setFetchingProjectsStatus',
          payload: {
            status: 'FETCHING'
          }
        })

        try {
          const response = yield WikiApi.Wikis.getAll()

          yield put({
            type: 'setFetchingProjectsStatus',
            payload: {
              status: 'SUCCESS'
            }
          })

          const data = map(response.data, obj => {
            obj.actionType = {
              name: '',
              status: '',
              err: {}
            }

            return obj
          })

          yield put({
            type: 'setProjects',
            payload: keyBy(data, 'id')
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setFetchingProjectsStatus',
            payload: {
              status: 'ERROR'
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    patchProjectEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'PATCH_PROJECT',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Wikis.patch(action.id, fromCamelToSnake(action.payload))

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'PATCH_PROJECT',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'patchProject',
            payload: {
              id: action.id,
              patchedProject: response.data
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'PATCH_PROJECT',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    addNewProjectEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericProjectStatus',
          payload: {
            type: 'ADD_NEW_PROJECT',
            status: 'RUNNING',
            err: {}
          }
        })

        try {
          const response = yield WikiApi.Wikis.create(action.payload)

          const currentProject = {
            ...response.data,
            actionType: {
              name: '',
              status: '',
              err: {}
            }
          }

          yield put({
            type: 'setGenericProjectStatus',
            payload: {
              type: 'ADD_NEW_PROJECT',
              status: 'SUCCESS',
              err: {}
            }
          })

          yield put({
            type: 'addNewProject',
            current: currentProject,
            currentProjectId: currentProject.id
          })

          if (action.callback) {
            action.callback()
          }

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setGenericProjectStatus',
            payload: {
              type: 'ADD_NEW_PROJECT',
              status: 'ERROR',
              err
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    deleteProjectEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.currentProjectId,
            actionType: {
              name: 'DELETE_PROJECT',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield WikiApi.Wikis.delete(action.currentProjectId)

          yield put({
            type: 'deleteProject',
            payload: {
              currentProjectId: action.currentProjectId,
            }
          })

          if (action.callback) {
            action.callback()
          }

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.currentProjectId,
              actionType: {
                name: 'DELETE_PROJECT',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    getProjectActivities: [
      function* (action, { put }) {
        try {
          const activities = yield WikiApi.Wikis.activity(action.id)
          yield put({
            type: 'setProjectActivities',
            payload: {
              id: action.id,
              totalItems: activities.data.totalItems,
              data: keyBy(activities.data.items, 'id')
            }
          })
        } catch(err) {
          console.log(err)
        }
      },
      { type: 'takeLatest' }
    ],
    getAllLecturesEffect: [
      function* (action, { put, select }) {
        try {
          const id = yield select(state => state.projects.currentProjectId)
          const current = yield select(state => state.projects.data[id])
          const lectures = yield WikiApi.Wikis.getAllLecture(id)
          const lecturesData = lectures.data.map(lecture => {
            lecture.actionType = {
              name: '',
              status: '',
              err: {},
            }

            return lecture
          })

          yield put({
            type: 'setCurrentProject',
            current: {
              ...current,
              allLectures: {
                data: keyBy(lecturesData, 'id'),
                currentLectureId: ''
              }
            },
            currentProjectId: id,
          })

          if (action.callback) {
            action.callback()
          }

        } catch (err) {
          yield put({
            type: 'setGenericProjectStatus',
            payload: {
              type: 'GET_PROJECT_LECTURES',
              status: 'ERROR',
              err,
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    getProjectDetailsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericProjectStatus',
          payload: {
            type: 'GET_PROJECT_DETAILS',
            status: 'FETCHING',
            err: {}
          }
        })

        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'GET_PROJECT_DETAILS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Wikis.get(action.id)

          if (response.data.status === 'ready') {
            const sections = yield WikiApi.Wikis.getAllSection(action.id)

            response.data.actionType = {
              name: '',
              status: '',
              err: {},
            }

            const sectionsData = sections.data.map(section => {
              section.actionType = {
                name: '',
                status: '',
                err: {},
              }

              return section
            })

            response.data.allSections = {
              data: keyBy(sectionsData, 'id'),
              currentSectionId: ''
            }

            response.data.allLectures = {
              data: {},
              currentLectureId: ''
            }

            yield put({
              type: 'setCurrentProject',
              current: {
                ...response.data,
              },
              currentProjectId: response.data.id
            })

            yield put({
              type: 'setSectionsForSelect',
              sectionsForSelect: keyBy(sections.data, 'id'),
            })
          } else {
            yield put({
              type: 'setCurrentProject',
              current: {
                ...response.data,
              },
              currentProjectId: response.data.id
            })
          }


          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_PROJECT_DETAILS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'setGenericProjectStatus',
            payload: {
              type: 'GET_PROJECT_DETAILS',
              status: 'SUCCESS',
              err: {}
            }
          })

          if (action.callback) {
            action.callback()
          }

        } catch(err) {
          console.log(err)

          if (action.errorCallback) {
            action.errorCallback()
          }

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_PROJECT_DETAILS',
                status: 'ERROR',
                err: {}
              }
            }
          })

          yield put({
            type: 'setGenericProjectStatus',
            payload: {
              type: 'GET_PROJECT_DETAILS',
              status: 'ERROR',
              err: {}
            }
          })
        } finally {
          yield put({
            type: 'getProjectActivities',
            id: action.id,
          })
        }
      },
      { type: 'takeLatest' }
    ],
    assignPeopleEffect: [
      function* (action, { put }) {

        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'ASSIGN_PEOPLE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const assignees = { ...action.payload.assignees }

          if (action.payload.optionType === 'users') {
            assignees.users = assignees.users
              .filter(user => user)
              .map(user => user.id)
              .concat(action.payload.optionId)
            assignees.groups = assignees.groups.map(group => group.id)

          } else if (action.payload.optionType === 'groups') {
            assignees.groups = assignees.groups
              .filter(group => group)
              .map(group => group.id)
              .concat(action.payload.optionId)
            assignees.users = assignees.users.map(user => user.id)
          }

          yield put({
            type: 'assignPeople',
            payload: {
              id: action.id,
              option: action.payload.option,
              optionType: action.payload.optionType
            }
          })

          yield WikiApi.Wikis.assign(action.id, assignees)

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ASSIGN_PEOPLE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ASSIGN_PEOPLE',
                status: 'ERROR',
                err: {}
              }
            }
          })

          yield put({
            type: 'assignPeopleError',
            payload: {
              id: action.id,
              option: action.payload.option,
              optionType: action.payload.optionType
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    removePeopleFromProjectEffect: [
      function* (action, { put }) {

        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'REMOVE_PEOPLE_FROM_PROJECT',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const assignees = { ...action.payload.assignees }

          if (action.payload.optionType === 'users') {
            assignees.users = assignees.users
            .filter(user => user.id !== action.payload.optionId)
            .map(user => user.id)
            assignees.groups = assignees.groups.map(group => group.id)

          } else if (action.payload.optionType === 'groups') {
            assignees.groups = assignees.groups
              .filter(group => group.id !== action.payload.optionId)
              .map(group => group.id)
              assignees.users = assignees.users.map(user => user.id)
          }

          yield put({
            type: 'removePeopleFromProject',
            payload: {
              id: action.id,
              optionId: action.payload.optionId,
              optionType: action.payload.optionType
            }
          })

          yield WikiApi.Wikis.assign(action.id, assignees)

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'REMOVE_PEOPLE_FROM_PROJECT',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'REMOVE_PEOPLE_FROM_PROJECT',
                status: 'ERROR',
                err: {}
              }
            }
          })

          yield put({
            type: 'assignPeople',
            payload: {
              id: action.id,
              option: action.payload.option,
              optionType: action.payload.optionType
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    addNewSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'ADD_NEW_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Wikis.addSection(action.id, fromCamelToSnake(action.payload))
          yield WikiApi.Sections.assign(response.data.id, action.assignees)

          yield put({
            type: 'setCurrentSection',
            currentSection: response.data,
            currentSectionId: response.data.id,
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ADD_NEW_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: '',
                status: '',
                err: {}
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'projects.section.created' })
            )
          }

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ADD_NEW_SECTION',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    addNewLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.projectId,
            actionType: {
              name: 'ADD_NEW_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Wikis.addLecture(
            action.projectId,
            action.sectionId,
            action.formData
          )

          if (Number(action.formData.get('access_type')) === 0) {
            yield WikiApi.Lectures.assign(response.data.id, action.lectureAssignees)
          }

          response.data.section = action.sectionId
          if (!isEmpty(action.formData.get('file'))) {
            response.data.has_file = true
            response.data.original_file_name = action.formData.get('file').name.split('.')[0]
            response.data.file_extension = action.formData.get('file').name.split('.')[1]
          }

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'ADD_NEW_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'setCurrentLecture',
            id: action.projectId,
            currentLecture: response.data,
            currentLectureId: response.data.id,
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.projectId
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: '',
                status: '',
                err: {}
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'projects.lecture.created' })
            )
          }

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'ADD_NEW_LECTURE',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    publishProjectEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'PUBLISH_PROJECT',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {

          yield WikiApi.Wikis.publish(action.id, { name: action.current.name })

          const publishedProject = {
            ...action.current,
            is_published: true,
            published_version: action.current.version
          }

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'PUBLISH_PROJECT',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        yield put({
          type: 'setCurrentProject',
          current: publishedProject,
          currentProjectId: action.id
        })

        if (action.callback) {
          action.callback(true, 'success', formatMessage({ id: 'projects.detail.published' }))
        }

        } catch(err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'PUBLISH_PROJECT',
                status: 'ERROR',
                err: {}
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    deleteSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.projectId,
            actionType: {
              name: 'DELETE_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield WikiApi.Wikis.deleteSection(action.id)

          yield put({
            type: 'deleteSection',
            id: action.id,
            projectId: action.projectId,
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'DELETE_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.projectId
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: '',
                status: '',
                err: {}
              }
            }
          })

        } catch(err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'DELETE_SECTION',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    deleteLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.projectId,
            actionType: {
              name: 'DELETE_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {

          yield WikiApi.Lectures.delete(action.id)

          yield put({
            type: 'deleteLecture',
            id: action.id,
            projectId: action.projectId,
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'DELETE_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.projectId
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: '',
                status: '',
                err: {}
              }
            }
          })

        } catch(err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'DELETE_LECTURE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    fetchSectionsAndLecturesEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.projectId,
            actionType: {
              name: 'FETCH_SECTIONS_AND_LECTURES',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        yield put ({
          type: 'setCurrentSection',
          currentSection: {
            ...action.section,
            assignees: action.section.assignees || {},
            actionType: {
              name: 'EXPAND_SECTION',
              status: 'RUNNING',
              err: {},
            }
          },
          currentSectionId: action.section.id,
        })

        try {
          const sections = yield WikiApi.Wikis.getSections(action.projectId, action.section.id)
          const lectures = yield WikiApi.Wikis.getLectures(action.projectId, action.section.id)

          const sectionsData = sections.data.map(section => {
            section.actionType = {
              name: '',
              status: '',
              err: {},
            }

            return section
          })

          const allSections = keyBy(sectionsData, 'id')

          const lecturesData = lectures.data.map(lecture => {
            lecture.actionType = {
              name: '',
              status: '',
              err: {},
            }

            return lecture
          })

          const allLectures = keyBy(lecturesData, 'id')

          yield put({
            type: 'fetchSectionsAndLectures',
            sections: allSections,
            lectures: allLectures
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'FETCH_SECTIONS_AND_LECTURES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put ({
            type: 'setCurrentSection',
            assignees: action.section.assignees || {},
            currentSection: {
              ...action.section,
              actionType: {
                name: 'EXPAND_SECTION',
                status: 'SUCCESS',
                err: {},
              }
            },
            currentSectionId: action.section.id,
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'FETCH_SECTIONS_AND_LECTURES',
                status: 'ERROR',
                err
              }
            }
          })

          yield put ({
            type: 'setCurrentSection',
            assignees: action.section.assignees || {},
            currentSection: {
              ...action.section,
              actionType: {
                name: 'EXPAND_SECTION',
                status: 'ERROR',
                err,
              }
            },
            currentSectionId: action.section.id,
          })
        }
      },
      { type: 'takeEvery' }
    ],
    patchLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.projectId,
            actionType: {
              name: 'PATCH_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Lectures.patch(action.id, action.formData)
          response.data.section = action.sectionId

          if (action.fileRemoved) {
            response.data.original_file_name = undefined
            response.data.file_extension = undefined
          } else {
            if (action.formData.get('file')) {
              response.data.original_file_name = action.formData.get('file').name.split('.')[0]
              response.data.file_extension = action.formData.get('file').name.split('.')[1]
            } else if (action.lecture.original_file_name && action.lecture.file_extension) {
              response.data.original_file_name = action.lecture.original_file_name
              response.data.file_extension = action.lecture.file_extension
            }
          }

          if (action.previewRemoved) {
            action.lecture.preview_file_name = undefined
            action.lecture.preview_file_extension = undefined
          } else {
            if (action.formData.get('preview_file')) {
              action.lecture.preview_file_name = action.formData.get('preview_file').name.split('.')[0]
              action.lecture.preview_file_extension = action.formData.get('preview_file').name.split('.')[1]
            } else if (action.lecture.preview_file_name && action.lecture.preview_file_extension) {
              action.lecture.preview_file_name = action.lecture.preview_file_name
              action.lecture.preview_file_extension = action.lecture.preview_file_extension
            }
          }

          yield put({
            type: 'setCurrentLecture',
            currentLecture: {
              ...response.data,
              assignees: {}
            },
            currentLectureId: action.id,
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'PATCH_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.projectId
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: '',
                status: '',
                err: {}
              }
            }
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'PATCH_LECTURE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    patchSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.projectId,
            actionType: {
              name: 'PATCH_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Sections.patch(action.id, action.section)
          response.data.actionType = {
            name: 'PATCH_SECTION',
            status: 'SUCCESS',
            err: {}
          }

          yield put({
            type: 'setCurrentSection',
            currentSection: response.data,
            currentSectionId: response.data.id,
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'PATCH_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.projectId
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: '',
                status: '',
                err: {}
              }
            }
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.projectId,
              actionType: {
                name: 'PATCH_SECTION',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    getAssigneesSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'GET_ASSIGNEES_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Sections.getAssignees(action.section.id)
          const currentSection = {
            ...action.section,
            assignees: {
              users: keyBy(response.data.assignees.users, 'id'),
              groups: keyBy(response.data.assignees.groups, 'id')
            }
          }

          yield put({
            type: 'setCurrentSection',
            currentSection,
            currentSectionId: action.section.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_ASSIGNEES_SECTION',
                status: 'SUCCESS',
              }
            }
          })

        } catch (err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_ASSIGNEES_SECTION',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    assignSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'ASSIGN_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'assignSection',
            option: action.newAssignee,
            optionType: action.optionType,
            currentSectionId: action.currentSectionId,
          })

          yield WikiApi.Sections.assign(action.currentSectionId, action.assignees)

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ASSIGN_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ASSIGN_SECTION',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removePeopleFromSectionEffect: [
      function* (action, { put }) {

        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'REMOVE_PEOPLE_FROM_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          delete action.payload.assignees[action.payload.optionType][action.payload.optionId]
          const assignees = {
            users: values(action.payload.assignees.users).map(option => option.id),
            groups: values(action.payload.assignees.groups).map(option => option.id)
          }

          yield put({
            type: 'removePeopleFromSection',
            payload: {
              sectionId: action.sectionId,
              optionId: action.payload.optionId,
              optionType: action.payload.optionType
            }
          })

          yield WikiApi.Sections.assign(action.sectionId, assignees)

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'REMOVE_PEOPLE_FROM_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'REMOVE_PEOPLE_FROM_SECTION',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    getAssigneesLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'GET_ASSIGNEES_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Lectures.getAssignees(action.lecture.id)
          const currentLecture = {
            ...action.lecture,
            assignees: {
              users: keyBy(response.data.assignees.users, 'id'),
              groups: keyBy(response.data.assignees.groups, 'id')
            }
          }

          yield put({
            type: 'setCurrentLecture',
            currentLecture,
            currentLectureId: action.lecture.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_ASSIGNEES_LECTURE',
                status: 'SUCCESS',
              }
            }
          })

        } catch (err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_ASSIGNEES_LECTURE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    assignLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'ASSIGN_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'assignLecture',
            option: action.newAssignee,
            optionType: action.optionType,
            currentLectureId: action.currentLectureId,
          })

          yield WikiApi.Lectures.assign(action.currentLectureId, action.assignees)

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ASSIGN_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'ASSIGN_LECTURE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removePeopleFromLectureEffect: [
      function* (action, { put }) {

        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'REMOVE_PEOPLE_FROM_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          delete action.payload.assignees[action.payload.optionType][action.payload.optionId]
          const assignees = {
            users: values(action.payload.assignees.users).map(option => option.id),
            groups: values(action.payload.assignees.groups).map(option => option.id)
          }

          yield put({
            type: 'removePeopleFromLecture',
            payload: {
              lectureId: action.lectureId,
              optionId: action.payload.optionId,
              optionType: action.payload.optionType
            }
          })

          yield WikiApi.Lectures.assign(action.lectureId, assignees)

          yield put({
            type: 'updateProjectVersion',
            id: action.id
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'REMOVE_PEOPLE_FROM_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'REMOVE_PEOPLE_FROM_LECTURE',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    dropSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'DROP_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })
        try {
          yield put({
            type: 'dropSection',
            sectionId: action.payload.sectionId,
            target: action.payload.data.target,
            position: action.payload.data.position,
          })

          yield WikiApi.Sections.move(action.payload.sectionId, action.payload.data)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.payload.id
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_SECTION',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    dropLastChildSectionEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'DROP_LAST_CHILD_SECTION',
              status: 'RUNNING',
              err: {}
            }
          }
        })
        try {
          yield put({
            type: 'dropLastChildSection',
            sectionId: action.payload.sectionId,
            target: action.payload.data.target,
          })

          yield WikiApi.Sections.move(action.payload.sectionId, action.payload.data)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_LAST_CHILD_SECTION',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.payload.id
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_LAST_CHILD_SECTION',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    dropLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'DROP_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })
        try {
          yield put({
            type: 'dropLecture',
            lectureId: action.payload.lectureId,
            position: action.payload.position,
            target: action.payload.target,
            sectionId: action.payload.data.section,
          })

          yield WikiApi.Lectures.move(action.payload.lectureId, action.payload.data)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.payload.id
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_LECTURE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    dropLastChildLectureEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'DROP_LAST_CHILD_LECTURE',
              status: 'RUNNING',
              err: {}
            }
          }
        })
        try {
          yield put({
            type: 'dropLastChildLecture',
            lectureId: action.payload.lectureId,
            order: action.payload.data.order,
            sectionId: action.payload.data.section,
          })

          yield WikiApi.Lectures.move(action.payload.lectureId, action.payload.data)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_LAST_CHILD_LECTURE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'updateProjectVersion',
            id: action.payload.id
          })

        } catch (err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DROP_LAST_CHILD_LECTURE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    getSectionActivityFeedEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setCurrentSection',
          currentSection: action.currentSection,
          currentSectionId: action.currentSectionId,
        })

        try {
          const activities = yield WikiApi.Sections.activity(action.currentSectionId)

          yield put({
            type: 'setCurrentSection',
            currentSection: {
              ...action.currentSection,
              activities: {
                totalItems: activities.data.totalItems,
                data: keyBy(activities.data.items, 'id')
              }
            },
            currentSectionId: action.currentSectionId,
          })
        } catch(err) {
          console.log(err)
        }
      },
      { type: 'takeEvery' }
    ],
    getLectureActivityFeedEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setCurrentLecture',
          currentLecture: action.currentLecture,
          currentLectureId: action.currentLectureId,
        })

        try {
          const activities = yield WikiApi.Lectures.activity(action.currentLectureId)

          yield put({
            type: 'setCurrentLecture',
            currentLecture: {
              ...action.currentLecture,
              activities: {
                totalItems: activities.data.totalItems,
                data: keyBy(activities.data.items, 'id')
              }
            },
            currentLectureId: action.currentLectureId,
          })
        } catch(err) {
          console.log(err)
        }
      },
      { type: 'takeEvery' }
    ],
    downloadLectureFileEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'DOWNLOAD_LECTURE_FILE',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Lectures.getDownloadUrl(action.payload.id)

          if (!isEmpty(response.data.url)) {
            window.open(response.data.url, '_blank')
          }

          if (!isEmpty(response.data.preview_url)) {
            window.open(response.data.preview_url, '_blank')
          }

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DOWNLOAD_LECTURE_FILE',
                status: 'SUCCESS',
                err: {}
              }
            }
          })
        } catch(err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'DOWNLOAD_LECTURE_FILE',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    getNotificationsSettingsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'GET_PROJECT_NOTIFICATION_SETTINGS',
              status: 'FETCHING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Wikis.getSettingsNotifications(action.payload.unique_id)
          const options = yield WikiApi.Wikis.optionsSettingsNotifications(action.payload.unique_id)

          yield put({
            type: 'setNotificationsSettingsOptions',
            payload: {
              actions: options.data.actions,
              data: response.data,
              id: action.payload.id,
            }
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'GET_PROJECT_NOTIFICATION_SETTINGS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'GET_PROJECT_NOTIFICATION_SETTINGS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    patchNotificationsSettingsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'PATCH_PROJECT_NOTIFICATION_SETTINGS',
              status: 'FETCHING',
              err: {}
            }
          }
        })

        try {
          WikiApi.Wikis.patchSettingsNotifications(action.payload.unique_id, action.payload.data)

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'PATCH_PROJECT_NOTIFICATION_SETTINGS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'settings.updated' })
            )
          }

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'PATCH_PROJECT_NOTIFICATION_SETTINGS',
                status: 'ERROR',
                err
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeEvery' }
    ],
    getAssigneesProjectEffect: [
      function* (action, { put, select }) {
        yield put({
          type: 'setProjectStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'GET_ASSIGNEES_PROJECT',
              status: 'FETCHING',
              err: {}
            }
          }
        })

        try {
          const response = yield WikiApi.Wikis.getAssignees(action.payload.id)
          const users = response.data.assignees.users.filter(user => user)
          const groups = response.data.assignees.groups.filter(group => group)
          const current = yield select(state => state.projects.data[action.payload.id])

          const currentProject = {
            ...current,
            assignees: {
              users,
              groups,
            }
          }

          yield put({
            type: 'setCurrentProject',
            current: currentProject,
            currentProjectId: action.payload.id,
          })

          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'GET_ASSIGNEES_PROJECT',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch (err) {
          yield put({
            type: 'setProjectStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'GET_ASSIGNEES_PROJECT',
                status: 'ERROR',
                err,
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ]
  },
}
