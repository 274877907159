import WikiApi from '@wikicrm/api'
import ShowMessage from '@wikicrm/backoffice/src/core/utils/showMessage'
import * as actions from '../store/actions/actions'

const sendSurvey = async ({
  dispatch,
  id,
  calculate_score,
  surveySentMessage,
  surveySentErrorMessage,
  isAdmin,
}) => {
  try {
    actions.setGenericSurveysActionType(
      {
        name: 'SUBMIT_PARTICIPATION',
        status: 'RUNNING',
      },
      dispatch
    )

    await WikiApi.Survey.submit(id)
    const liveSurveyDetail = await WikiApi.Survey.getLiveSurveyDetail(id)
    actions.submitParticipation(
      id,
      liveSurveyDetail.data,
      calculate_score,
      dispatch
    )
    ShowMessage(true, 'success', surveySentMessage)

    if (isAdmin) {
      actions.setCurrentSurvey('', dispatch)
      actions.viewResults(id, false, dispatch)
    }
  } catch (err) {
    console.log(err)
    actions.setGenericSurveysActionType(
      {
        name: 'SUBMIT_PARTICIPATION',
        status: 'ERROR',
      },
      dispatch
    )
    ShowMessage(true, 'error', surveySentErrorMessage)
  } finally {
    actions.setGenericSurveysActionType(
      {
        name: 'SUBMIT_PARTICIPATION',
        status: 'SUCCESS',
      },
      dispatch
    )
  }
}

export default sendSurvey
