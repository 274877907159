import styled from "styled-components";

export const BreadCrumbItem = styled.div`
  min-height: 24px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  text-transform: uppercase;
  &:last-child {
    font-size: 20px;
    text-transform: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primaryText};
  }
`;

export const BreadCrumbDivider = styled.div`
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 2rem;
  padding: 0 0.5rem;
`;
