import React from 'react'
import get from 'lodash/get'
import merge from 'lodash/merge'

function getMergedActions(context, props) {
  const propsActions = get(props, 'actions')
  const contextActions = get(context, 'actions')

  return merge(propsActions, contextActions)
}

function createConsumerContext(Consumer) {
  return () => Comp => props => {
    return (
      <Consumer>
        {context => <Comp {...context} {...props} actions={getMergedActions(context, props)} />}
      </Consumer>
    )
  }
}

export default createConsumerContext