import { BaseObject } from '../BaseObject'
import { isUndefined } from 'lodash'

class OrganizationApi extends BaseObject {
  constructor(client, options) {
    super(client, options)
  }

  getDashboardRows() {
    return this.client.get(`/api/${this.versioning}/analytics/dashboard/`)
  }

  getDashboardColumn(url, params) {
    if (isUndefined(params)) {
      return this.client.get(`/api/${this.versioning}/analytics/${url}/`)
    }

    return this.client.get(`/api/${this.versioning}/analytics/${url}/`, {
      params,
    })
  }

  getDashboardRowsSurveys() {
    return this.client.get(`/api/${this.versioning}/analytics/surveys/dashboard/`)
  }

  getDashboardColumnSurveys(url, params) {
    return this.client.get(`/api/${this.versioning}/analytics/${url}/`, {
      params,
    })
  }

  downloadDashboardColumn(url, params, filters) {
    return this.client.get(`/api/${this.versioning}/analytics/${url}`, {
      ...params,
      params: filters,
      data: {},
    })
  }

  getUserDashboardRows() {
    return this.client.get(`api/${this.versioning}/analytics/users/dashboard/`)
  }

  getUserDashboardColumn(url, params) {
    return this.client.get(`/api/${this.versioning}/analytics/${url}/`, {
      params,
    })
  }

  downloadUserDashboardColumn(url, params, filters) {
    return this.client.get(`/api/${this.versioning}/analytics/${url}`, {
      ...params,
      params: filters,
      data: {},
    })
  }
}

export default OrganizationApi
