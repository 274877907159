import ax from 'axios'
import EventsApi from './objects/Events'
import LecturesApi from './objects/Lectures'
import SectionsApi from './objects/Sections'
import SurveyApi from './objects/Surveys'
import WikisApi from './objects/Wikis'
import ThemeApi from './objects/Theme'
import AnalyticsApi from './objects/Analytics'

/**
 * Wiki Api Library
 *
 * Accept a configuration object as
 * parameters to configure the instance
 * by invoking the init method
 */

class WikiApiClient {
  #config = null
  #options = {
    versioning: 'v1',
  }
  #client = ax.create()
  #Lectures = null
  #Notifications = null
  #Sections = null
  #Wikis = null
  #Events = null
  #Theme = null
  #Survey = null
  #Analytics = null

  get config() {
    return this.#config
  }

  init(config = {}, options = {}) {
    return new Promise(async (resolve, reject) => {
      this.#config = config
      this.#options = { ...this.#options, ...options }

      if (this.#config.baseURL !== undefined) {
        this.#client.defaults.baseURL = this.#config.baseURL
      } else {
        throw new Error('baseURL undefined')
      }

      try {
        await this.#loadObjects()

        resolve()
      } catch (e) {
        reject()
      }
    })
  }

  #loadObjects(client) {
    return new Promise(resolve => {
      this.#Lectures = new LecturesApi(this.#client, this.#options)
      this.#Wikis = new WikisApi(this.#client, this.#options)
      this.#Sections = new SectionsApi(this.#client, this.#options)
      this.#Events = new EventsApi(this.#client, this.#options)
      this.#Theme = new ThemeApi(this.#client, this.#options)
      this.#Survey = new SurveyApi(this.#client, this.#options)
      this.#Analytics = new AnalyticsApi(this.#client, this.#options)
      resolve()
    })
  }

  get client() {
    return this.#client
  }

  get Wikis() {
    return this.#Wikis
  }

  get Sections() {
    return this.#Sections
  }

  get Lectures() {
    return this.#Lectures
  }

  get Events() {
    return this.#Events
  }

  get Theme() {
    return this.#Theme
  }

  get Survey() {
    return this.#Survey
  }

  get Analytics() {
    return this.#Analytics
  }
}

export {
  WikiApiClient,
}
