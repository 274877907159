import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'
import Container from '../../layout/Container'

const AccessDenied = () => (
  <Container>
    <Box is="h2">
      <FormattedMessage id="access.denied" />
    </Box>
  </Container>
)

export default AccessDenied
