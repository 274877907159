import ax from 'axios'
import OrganizationApi from './objects/OrganizationApi'
import UsersApi from './objects/Users'
import GroupsApi from './objects/Groups'
import MeApi from './objects/Me'

class EamApiClient {
  #config = null
  #client = ax.create()
  #Users = null
  #Organization = null
  #Groups = null
  #Me = null

  get config() {
    return this.#config
  }

  init(config = {}) {
    return new Promise(async (resolve, reject) => {
      this.#config = config

      if (this.#config.baseURL !== undefined) {
        this.#client.defaults.baseURL = this.#config.baseURL
      } else {
        throw new Error('baseURL undefined')
      }

      try {
        await this.#loadObjects()

        resolve()
      } catch (e) {
        reject()
      }
    })
  }

  #loadObjects(client) {
    return new Promise(resolve => {
      this.#Users = new UsersApi(this.#client)
      this.#Organization = new OrganizationApi(this.#client)
      this.#Me = new MeApi(this.#client)
      this.#Groups = new GroupsApi(this.#client)
      resolve()
    })
  }

  get client() {
    return this.#client
  }

  get Users() {
    return this.#Users
  }

  get Organization() {
    return this.#Organization
  }

  get Groups() {
    return this.#Groups
  }

  get Me() {
    return this.#Me
  }
}

export {
  EamApiClient,
}