import { Button, Checkbox, Col, Form, Icon, Radio, Row, Select, Transfer, Tree } from 'antd';
import { isSafari } from 'react-device-detect';
import styled from 'styled-components';
import IconArrowLeft from '../assets/icons/ArrowLeft';

const { TreeNode } = Tree;

export const WrapButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  > button {
    width: 50%;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EditIcon = styled.div`
  position: absolute;
  right: 20px;
  z-index: 2;
  cursor: pointer;
`;

export const WrapInfo = styled.div`
  padding-bottom: 20px;
  // margin: 0 20px 0 0;
`;

export const WrapInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-left: 30px;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.primaryText};
  // height: 20px;
  // width: 112px;
  font-family: Roboto;
  font-size: 16px !important;
  // line-height: 20px;
`;

export const ValueLabel = styled.label`
  color: ${({ theme }) => theme.colors.primaryText};
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: bold;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ description }) => (description ? '5' : null)};
  -webkit-box-orient: ${({ description }) => (description ? 'vertical' : null)};
  display: ${({ description }) => (description ? '-webkit-box' : null)};
`;

export const PrimaryButton = styled(Button)`
  && {
    text-transform: uppercase;
  }
`;
export const CardContainer = styled.ul`
  height: 100%;
  overflow: auto;
  margin: 0px -17px 0px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  padding: 0 70px;
`;

export const GridContent = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: ${({ full }) => (full ? '100%' : '49%')};
  }
`;

export const HeaderButton = styled(Button)`
  && {
    width: 180px;
    margin-left: 23px;
    text-transform: uppercase;
  }
`;

export const ActionWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > button,
  > div {
    margin-right: 20px;
  }
`;

export const WrapContent = styled.div`
  /* padding: 20px; */
  margin-top: 64px;
  height: calc(100vh - 64px);
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f2f5f7;
`;

export const Content = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  min-height: auto;
  height: auto;
  padding: 70px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  > button:nth-child(0) {
    margin-right: 0 !important;
  }
`;

export const Search = styled.input`
  border: none;
  background: ${({ theme }) => theme.colors.background};
  &::placeholder {
    color: ${({ theme }) => theme.colors.secondaryAccent};
  }
  color: ${({ theme }) => theme.colors.primaryText};
  /* width: 0px; */
  font-size: 16px;
  width: ${({ isOpen }) => (isOpen ? '50%' : '0px')};
  transition: all 0.3s ease-in-out;
  &:active,
  &:focus {
    outline: none;
  }
`;

export const WrapSearchBar = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : null)};

  > i,
  > div > i {
    margin-right: 15px;
    font-size: 20px;
    color: #4a90e2;
  }

  &:hover > input,
  &:hover > div > input {
    width: 80%;
  }
`;
export const WrapTable = styled.div`
  padding: ${({ noPadding }) => (noPadding ? null : '0 70px 70px')};
  background: #f2f5f7;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }

  > div {
    background: #ffff;
    border-radius: 20px;
  }
  table {
    font-size: 16px;
  }
`;

export const WrapIcon = styled.div`
  margin-left: auto;
`;

export const BarButton = styled(Button)`
  && {
    width: 200px;
    text-transform: uppercase;
  }
`;
export const TableStyledCell = styled.div`
  word-wrap: break-word;
  word-break: break-all;
  cursor: ${({ noCursor }) => (noCursor ? null : 'pointer')};
  display: ${({ display }) => (display ? display : null)};
  justify-content: ${({ justify }) => (justify ? justify : null)};
`;

export const TableName = styled.div`
  display: flex
  align-items: center

  p {
    margin-left: 15px;
  }
`;

export const TableActions = styled.div`
  color: ${({ theme }) => theme.colors.icons}

  i {
    width: 24px
    height: 25px
    margin: 5px
  }
  `;

export const PrjButton = styled(Button)`
  width: 200px;
  text-transform: uppercase;
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.icons};
`;

export const RightBoxContainer = styled(Row)`
  flex-flow: column;
  width: 65%;
`;

export const RightBox = styled.div`
  width: ${({ full }) => (full ? '100%' : '65%')};
  height: ${({ height }) => height || null};
`;

export const NoContentStyle = styled.div`
  text-align: center;
  margin: ${({ margin }) => (margin ? margin : 'auto')};
  color: #c2d1d9;
  font-size: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #c2d1d9;
  }
`;

export const DataEl = styled(Row)`
  padding: 15px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.variantText};

  p,
  button {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
`;

export const StyledCol = styled(Col)`
  width: ${({ width }) => width};

  * {
    ${({ cut }) => (cut ? `overflow: hidden !important` : null)};
    ${({ cut }) => (cut ? `text-overflow: ellipsis` : null)};
    ${({ cut }) => (cut ? `white-space: nowrap` : null)};
  }

  &:first-child {
    padding: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const StyledTree = styled(Tree)`
  &.sections-tree {
    li ul {
      padding-left: 0;
    }

    li,
    li:first-child,
    li:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }

    ul.ant-tree-child-tree {
      width: 100%;
    }

    > li .ant-tree-node-content-wrapper {
      display: flex;
      align-items: center;
      padding: 15px 0;
      padding-left: 0;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: calc(100% - 100px);
      height: 26px;

      .backoffice & {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
  }

  // WIKI WEB

  &.wiki-web {
    padding-left: 30px;

    &.ant-tree {
      max-width: 100vw;
    }

    &.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
      transform: rotate(0);
    }

    &.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg {
      transform: rotate(90deg);
    }

    &.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
    &.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon {
      transform: scale(1);
    }

    .main-section,
    .main-section:first-child,
    .main-section:last-child {
      border-bottom: none !important;
      margin-bottom: 30px !important;
      min-height: 30px !important;
    }

    .ant-tree-node-content-wrapper {
      // padding: 0 !important;
    }

    .lecture,
    .empty-section {
      padding-left: 80px !important;
    }

    .ant-tree-title {
      width: calc(100% - 80px) !important;
    }

    .nested-section {
      margin-top: 20px;
      margin-bottom: 20px;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    span.ant-tree-title,
    .nested-section span.ant-tree-title {
      font-size: 28px !important;
      font-weight: 600;
    }

    .empty-section span.ant-tree-title,
    .lecture span.ant-tree-title {
      font-size: 28px !important;
      font-weight: 300;
    }

    .lecture {
      min-height: 30px;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-tree-child-tree.ant-tree-child-tree-open {
      margin-top: 30px;
    }

    &.ant-tree li .ant-tree-node-content-wrapper:hover {
      background-color: inherit;
    }

    .nested-section ~ .lecture {
      margin-top: 30px;

      .ant-tree-title {
        padding: 0;
      }
    }

    .nested-section {
      padding-left: 100px;
    }

    li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: inherit;
    }
  }
`;

export const MainSection = styled(TreeNode)`
  &.question {
    background-color: #fff;
    .ant-tree-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      p {
        width: 85%;
      }
    }
  }

  &.main-section,
  &.main-section:first-child,
  &.main-section:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders};
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.drag-over-gap-top,
    &.drag-over-gap-bottom {
      span[draggable] {
        border-radius: 0;
        border-width: 5px;
      }

      > span[draggable] {
        background-color: ${({ theme }) => theme.colors.primaryVariant};
      }
    }

    span.ant-tree-node-content-wrapper {
      flex: 1;
    }

    .ant-tree-iconEle.ant-tree-icon__open,
    .ant-tree-iconEle.ant-tree-icon__close {
      display: none !important;
    }

    > .ant-tree-switcher.ant-tree-switcher_open,
    > .ant-tree-switcher.ant-tree-switcher_close {
      margin: 0 0 0 70px;

      .wiki-web & {
        margin: 0 0 0 80px !important;
      }
    }

    span.ant-tree-title {
      padding: 0 0 0 16px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.primary};
    }

    .lecture,
    .empty-section {
      padding-left: 70px;
    }
  }

  span.ant-tree-title {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 2;
  }
`;

export const NestedSection = styled(TreeNode)`
  &.nested-section {
    border-left: 6px solid transparent;
    padding-left: 90px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &.drag-over-gap-top,
    &.drag-over-gap-bottom {
      span[draggable] {
        border-radius: 0;
        border-width: 5px;
      }

      > span[draggable] {
        background-color: ${({ theme }) => theme.colors.primaryVariant};
      }
    }

    .ant-tree-node-content-wrapper {
      width: calc(100% - 30px) !important;
      flex: 1;
    }

    .lecture,
    .empty-section {
      padding-left: 0 !important;
    }

    .nested-section {
      padding-left: 26px;
    }

    &.nested-section.ant-tree-treenode-switcher-open {
      background-color: ${({ theme }) => theme.colors.background};
      border-left: ${({ theme }) => `6px solid ${theme.colors.primary}`};
    }

    .nested-section.ant-tree-treenode-switcher-open,
    .nested-section.ant-tree-treenode-switcher-close {
      border-left: 6px solid transparent;
    }

    span.ant-tree-title {
      font-size: 16px !important;
      color: ${({ theme }) => theme.colors.primaryText} !important;
    }
  }

  .ant-tree-child-tree {
    width: 100%;
  }
`;

export const Lecture = styled(TreeNode)`
  &.lecture {
    display: flex;

    span.ant-tree-title {
      padding-left: 16px;
      font-size: 14px !important;
      font-weight: lighter !important;
      color: ${({ theme }) => theme.colors.primaryText} !important;
    }

    span.ant-tree-node-content-wrapper {
      width: 100%;
    }

    &.drag-over-gap-top,
    &.drag-over-gap-bottom {
      span[draggable] {
        border-radius: 0;
        border-width: 5px;
      }

      > span[draggable] {
        background-color: ${({ theme }) => theme.colors.primaryVariant};
      }
    }
  }

  &.answer {
    background-color: ${({ theme }) => theme.colors.background};
    border-left: ${({ theme }) => `6px solid ${theme.colors.primary}`};
    &:first-child {
      border-top: 1px solid ${({ theme }) => theme.colors.borders};
    }
    .ant-tree-title {
      display: inline-flex;
    }

    p {
      width: auto;
    }

    p:first-child {
      flex: 3;
    }

    p:last-child {
      flex: 1;
      padding-right: 20px;
      text-align: right;
    }
  }
`;

export const EmptySection = styled(TreeNode)`
  &.empty-section {
    background-color: ${({ theme }) => theme.colors.background};
    pointer-events: none;
    display: flex;

    .ant-tree-node-content-wrapper {
      color: ${({ theme }) => theme.colors.variantText} !important;
    }

    span.ant-tree-title {
      padding-left: 16px;
      color: ${({ theme }) => theme.colors.variantText} !important;
      font-size: 14px !important;
    }
  }
`;

export const CutText = styled.p`
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${({ width }) => width};
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;

export const QuestionsFormContent = styled.div`
  box-shadow: 0 5px 7px 2px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  margin: 32px 70px 70px 70px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 94px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders};
  padding: 32px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ width }) => width || '70%'};
`;

export const PButton = styled(PrimaryButton)`
  && {
    margin-left: 16px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: 1100px) {
      margin-left: 5px;
    }
  }
`;

export const Title = styled.div`
  color: #4d565f;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  width: 30%;

  @media (max-width: 1023px) {
    font-size: 16px;
  }
`;

export const QuestionBody = styled.div`
  width: 100%;
  padding: 26px 32px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders};
  margin-bottom: 16px;
`;

export const AnswersBody = styled.div`
  width: 100%;
  padding: 10px 32px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-form-item {
    margin-bottom: 0;
  }

  i {
    margin: 0 auto;
  }

  .ant-checkbox-inner {
    background-color: #fff !important;
  }
`;

export const AnswerCol = styled.div`
  width: ${({ width }) => (width ? width : null)};
  flex-grow: ${({ flexVal }) => (flexVal ? flexVal : null)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
`;

export const RadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const AddAnswer = styled.div`
  padding: 26px 32px 15px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  p {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const PrivilegeTransfer = styled(Transfer)`
  padding-bottom: 20px;

  && {
    .ant-transfer-list-content {
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    left: 50%;
    border-radius: 3px;
  }
`;

export const StyledIconArrowLeft = styled(IconArrowLeft)`
  color: ${({ theme }) => theme.colors.primary};
  transform: ${({ rotate }) => (rotate ? 'rotateZ(180deg)' : null)};

  button:disabled & {
    opacity: 0.3;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: #fff;
  }
`;

export const DarkButton = styled(PrjButton)`
  color: #fff;
  background-color: ${({ theme }) => theme.colors.primaryText};
  border-color: ${({ theme }) => theme.colors.primaryText};

  &:hover,
  &:focus {
    color: #fff;
    background-color: ${({ theme }) => theme.colors.primaryText};
    border-color: ${({ theme }) => theme.colors.primaryText};
  }
`;

export const DarkButtonSmall = styled(DarkButton)`
  min-height: auto;
  min-width: 150px;
  width: auto;
  font-size: 14px;
  margin: ${({ margin }) => margin};
`;

export const StyledRow = styled(Row)`
  height: ${({ height }) => height};
  flex-flow: ${({ flow }) => flow};
`;

export const ContentForm = styled.div`
  padding: 30px;
`;

export const ContentActions = styled.div`
  box-shadow: 0 5px 7px 2px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  border-radius: 20px;
  position: relative;
`;

export const StyledFlexContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  height: ${isSafari ? '100%' : 0};
  max-height: ${isSafari ? '500px' : null};
`;

export const StyledDatePicker = (DatePickerType, lightBorders) => {
  return styled(DatePickerType)`
    .ant-calendar-picker-input.ant-input {
      border: ${({ theme }) => (lightBorders ? null : `2px solid ${theme.colors.borders}`)};

      * {
        color: ${({ theme }) => theme.colors.borders};
      }

      input {
        color: ${({ theme }) => theme.colors.buttonSecondary};
        &::placeholder {
          color: ${({ theme }) => theme.colors.borders};
        }
      }
    }

    &.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-right-width: 2px !important;
    }
  `;
};

export const DashboardStyledContent = styled(Content)`
  padding-top: 70px;
  padding: ${({ noPadding }) => (noPadding ? `70px 0 0` : null)};
  flex-wrap: wrap;
`;

export const DashboardStyledRow = styled(Row)`
  width: 100%;
  margin-bottom: 10px;
`;

export const CustomIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 20px;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.borders};
`;

export const StyledFormItem = styled(Form.Item)`
  width: ${({ width }) => (width ? width : null)};
`;
