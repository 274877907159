import WikiWebListIcon from '@wikicrm/backoffice/src/assets/icons/WikiWebListIcon'
import React from 'react'
import { withTheme } from 'styled-components'
import Box from 'ui-box'

const ListItem = ({
  name,
  theme,
  iconStyle = {},
  fontWeight = 700,
  fontSize = 30,
  fill = null,
  onClickIcon = () => null,
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Box onClick={onClickIcon}>
        <WikiWebListIcon size={30} style={iconStyle} fill={fill} />
      </Box>
      <Box
        is="span"
        color={theme.greyDark}
        marginLeft={10}
        fontSize={fontSize}
        fontWeight={fontWeight}
      >
        {name}
      </Box>
    </Box>
  )
}

export default withTheme(ListItem)
