import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import Container from '../../layout/Container'

const Error = ({ errString, err, theme }) => {
  console.log(err)

  return (
    <Container>
      <Box is="h2" color={theme.textColor}>
        <FormattedMessage id="error" /> : <br /> {errString}
      </Box>
    </Container>
  )
}

export default withTheme(Error)
