import {Form, Icon, Input} from "antd";
import React, {useState} from "react";
import styled from "styled-components";
import {WrapField} from "./Commons";
import { isUndefined } from "util";

const WrapInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
	
  i {
    margin-left: 30px;
  }
`;

const Label = styled.label`
  color: ${({theme}) => theme.colors.primaryText};
  height: 20px;	
	width: 112px;	
	font-family: Roboto;	
	font-size: 16px !important;	
	font-weight: bold;	
	line-height: 20px;
	margin-bottom: 20px !important;
`;

const TextInput = styled(Input.TextArea)`
	padding: 15px;

	&& {
		color: black;

		textarea {
			padding-left: 4px;
			padding-top: 2px;
		}
	
	}
`


export const TextAreaV1 = ({label, switchable = false, read = false, value, type, required, ...props}) => {
	const [selected, setSelected] = useState(switchable === true ? false : true);
	const valueInput = value && value.length >= 0 ? value : "---";

	const inputType = !isUndefined(type) ? type : 'text';

	const txtLabel = `${label} ${ required ? '*' : ''}`

	return (
		<WrapField>
			
				<Label>{txtLabel}</Label>
				<WrapInput>
					<TextInput type={inputType} rows={4} placeholder={props.placeholder} defaultValue={value} {...props} />
				</WrapInput>
			
		</WrapField>
	);
};
