import React from 'react'
import Box from 'ui-box'

const Container = ({ children }) => (
  <Box is="div" marginX="auto" marginY="0" paddingX="20px">
    {children}
  </Box>
)

export default Container
