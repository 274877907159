import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import ariaSVGAttributes from '../utils/ariaSVGAttributes';

const icon = IconComponent => {
  const Icon = props => (
    <Wrap {...props}>
      <IconComponent {...ariaSVGAttributes(props)} />
    </Wrap>
  );

  Icon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    margin: PropTypes.string,
  };

  return Icon;
};

export default icon;

const Wrap = styled.i.attrs({
  className: 'iconHoc',
})`
  display: block;
  position: relative;

  width: ${({ size }) => (size ? `${size}px` : '25px')};
  height: ${({ size }) => (size ? `${size}px` : '25px')};

  margin: ${({ margin }) => (margin ? margin : '0px')};

  color: ${({ color, theme, themed }) => (color ? color : themed ? theme.colors.icons : undefined)};
`;
