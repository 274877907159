import WikiApi from '@wikicrm/api'
import { isUndefined } from 'lodash'
import * as actions from '../store/actions/actions'

const getLectureDownloadUrl = async ({
  dispatch,
  lectureId,
  currentWiki,
  id,
}) => {
  try {
    actions.setGenericWikisActionType(
      {
        name: 'SET_CURRENT_FILE',
        status: 'FETCHING',
        err: {},
      },
      dispatch
    )

    if (isUndefined(currentWiki.allLectures.data[lectureId].contentUrl)) {
      const res = await WikiApi.Lectures.getDownloadUrl(lectureId)
      actions.setCurrentFile(
        id,
        lectureId,
        res.data.content_url,
        res.data.url,
        res.data.preview_url,
        dispatch
      )
    } else {
      actions.setCurrentFile(
        id,
        lectureId,
        currentWiki.allLectures.data[lectureId].contentUrl,
        currentWiki.allLectures.data[lectureId].url,
        currentWiki.allLectures.data[lectureId].previewUrl,
        dispatch
      )
    }
  } catch (err) {
    console.log(err)
    actions.setGenericWikisActionType(
      {
        name: 'SET_CURRENT_FILE',
        status: 'ERROR',
        err,
      },
      dispatch
    )
  } finally {
    actions.setGenericWikisActionType(
      {
        name: 'SET_CURRENT_FILE',
        status: 'SUCCESS',
        err: {},
      },
      dispatch
    )
  }
}

export default getLectureDownloadUrl
