import { createGlobalStyle } from 'styled-components'

export const theme = {
  white: '#fff',
  greyLight: '#C4C4C4',
  greyDark: '#565656',
  textColor: '#565656',
  textColorBold: '#565656',
  borderColor: '#C4C4C4',
  lightGrey: '#C4C4C4',
  red: '#FE0000',
  green: '#63C779',
  yellow: '#FBD304',
  colors: {},
}

export const GlobalStyle = createGlobalStyle`
  :root {
    --app-height: 100%;
  }

  html {
    height: var(--app-height);
    overflow: hidden;
    box-sizing: border-box;
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }

  body {
    height: var(--app-height);
    margin: 0;
    font-weight: 400;
    overflow-y: hidden;
    background-color: ${({ theme }) => theme.white} !important;
    color: ${({ theme }) => theme.textColor} !important;
    font-family: 'Quicksand', sans-serif !important;
    font-size: 1em !important;
    line-height: normal !important;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.textColorBold} !important;
  }

  button {
    font-family: 'Quicksand', sans-serif !important;
  }

  a:hover {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .tree-container {
    .wiki-web {
      color: ${({ theme }) => theme.textColor};
  
      * {
        color: ${({ theme }) => theme.textColor};
      }
  
      li .ant-tree-node-content-wrapper:hover,
      li .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: ${({ theme }) => theme.white};
      }
  
      .main-section,
      .main-section:first-child,
      .main-section:last-child {
        border: 0;
        > .ant-tree-switcher.ant-tree-switcher_open,
        > .ant-tree-switcher.ant-tree-switcher_close {
          margin: 0 0 0 20px;
        }
  
        .ant-tree-title {
          padding-right: 20px;
        }
  
        .lecture,
        .empty-section {
          padding-left: 20px;
        }
      }
  
      .nested-section {
        padding-left: 40px;
      }
    }
  }

  // Responsive PDF rules cause conflict with zoomIn and zoomOut
  // .react-pdf__Page {
  //   width: auto;
  //   height: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   canvas {
  //     height: auto !important;
  //     width: auto !important;
  //     max-width: 100% !important;
  //     max-height: 100% !important;
  //   }
  // }
`
