import WikiApi from '@wikicrm/api'
import * as actions from '../store/actions/actions'

const fetchWikiList = async ({ didCancel, dispatch }) => {
  try {
    !didCancel &&
      actions.setGenericWikisActionType(
        {
          name: 'FETCH_WIKIS',
          status: 'FETCHING',
          err: {},
        },
        dispatch
      )

    const res = await WikiApi.Wikis.getAll()
    !didCancel && actions.fetchWikis(res, dispatch)
  } catch (err) {
    console.log(err)
    !didCancel &&
      actions.setGenericWikisActionType(
        {
          name: 'FETCH_WIKIS',
          status: 'ERROR',
          err,
        },
        dispatch
      )
  } finally {
    !didCancel &&
      actions.setGenericWikisActionType(
        {
          name: 'FETCH_WIKIS',
          status: 'SUCCESS',
          err: {},
        },
        dispatch
      )
  }
}

export default fetchWikiList
