import styled from "styled-components";

export const WrapNavbar = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid ${({theme}) => theme.colors.borders};
`;

export const Brand = styled.div`
  margin-left: 6px;
  margin-right: auto;
  padding: 15px 0;
  height: 100%;
`;

export const WrapNavigation = styled.div`
  margin-left: auto;
`;

export const List = styled.ul`
  margin: 0;
  display: flex;
  align-items: center;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-left: 30px;
  &:last-child {
    margin-right: 30px;
  }
  > a {
    font-size: 1.6rem;
  }
`;