import {Icon, Upload, Button, Row, Col} from "antd";
import React from "react";
import styled from "styled-components";
import { FormattedMessage } from 'umi-plugin-locale';
import {WrapField} from "./Commons";
import { isEmpty, isUndefined} from 'lodash'

const Dragger = Upload.Dragger;

const Label = styled.label`
  color: ${({theme}) => theme.colors.primaryText};
  height: 20px;	
  width: 112px;	
  font-family: Roboto;	
  font-size: 16px !important;	
  font-weight: bold;	
	line-height: 20px;
	flex: 1;
`;

const WrapHeader = styled.div`
  display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px !important;
`;

const FileButton = styled(Button)`
	padding: 0;

	&& {
		height: auto;
		min-height: 21px;
		font-size: 12px;
		border: none
		color: ${({ theme }) => theme.colors.primary}
		&:hover {
			color: ${({ theme }) => theme.colors.primary}
		}
	}
`

const CustomRow = styled(Row)`
	flex-wrap: nowrap;
`

const CustomPar = styled.p`
	color: ${({ theme }) => theme.colors.primary};
	cursor: pointer;
`

const CustomDragger = styled(Dragger)`
	.ant-upload.ant-upload-drag {
		padding: 0 !important;
		.ant-upload {
			padding: 20px !important;
		}
	}
`

export const UploadFile = ({ disabled, fileList, setFileList, fileName, onClick, accept, setIsRemoveFile, showErrorMessage, downloadFile, style, label, setUploadsTouched }) => {
	let fileToUpload = !isUndefined(fileName) && !isEmpty(fileName) ? fileName :
						 !isEmpty(fileList)? fileList[0].name : undefined

	const displayedFileToUpload = fileToUpload && fileToUpload.split(/\.(?=[^\.]+$)/)
	const fileExtension = displayedFileToUpload && displayedFileToUpload[1]
	const fileNameSpliced = displayedFileToUpload && displayedFileToUpload[0].length >= 20 ?
		displayedFileToUpload && displayedFileToUpload[0].substring(0, 20) + '..' :
		displayedFileToUpload && displayedFileToUpload[0]

	const acceptedExtensions = accept.split(',')
			
	const uploadProps = {
		disabled,
		name: "file",
		multiple: false,
		onRemove: file => {		
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList)			
		},
		beforeUpload: file => {
			const extension = `.${file.name.split(/\.(?=[^\.]+$)/)[1]}`
			const isAccepted = acceptedExtensions.indexOf(extension)
			if (isAccepted < 0) {
				showErrorMessage()
				return false
			} else {
				setFileList([file])
				setIsRemoveFile(false)
				setUploadsTouched && setUploadsTouched(true)
			}
		},
	};
  
	return (
		<WrapField style={style}>
			
			<WrapHeader>
				<Label style={{color: "#4D565F"}}>
					{label ? label : 'File'}
				</Label>
				{isUndefined(fileToUpload) && <label style={{color: "#C2D1D9"}}>
					<FormattedMessage id="file.notSelected" />
				</label>}
				{!isUndefined(fileToUpload) && 
					<CustomRow type="flex" align="middle">
						<Col>
							<CustomPar onClick={downloadFile}>
								{fileNameSpliced}.{fileExtension}
							</CustomPar>
						</Col>
						{
							!disabled &&
							<Col style={{ marginLeft: 5 }}>
								<FileButton onClick={onClick}>
									<Icon type="close" />
								</FileButton>
							</Col>
						}
					</CustomRow>
				}
			</WrapHeader>

			{
				!disabled &&
				<CustomDragger {...uploadProps} setFileList={setFileList} showUploadList={false}>
					<p className="ant-upload-hint">
						<FormattedMessage id="click.to.upload" />
					</p>
				</CustomDragger>
			}
			
		</WrapField>
	);
};
