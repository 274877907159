import { Select } from 'antd'
import React from 'react'
import styled from "styled-components";
import {WrapField} from './Commons'
import { isUndefined } from 'lodash'

const Label = styled.label`
  color: ${({theme}) => theme.colors.primaryText};
  height: 20px;	
  width: 112px;	
  font-family: Roboto;	
  font-size: 16px !important;	
  font-weight: bold;	
  line-height: 20px;
`;

const SelectInput = styled(Select)`
	&& {
		width: 100%;
		.ant-select-selection-selected-value {
			color: black !important;
		}
	}
`

export const SelectField = ({label, options, value, initialValue, required, onSelect, selectEl, ...props}) => {
	const items = !isUndefined(options) ? options: [];
	const txtLabel = !isUndefined(label) ? `${label} ${ required ? '*' : ''}` : undefined;
	
	return (
		<WrapField>
			
			{!isUndefined(txtLabel)  && <Label>{txtLabel}</Label>}
			<SelectInput
				ref={selectEl}
				placeholder={props.placeholder}
				defaultValue={initialValue}
				onSelect={onSelect}
				{ ...props}
			>
				{items.map((option, index) => (
					<Select.Option key={index} value={option.value}>
						{option.name}
					</Select.Option>
				))}
			</SelectInput>
			
		</WrapField>
	)
}
