import React from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import {WrapField} from "./Commons";
import moment from 'moment';

const WrapInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  i {
    margin-left: 30px;
  }
`;

const Label = styled.label`
  color: ${({theme}) => theme.colors.primaryText};
  height: 20px;	
	width: 112px;	
	font-family: Roboto;	
	font-size: 16px !important;	
	font-weight: bold;	
	line-height: 20px;
`;

const DatePickerInput = styled(DatePicker)`
	&& {
        .ant-calendar-picker-input {
            color: black;
        }
	}
`


export const DatePickerV1 = ({label, required, handleOnDatePickerChange, initialValue, ...props}) => {
	const txtLabel = `${label} ${ required ? '*' : ''}`
	const dateFormat = ['DD/MM/YY'];
	const defaultValue = initialValue ? moment(initialValue, dateFormat) : moment(new Date(), dateFormat)

	return (
		<WrapField>
				<Label>{txtLabel}</Label>
				<WrapInput>
					<DatePickerInput
						format={dateFormat}
						defaultValue={defaultValue}
						onChange={handleOnDatePickerChange} />
				</WrapInput>
			
		</WrapField>
	);
};
