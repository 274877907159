import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { values } from 'lodash'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import * as actions from '../../../store/actions/actions'

const SurveyCertificates = ({ certificates, loading, theme, surveyTitle }) => {
  const { dispatch } = useContext(SurveysContext)

  if (loading) {
    return (
      <Box paddingLeft={30}>
        <StyledSpin
          data-bg-color={theme.textColor}
          style={{ marginTop: 25, textAlign: 'left', marginRight: 30 }}
        />
      </Box>
    )
  }

  if (values(certificates).length === 0) {
    return (
      <Box
        fontSize={24}
        fontWeight={300}
        paddingLeft={30}
        marginTop={25}
        textAlign="left"
      >
        <FormattedMessage id="certificates.noContent" />
      </Box>
    )
  }

  return values(certificates).map((cert) =>
    cert.url ? (
      <Box
        key={cert.url}
        fontSize={24}
        fontWeight={300}
        marginTop={25}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingLeft={30}
      >
        <Box is="span">
          <FormattedMessage id={cert.name} />
        </Box>
        <Box
          is="button"
          backgroundColor="white"
          padding={0}
          cursor="pointer"
          border="none"
          textTransform="uppercase"
          fontSize={16}
          fontWeight={600}
          onClick={() =>
            actions.setActiveCertificate({ ...cert, surveyTitle }, dispatch)
          }
        >
          <FormattedMessage id="certificates.download" />
        </Box>
      </Box>
    ) : null
  )
}

export default withTheme(SurveyCertificates)
