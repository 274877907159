import { BaseObject } from '../BaseObject'

class WikisApi extends BaseObject {
  constructor(client, options) {
    super(client, options)
  }

  get(wikiId) {
    return this.client.get(`api/${this.versioning}/wikis/${wikiId}/`)
  }

  getAll() {
    return this.client.get(`api/${this.versioning}/wikis/`)
  }

  create(data) {
    return this.client.post(`api/${this.versioning}/wikis/`, data)
  }

  count() {
    return this.client.get(`api/${this.versioning}/wikis/count/`)
  }

  put(wikiId, data) {
    return this.client.put(`api/${this.versioning}/wikis/${wikiId}/`, data)
  }

  patch(wikiId, data) {
    return this.client.patch(`api/${this.versioning}/wikis/${wikiId}/`, data)
  }

  delete(wikiId) {
    return this.client.delete(`api/${this.versioning}/wikis/${wikiId}/`)
  }

  activity(wikiId) {
    return this.client.get(`api/${this.versioning}/wikis/${wikiId}/activity/`)
  }

  publishTest(wikiId) {
    return this.client.post(`api/${this.versioning}/wikis/${wikiId}/publish-test/`)
  }

  publish(wikiId, data) {
    return this.client.post(`api/${this.versioning}/wikis/${wikiId}/publish/`, data)
  }

  restoreCache(wikiId) {
    return this.client.post(`api/${this.versioning}/wikis/${wikiId}/publish/restore-cache/`)
  }

  getSections(wikiId, sectionId = null) {
    if (sectionId === null) {
      return this.client.get(`api/${this.versioning}/wikis/${wikiId}/sections/`)
    } else {
      return this.client.get(`api/${this.versioning}/wikis/${wikiId}/sections/${sectionId}/`)
    }
  }

  getLectures(wikiId, sectionId) {
    return this.client.get(`api/${this.versioning}/wikis/${wikiId}/sections/${sectionId}/lectures/`)
  }

  getAllSection(wikiId) {
    return this.client.get(`api/${this.versioning}/wikis/${wikiId}/sections/all/`)
  }

  getAllLecture(wikiId) {
    return this.client.get(`api/${this.versioning}/wikis/${wikiId}/lectures/all/`)
  }

  addSection(wikiId, data) {
    return this.client.post(`api/${this.versioning}/wikis/${wikiId}/sections/`, data)
  }

  deleteSection(sectionId) {
    return this.client.delete(`api/${this.versioning}/sections/${sectionId}/`)
  }

  addLecture(wikiId, sectionId, data) {
    return this.client.post(`api/${this.versioning}/wikis/${wikiId}/sections/${sectionId}/lectures/`, data)
  }

  countAllSections(wikiId) {
    return this.client.post(`api/${this.versioning}/wikis/${wikiId}/sections/count/`)
  }

  static search() {
    throw new Error('Not implemented')
  }

  static suggestions() {
    throw new Error('Not implemented')
  }

  tree = (wikiUniqueId) => {
    return this.client.get(`api/${this.versioning}/wikis/${wikiUniqueId}/tree/`)
  }

  treePublic = (wikiUniqueId) => {
    return this.client.get(`api/${this.versioning}/wikis/${wikiUniqueId}/tree/public/`)
  }

  assign(id, data) {
    return this.client.put(`api/${this.versioning}/wikis/${id}/assign/`, data)
  }

  getAssignees(id) {
    return this.client.get(`api/${this.versioning}/wikis/${id}/assign/`)
  }

  getSettings() {
    return this.client.get(`api/${this.versioning}/app/settings/`)
  }

  getNotifications() {
    return this.client.get(`api/${this.versioning}/notifications/`)
  }

  setNotificationRead(id) {
    return this.client.post(`api/${this.versioning}/notifications/${id}/read/`)
  }

  setNotificationUnread(id) {
    return this.client.post(`api/${this.versioning}/notifications/${id}/unread/`)
  }

  readAllNotfications() {
    return this.client.post(`api/${this.versioning}/notifications/read-all/`)
  }

  optionsSettingsNotifications(id) {
    return this.client.options(`api/${this.versioning}/wikis/${id}/settings/notifications/`)
  }

  getSettingsNotifications(id) {
    return this.client.get(`api/${this.versioning}/wikis/${id}/settings/notifications/`)
  }

  patchSettingsNotifications(id, data) {
    return this.client.patch(`api/${this.versioning}/wikis/${id}/settings/notifications/`, data)
  }
}

export default WikisApi
