import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import * as actions from '../../../store/actions/actions'

const CertificateFooterButtons = () => {
  const { dispatch } = useContext(SurveysContext)

  return (
    <Box
      is="button"
      onClick={() => actions.setActiveCertificate(null, dispatch)}
      padding={0}
      backgroundColor="white"
      border="none"
      cursor="pointer"
      fontWeight={700}
      textTransform="uppercase"
    >
      <FormattedMessage id="close" />
    </Box>
  )
}

export default CertificateFooterButtons
