import { Breadcrumb, Typography } from 'antd'
import { get } from 'lodash'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import { WikisContext } from '../../../context/WikisContext'
import * as actions from '../../../store/actions/actions'
import { StyledBreadcrumb } from '../../shared/Breadcrumbs/styled'

const BreadcrumbsCertificates = () => {
  const { state, dispatch } = useContext(WikisContext)
  const { state: surveyState, dispatch: surveyDispatch } =
    useContext(SurveysContext)
  const id = state.currentWikiId
  const wikiName = get(state, `data[${id}].name`, '')

  const resetActiveCertificate = () =>
    actions.setActiveCertificate(null, surveyDispatch)

  const resetFile = () => actions.resetCurrentFile(id, dispatch)

  return (
    <StyledBreadcrumb>
      <Breadcrumb.Item onClick={resetActiveCertificate}>
        <Link to="/">
          <Typography.Paragraph>Wiki</Typography.Paragraph>
        </Link>
      </Breadcrumb.Item>

      {wikiName && (
        <Breadcrumb.Item onClick={resetFile}>
          <Box is={Typography.Paragraph} ellipsis cursor="pointer">
            {wikiName}
          </Box>
        </Breadcrumb.Item>
      )}

      {surveyState.activeCertificate && (
        <>
          <Breadcrumb.Item>
            <Typography.Paragraph>
              {surveyState.activeCertificate.surveyTitle}
            </Typography.Paragraph>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Typography.Paragraph>
              {<FormattedMessage id={surveyState.activeCertificate.name} />}
            </Typography.Paragraph>
          </Breadcrumb.Item>
        </>
      )}
    </StyledBreadcrumb>
  )
}

export default BreadcrumbsCertificates
