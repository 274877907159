import React from 'react';
import icon from '../../core/hoc/icon';

const SvgArrowLeft = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
      transform="translate(1 1)"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={11} cy={11} r={11} />
      <path d="M9 7l4 4-4 4" />
    </g>
  </svg>
);

export default icon(SvgArrowLeft);
