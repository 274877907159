import { Form } from 'antd'
import styled from 'styled-components'

export const StyledForm = styled(Form)`
  &.ant-form {
    height: 100%;

    label {
      display: flex;
      align-items: center;
      margin: 30px 0;

      span {
        font-size: 20px;
      }
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: ${({ theme }) => theme.greyLight};
    }

    .ant-radio-inner {
      width: 30px;
      height: 30px;

      &:after {
        width: 22px;
        height: 22px;
        border-radius: 22px;
        background-color: ${({ theme }) => theme.greyLight};
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.greyLight};
      border-color: ${({ theme }) => theme.greyLight};
    }

    .ant-checkbox-inner {
      width: 30px;
      height: 30px;

      &:after {
        width: 10px;
        height: 15px;
      }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.greyLight};
    }

    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: #fff;
    }
  }

  &.check-answers {
    label {
      span {
        font-size: 22px;
      }
    }

    .ant-checkbox-inner {
      width: 25px;
      height: 25px;

      &:after {
        width: 9px;
        height: 13px;
      }
    }

    label {
      margin: 20px 0;
    }
  }

  &.results {
    .ant-checkbox-inner {
      width: 25px;
      height: 25px;

      &:after {
        width: 9px;
        height: 13px;
      }
    }

    label {
      margin: 20px 0;

      &.wrong {
        .ant-radio-inner {
          &:after {
            background-color: ${({ theme }) => theme.red};
          }
        }
      }

      &.wrong {
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${({ theme }) => theme.red};
          border-color: ${({ theme }) => theme.red};
        }
      }

      &.correct {
        .ant-radio-inner {
          &:after {
            background-color: ${({ theme }) => theme.green};
          }
        }
      }

      &.correct {
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${({ theme }) => theme.green};
          border-color: ${({ theme }) => theme.green};
        }
      }
    }
  }
`
