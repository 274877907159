import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
`
