import WikiApi from '@wikicrm/api'
import SectionsTree from '@wikicrm/backoffice/src/components/SectionsTree'
import { isEmpty, isUndefined, values } from 'lodash'
import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import { GlobalContext } from '../../../context/GlobalContext'
import { WikisContext } from '../../../context/WikisContext'
import * as actions from '../../../store/actions/actions'

const WikiContentTree = ({ currentWiki }) => {
  const { id } = useParams()
  const { state, dispatch } = useContext(WikisContext)
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  const [selectedKeys, setSelectedKeys] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])

  const getLectureDownloadUrl = async (lectureId) => {
    try {
      actions.setGenericWikisActionType(
        {
          name: 'SET_CURRENT_FILE',
          status: 'FETCHING',
          err: {},
        },
        dispatch
      )

      if (isUndefined(currentWiki.allLectures.data[lectureId].contentUrl)) {
        const res = await WikiApi.Lectures.getDownloadUrl(lectureId)
        actions.setCurrentFile(
          id,
          lectureId,
          res.data.content_url,
          res.data.url,
          res.data.preview_url,
          dispatch
        )
      } else {
        actions.setCurrentFile(
          id,
          lectureId,
          currentWiki.allLectures.data[lectureId].contentUrl,
          currentWiki.allLectures.data[lectureId].url,
          currentWiki.allLectures.data[lectureId].previewUrl,
          dispatch
        )
      }
    } catch (err) {
      console.log(err)
      actions.setGenericWikisActionType(
        {
          name: 'SET_CURRENT_FILE',
          status: 'ERROR',
          err,
        },
        dispatch
      )
    } finally {
      actions.setGenericWikisActionType(
        {
          name: 'SET_CURRENT_FILE',
          status: 'SUCCESS',
          err: {},
        },
        dispatch
      )
    }
  }

  const onSelectTree = (selectedKeys, info) => {
    if (info.node.props.isLeaf) {
      setSelectedKeys(selectedKeys)

      if (!isEmpty(selectedKeys)) {
        if (info.node.props.type === 'lecture') {
          const setLecture = async () => {
            try {
              actions.setActiveItem('wiki', globalDispatch)
            } catch (err) {
              console.log(err)
            } finally {
              await getLectureDownloadUrl(selectedKeys[0])
            }
          }

          setLecture()
        }
      }
    } else {
      setExpandedKeys(
        info.node.props.expanded
          ? expandedKeys.filter((k) => k !== info.node.props.eventKey)
          : expandedKeys.concat(info.node.props.eventKey)
      )
    }
  }

  const onLoadTree = async (treeNode) => {
    actions.setSectionStatus(
      id,
      treeNode.props.eventKey,
      {
        name: 'EXPAND_SECTION',
        status: 'FETCHING',
        err: {},
      },
      dispatch
    )

    try {
      const sections = await WikiApi.Wikis.getSections(
        state.currentWikiId,
        treeNode.props.eventKey
      )

      const lectures = await WikiApi.Wikis.getLectures(
        state.currentWikiId,
        treeNode.props.eventKey
      )

      actions.setCurrentSections(id, sections, dispatch)
      actions.setCurrentLectures(id, lectures, dispatch)
    } catch (err) {
      console.log(err)
      actions.setSectionStatus(
        id,
        treeNode.props.eventKey,
        {
          name: 'EXPAND_SECTION',
          status: 'ERROR',
          err,
        },
        dispatch
      )
    } finally {
      actions.setSectionStatus(
        id,
        treeNode.props.eventKey,
        {
          name: 'EXPAND_SECTION',
          status: 'SUCCESS',
          err: {},
        },
        dispatch
      )
    }
  }

  const onExpandTree = (expandedKeys) => {
    setExpandedKeys(expandedKeys)
  }

  const showEmptyNodeForSection = (id) => {
    return (
      currentWiki.allSections.data[id] &&
      currentWiki.allSections.data[id].actionType &&
      currentWiki.allSections.data[id].actionType.name === 'EXPAND_SECTION' &&
      currentWiki.allSections.data[id].actionType.status === 'SUCCESS' &&
      !values(currentWiki.allSections.data).filter(
        (nested) => nested.parent === id
      ).length &&
      !values(currentWiki.allLectures.data).filter(
        (nested) => nested.section === id
      ).length
    )
  }

  return (
    <SectionsTree
      selectedKeys={selectedKeys}
      expandedKeys={expandedKeys}
      draggable={false}
      showHeader={false}
      sections={!isUndefined(currentWiki) && currentWiki.allSections.data}
      lectures={!isUndefined(currentWiki) && currentWiki.allLectures.data}
      onSelect={onSelectTree}
      onLoad={onLoadTree}
      onExpand={onExpandTree}
      showIcon={false}
      showEmptyNodeForMainSection={showEmptyNodeForSection}
      showEmptyNodeForNestedSection={showEmptyNodeForSection}
      emptyNodeTitle={<FormattedMessage id="wiki.emptyNode" />}
      className="wiki-web"
      context="wiki-web"
    />
  )
}

export default WikiContentTree
