import { BaseObject } from '../BaseObject'

class SectionsApi extends BaseObject {
  constructor(client, options) {
    super(client, options)
  }

  get(sectionId) {
    return this.client.get(`api/${this.versioning}/sections/${sectionId}/`)
  }

  put(sectionId, data) {
    return this.client.put(`api/${this.versioning}/sections/${sectionId}/`, data)
  }

  patch(sectionId, data) {
    return this.client.patch(`api/${this.versioning}/sections/${sectionId}/`, data)
  }

  delete(sectionId) {
    return this.client.delete(`api/${this.versioning}/sections/${sectionId}/`)
  }

  activity(sectionId) {
    return this.client.get(`api/${this.versioning}/sections/${sectionId}/activity/`)
  }

  move(sectionId, data) {
    return this.client.post(`api/${this.versioning}/sections/${sectionId}/move/`, data)
  }

  restore(sectionId, data) {
    return this.client.post(`api/${this.versioning}/sections/${sectionId}/restore/`)
  }

  getAssignees(id) {
    return this.client.get(`api/${this.versioning}/sections/${id}/assign/`)
  }

  assign(id, data) {
    return this.client.put(`api/${this.versioning}/sections/${id}/assign/`, data)
  }
}

export default SectionsApi
