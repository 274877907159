import {AutoComplete, Form} from 'antd'
import React, {useEffect, useState} from 'react'
import { isEmpty, filter } from 'lodash'
import {WrapField} from './Commons'
import styled from 'styled-components'

const {Option} = AutoComplete

const Label = styled.label`
  color: ${({theme}) => theme.colors.primaryText};
  height: 20px;	
  width: 112px;	
  font-family: Roboto;	
  font-size: 16px !important;	
  font-weight: bold;	
  line-height: 20px;
`;


const StyledAutoComplete = styled(AutoComplete)`
	&& {
		width: 100%;
		input {
			color: black;
		}
	}
`


export const Autocomplete = ({label, ...props}) => {
	const [datasource, setDatasource] = useState([])

	useEffect(() => {
		setDatasource(props.options || [])
	}, [props.options])

	function handleSearch(value) {
		if(value && !isEmpty(props.options)){
			const filtered = props.options.filter(function(opt){ return opt.name.indexOf(value) != -1})
			setDatasource(filtered)
		}
	}

	return (
		<WrapField>			
			<Label>{label}</Label>
			<StyledAutoComplete
				// onSelect={handleSelect}
				onSearch={handleSearch}
				{...props}>
				{datasource.map(item => (
					<Option key={item.pk}>{item.name}</Option>
				))}
			</StyledAutoComplete>
		</WrapField>
	)
}
