import { BaseObject } from '../BaseObject'

class ThemeApi extends BaseObject {
  constructor(client) {
    super(client)
  }

  get(sectionId) {
    return this.client.get(`api/v1/theme/app/`)
  }
}

export default ThemeApi
