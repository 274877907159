import '@fontsource/quicksand/300.css'
import '@fontsource/quicksand/400.css'
import '@fontsource/quicksand/500.css'
import '@fontsource/quicksand/600.css'
import '@fontsource/quicksand/700.css'
import WikiApi, { EAMApi } from '@wikicrm/api'
import Config from '@wikicrm/backoffice/src/config/config'
import { reduce } from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import CertificatesList from './components/certificates/CertificatesList'
import SearchPage from './components/search/SearchPage'
import Error from './components/shared/Error'
import SurveysList from './components/surveys/SurveysList'
import WikiContent from './components/wikis/WikiContent'
import WikiList from './components/wikis/WikiList'
import { GlobalContextProvider } from './context/GlobalContext'
import * as serviceWorker from './serviceWorker'

const routes = [
  {
    component: WikiList,
    exact: true,
    path: '/',
  },
  {
    path: '/wiki/:id/',
    exact: true,
    component: WikiContent,
  },
  {
    path: '/wiki/:id/survey/',
    exact: true,
    component: SurveysList,
  },
  {
    path: '/wiki/:id/certificates/',
    exact: true,
    component: CertificatesList,
  },
  {
    path: '/wiki/:id/search/',
    exact: true,
    component: SearchPage,
  },
]

const renderMainApp = () => {
  return ReactDOM.render(
    <BrowserRouter>
      <GlobalContextProvider>
        <App config={Config}>{renderRoutes(routes)}</App>
      </GlobalContextProvider>
    </BrowserRouter>,
    document.getElementById('root')
  )
}

const renderLoadingError = (err) => {
  const errString = JSON.stringify(err, Object.getOwnPropertyNames(err), '\t')

  return ReactDOM.render(
    <GlobalContextProvider>
      <App config={Config}>
        <Error err={err} errString={errString} />
      </App>
    </GlobalContextProvider>,
    document.getElementById('root')
  )
}

function getConfigFileName() {
  const defaultConfigFileName = 'config.json'

  if (process.env.NODE_ENV === 'development') {
    return 'config.json'
    // Todo: set CONFIG_FILE_NAME
    // eslint-disable-next-line no-undef
    // try {
    //   return CONFIG_FILE_NAME
    // } catch (e) {
    //   return defaultConfigFileName
    // }
  }

  return defaultConfigFileName
}

fetch(`/static/${getConfigFileName()}`).then((response) => {
  response
    .json()
    .then(async (data) => {
      const config = reduce(
        [data],
        (result, value) => {
          return { ...result, ...value }
        },
        {}
      )

      Config.set(config)

      await WikiApi.init(
        { baseURL: Config.get('API_URL') },
        {
          versioning: 'v2',
        }
      )
      await EAMApi.init({ baseURL: Config.get('EAM_API_URL') })

      return renderMainApp()
    })
    .catch((err) => {
      return renderLoadingError(err)
    })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
