import { EAMApi } from '@wikicrm/api'
import React, { useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import Box from 'ui-box'
import ReactGA from 'react-ga4'
import { GlobalContext } from '../../../context/GlobalContext'
import { SurveysContextProvider } from '../../../context/SurveysContext'
import { WikisContextProvider } from '../../../context/WikisContext'
import * as actions from '../../../store/actions/actions'
import Footer from '../Footer'
import Header from '../Header'
import { GlobalStyle, theme } from './styled'
import { get } from 'lodash'

const Theme = ({ logout, children, kc }) => {
  const { state, dispatch } = useContext(GlobalContext)

  useEffect(() => {
    const calculateAppHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    window.addEventListener('resize', calculateAppHeight)
    window.addEventListener('orientationchange', calculateAppHeight)
    calculateAppHeight()

    return () => {
      window.removeEventListener('resize', calculateAppHeight)
      window.removeEventListener('orientationchange', calculateAppHeight)
    }
  }, [])

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const orgRes = await EAMApi.Organization.my()
        actions.bootstrap(orgRes.data, theme, dispatch)

        const userEmail = get(kc, 'state.kc.userInfo.email', '')

        // TO DO: FIX OR REMOVE
        if (orgRes.data.client_id === 'sanofi') {
          ReactGA.initialize('G-CGE2685CG5')
          ReactGA.set({ username: userEmail })
          ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        }
      } catch (err) {
        console.log(err)
      }
    }

    fetchOrganization()
  }, [dispatch, kc])

  return state.bootstrapped ? (
    <ThemeProvider theme={state.theme}>
      <WikisContextProvider>
        <SurveysContextProvider>
          <GlobalStyle />
          <Box
            display="grid"
            gridTemplateRows="80px calc(var(--app-height) - 80px - 53px) 53px"
            gridTemplateAreas={`"Header" "Main" "Footer"`}
            height="100%"
          >
            <Header logout={logout} logo={state.organization.logo_url} />
            <Box is="main" gridArea="Main" position="relative">
              {children}
            </Box>
            <Footer />
          </Box>
        </SurveysContextProvider>
      </WikisContextProvider>
    </ThemeProvider>
  ) : null
}

export default Theme
