import React from 'react'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import Breadcrumbs from '../../shared/Breadcrumbs'
import FooterButtons from '../../shared/FooterButtons'

const Footer = ({ theme }) => {
  return (
    <Box
      is="footer"
      gridArea="Footer"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      backgroundColor="white"
      borderTop={`1px solid ${theme.borderColor}`}
      paddingX={22}
    >
      <Breadcrumbs />
      <FooterButtons />
    </Box>
  )
}

export default withTheme(Footer)
