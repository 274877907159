import { isEqual, isNull, keyBy, merge } from 'lodash'

export const bootstrap = (state, organization, theme) => {
  return {
    ...state,
    bootstrapped: true,
    organization,
    theme,
  }
}

export const fetchWikis = (state, wikis) => {
  const wikisData = wikis.data.map((wiki) => {
    return {
      ...wiki,
      allSections: {
        data: {},
      },
      allLectures: {
        data: {},
        ordered: [],
      },
    }
  })

  return {
    ...state,
    data: merge(state.data, keyBy(wikisData, 'unique_id')),
    alreadyFetched: true,
  }
}

export const setGenerictWikisActionType = (state, actionType) => {
  return {
    ...state,
    genericWikiActionType: actionType,
  }
}

export const setCurrentWiki = (state, currentWikiId, current) => {
  return {
    ...state,
    currentWikiId,
    data: {
      ...state.data,
      [currentWikiId]: merge(state.data[currentWikiId], {
        ...current.data,
        allSections: {
          data: {},
        },
        allLectures: {
          data: {},
          ordered: [],
        },
      }),
    },
  }
}

export const setCurrentSections = (state, currentWikiId, sections) => {
  return {
    ...state,
    currentWikiId,
    data: {
      ...state.data,
      [currentWikiId]: {
        ...state.data[currentWikiId],
        alreadyFetched: true,
        allSections: {
          ...state.data[currentWikiId].allSections,
          data: {
            ...state.data[currentWikiId].allSections.data,
            ...keyBy(sections.data, 'id'),
          },
        },
      },
    },
  }
}

export const setCurrentLectures = (state, currentWikiId, lectures) => {
  return {
    ...state,
    currentWikiId,
    data: {
      ...state.data,
      [currentWikiId]: {
        ...state.data[currentWikiId],
        allLectures: {
          ...state.data[currentWikiId].allLectures,
          data: {
            ...state.data[currentWikiId].allLectures.data,
            ...keyBy(lectures.data, 'id'),
          },
          ordered: [
            ...state.data[currentWikiId].allLectures.ordered,
            ...lectures.data,
          ],
        },
      },
    },
  }
}

export const setCurrentFile = (
  state,
  currentWikiId,
  currentLectureId,
  contentUrl,
  url,
  previewUrl
) => {
  return {
    ...state,
    currentWikiId,
    currentSectionId:
      state.data[currentWikiId].allLectures.data[currentLectureId].section,
    currentLectureId,
    data: {
      ...state.data,
      [currentWikiId]: {
        ...state.data[currentWikiId],
        allLectures: {
          ...state.data[currentWikiId].allLectures,
          data: {
            ...state.data[currentWikiId].allLectures.data,
            [currentLectureId]: {
              ...state.data[currentWikiId].allLectures.data[currentLectureId],
              contentUrl,
              url,
              previewUrl,
            },
          },
        },
      },
    },
  }
}

export const setSectionStatus = (
  state,
  currentWikiId,
  currentSectionId,
  actionType
) => {
  return {
    ...state,
    currentWikiId,
    data: {
      ...state.data,
      [currentWikiId]: {
        ...state.data[currentWikiId],
        alreadyFetched: true,
        allSections: {
          ...state.data[currentWikiId].allSections,
          data: {
            ...state.data[currentWikiId].allSections.data,
            [currentSectionId]: {
              ...state.data[currentWikiId].allSections.data[currentSectionId],
              actionType,
            },
          },
        },
      },
    },
  }
}

export const resetCurrentFile = (state, currentWikiId) => {
  return {
    ...state,
    currentWikiId,
    currentLectureId: '',
    currentSectionId: '',
  }
}

export const setCurrentLecture = (
  state,
  currentLectureId,
  currentSectionId
) => {
  return {
    ...state,
    currentLectureId,
    currentSectionId,
  }
}

export const fetchSurveys = (state, surveys) => {
  const surveysData = surveys.data.map((survey) => {
    return {
      ...survey,
      currentQuestionIndex: null,
      viewResults: false,
      checkAnswers: false,
      viewCertificates: false,
    }
  })

  return {
    ...state,
    data: merge(state.data, keyBy(surveysData, 'id')),
    alreadyFetched: true,
  }
}

export const setGenerictSurveysActionType = (state, actionType) => {
  return {
    ...state,
    genericSurveysActionType: actionType,
  }
}

export const setActiveItem = (state, activeItem) => {
  if (activeItem === state.activeItem) {
    return state
  }

  return {
    ...state,
    activeItem,
  }
}

export const setCurrentSurvey = (state, currentSurveyId) => {
  return {
    ...state,
    currentSurveyId,
  }
}

export const setCurrentQuestionIndex = (state, id, currentQuestionIndex) => {
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        currentQuestionIndex,
      },
    },
  }
}

export const saveParticipation = (state, id, data) => {
  const currentAttempt = !isNull(state.data[id].current_attempt)
    ? state.data[id].current_attempt
    : {}
  const currentAttemptData = !isNull(state.data[id].current_attempt)
    ? state.data[id].current_attempt.data
    : {}

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        current_attempt: {
          ...currentAttempt,
          data: {
            ...currentAttemptData,
            ...data,
          },
        },
      },
    },
  }
}

export const submitParticipation = (state, id, data, calculateScore) => {
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        ...data,
        currentQuestionIndex: null,
        checkAnswers: false,
        viewResults: calculateScore,
        viewCertificates: false,
      },
    },
  }
}

export const viewResults = (state, id, viewResults) => {
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        viewResults,
      },
    },
  }
}

export const checkAnswers = (state, id, checkAnswers) => {
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        checkAnswers,
      },
    },
  }
}

export const setSurveyResults = (state, id, results) => {
  if (isEqual(results, state.data[id].results)) {
    return state
  }

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        results,
      },
    },
  }
}

export const viewCertificates = (state, id, viewCertificates) => {
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        viewCertificates,
      },
    },
  }
}

export const showSaved = (state, id, showSaved) => {
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        showSaved,
      },
    },
  }
}

export const setSurveyCertificates = (state, id, certificates) => {
  if (isEqual(certificates, state.data[id].certificates)) {
    return state
  }

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        certificates,
      },
    },
  }
}

export const setLocale = (state, locale) => {
  if (isEqual(locale, state.locale)) {
    return state
  }

  return {
    ...state,
    locale,
  }
}

export const setActiveCertificate = (state, certificate) => {
  return {
    ...state,
    activeCertificate: certificate,
  }
}
