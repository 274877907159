import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { get, values, filter } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useParams } from 'react-router'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { GlobalContext } from '../../../context/GlobalContext'
import { WikisContext } from '../../../context/WikisContext'
import fetchWikiContent from '../../../services/fetchWikiContent'
import getLectureDownloadUrl from '../../../services/getLectureDownloadUrl'
import * as actions from '../../../store/actions/actions'
import WikiContentLecture from '../../wikis/WikiContentLecture/WikiContentLecture'

const SearchPage = ({ intl, theme }) => {
  const { id } = useParams()
  const { state, dispatch } = useContext(WikisContext)
  const { dispatch: globalDispatch } = useContext(GlobalContext)

  const currentWiki = state.data[id]

  const [keyword, setKeyword] = useState('')

  const handleChange = (e) => {
    setKeyword(e.target.value)
  }

  const lectures = values(get(currentWiki, 'allLectures.data', {}))
  const filteredLectures = filter(lectures, lecture => lecture.name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0)

  useEffect(() => {
    let didCancel = false
    fetchWikiContent({ didCancel, dispatch, currentWiki, id })

    return () => {
      actions.setActiveItem('', globalDispatch)
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    actions.resetCurrentFile(id, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickLecture = async (lecture) => {
    try {
      actions.setActiveItem('wiki', globalDispatch)
    } catch (err) {
      console.log(err)
    } finally {
      await getLectureDownloadUrl({ dispatch, currentWiki, lectureId: lecture.id, id })
    }
  }

  const renderContent = () => {
    if (!currentWiki || !currentWiki.alreadyFetched) {
      return <StyledSpin size="large" data-bg-color={theme.textColor} />
    }

    if (lectures.length <= 0) {
      return (
        <Box is="p" paddingTop="15px" color={theme.textColor} marginLeft="20px">
          <FormattedMessage id="wiki.empty.lectures" />
        </Box>
      )
    }

    return (
      <>
        {state.currentSectionId && state.currentLectureId && (
          <WikiContentLecture />
        )}

        <Box
          is="input"
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: 'search.keyword' })}
          width="100%"
          marginBottom={30}
          padding={20}
          borderRadius={100}
          border="1px solid"
          color={theme.greyLight}
          fontSize={28}
        />
        
        <Box paddingX={30}>
          {filteredLectures.length > 0 ?
            filteredLectures.map(lecture => (
              <Box
                key={lecture.id}
                fontSize={28}
                fontWeight={400}
                marginBottom={20}
                cursor="pointer"
                onClick={() => handleClickLecture(lecture)}
              >
                {lecture.name}
              </Box>
            )) : 
            <Box is="p" paddingTop="15px" color={theme.textColor} marginLeft="20px">
              <FormattedMessage id="wiki.empty.lectures.filtered" />
            </Box>
          }
        </Box>
      </>
    )
  }

  return (
    <Box padding={80}>
      {renderContent()}
    </Box>
  )
}

export default injectIntl(withTheme(SearchPage))
