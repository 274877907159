export default {
  'app.title': 'WikiWeb Eng',
  'header.logout': 'Logout',
  wikis: 'Wikis',
  download: 'Download',
  'download.notAvailable': 'Download not available',
  error: 'Error',
  'access.denied': 'Access denied',
  'wiki.emptyNode': 'Empty Section',
  'wiki.noData': 'No data available',
  'wiki.empty': 'There are no sections in this wiki.',
  'wiki.empty.lectures': 'There is no content in this wiki.',
  'wiki.empty.lectures.filtered': 'This search does not return any results.',
  'wiki.loadingPdf': 'Loading PDF...',
  'wiki.loadingIframe': 'Loading lecture...',
  'wiki.loadingPdfPage': 'Loading Page...',
  'wiki.pdfPrevPage': 'Previous Page',
  'wiki.pdfNextPage': 'Next Page',
  'wiki.pdfPageOfPages': 'Page {pageNumber} of {numPages}',
  'wiki.noPreviewNoDownload':
    "This file has no preview and can't be downloaded",
  'wiki.previewNotAvailable': 'Preview not available.',
  'wiki.content': 'Content',
  'wiki.selectContent': 'Select a lecture or a survey to view content.',
  'wiki.noSectionsOrSurveys': 'There are no sections or surveys in this wiki.',
  surveys: 'Questions',
  'surveys.noContent': 'There are no surveys associated to this Wiki.',
  'surveys.startDate': 'Start date',
  'surveys.endDate': 'End date',
  'surveys.about': 'About the survey',
  'surveys.start': 'Start',
  'surveys.results': 'Results',
  'surveys.send': 'Send',
  'surveys.check': 'Check',
  'surveys.save': 'Save',
  'surveys.attempts': 'Attempts',
  'surveys.saved': 'Survey saved successfully.',
  'surveys.saved.error': 'Error verified while saving. Please save again.',
  'surveys.sent': 'Survey sent successfully.',
  'surveys.sent.error': 'Error verified while sending. Please send again.',
  'surveys.noDescription': 'The survey has no description.',
  'surveys.passed': 'Survey passed.',
  'surveys.notPassed': 'Survey not passed.',
  'surveys.attemptsFinished': 'Attempts finished.',
  'surveys.expired': 'This survey is expired.',
  'surveys.yourAnswers': 'Your answers',
  'surveys.previousAttempt': 'Your previous attempt',
  'surveys.answer.correct': 'Correct',
  'surveys.answer.wrong': 'Wrong',
  'surveys.score': 'Score',
  'surveys.certificates': 'Certificates',
  'surveys.passed.congratulations': 'Congratulations you have passed the test',
  'surveys.passed.text': 'You answered {score}% of the questions correctly',
  'certificates.noContent': 'This survey certificates are not available',
  'certificates.download': 'Download certificate',
  'Certificate of completion': 'Survey certificate',
  'Personal certificate': 'Personal certificate',
  prev: 'Previous',
  next: 'Next',
  close: 'Close',
  search: 'Search',
  'search.keyword': 'Keyword',
}
