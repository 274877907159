import * as actionTypes from './actionTypes'

export const bootstrap = (organization, theme, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_ORGANIZATION,
    organization,
    theme,
  })
}

export const setGenericWikisActionType = (actionType, dispatch) => {
  dispatch({
    type: actionTypes.SET_GENERIC_WIKIS_ACTION_TYPE,
    actionType,
  })
}

export const fetchWikis = (wikis, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_WIKIS,
    wikis,
  })
}

export const setCurrentWiki = (currentWikiId, current, dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_WIKI,
    current,
    currentWikiId,
  })
}

export const setCurrentSections = (currentWikiId, sections, dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_SECTIONS,
    sections,
    currentWikiId,
  })
}

export const setCurrentLectures = (currentWikiId, lectures, dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_LECTURES,
    lectures,
    currentWikiId,
  })
}

export const setCurrentFile = (
  currentWikiId,
  currentLectureId,
  contentUrl,
  url,
  previewUrl,
  dispatch
) => {
  dispatch({
    type: actionTypes.SET_CURRENT_FILE,
    currentWikiId,
    currentLectureId,
    contentUrl,
    url,
    previewUrl,
  })
}

export const setSectionStatus = (
  currentWikiId,
  currentSectionId,
  actionType,
  dispatch
) => {
  dispatch({
    type: actionTypes.SET_SECTION_STATUS,
    actionType,
    currentWikiId,
    currentSectionId,
  })
}

export const resetCurrentFile = (currentWikiId, dispatch) => {
  dispatch({
    type: actionTypes.RESET_CURRENT_FILE,
    currentWikiId,
  })
}

export const setCurrentLecture = (
  currentLectureId,
  currentSectionId,
  dispatch
) => {
  dispatch({
    type: actionTypes.SET_CURRENT_LECTURE,
    currentLectureId,
    currentSectionId,
  })
}

export const fetchSurveys = (surveys, dispatch) => {
  dispatch({
    type: actionTypes.FETCH_SURVEYS,
    surveys,
  })
}

export const setGenericSurveysActionType = (actionType, dispatch) => {
  dispatch({
    type: actionTypes.SET_GENERIC_WIKIS_ACTION_TYPE,
    actionType,
  })
}

export const setActiveItem = (activeItem, dispatch) => {
  dispatch({
    type: actionTypes.SET_WIKI_ACTIVE_ITEM,
    activeItem,
  })
}

export const setCurrentSurvey = (currentSurveyId, dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_SURVEY,
    currentSurveyId,
  })
}

export const setCurrentQuestionIndex = (id, currentQuestionIndex, dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_QUESTION,
    id,
    currentQuestionIndex,
  })
}

export const saveParticipation = (id, data, dispatch) => {
  dispatch({
    type: actionTypes.SAVE_PARTICIPATION,
    id,
    data,
  })
}

export const submitParticipation = (id, data, calculateScore, dispatch) => {
  dispatch({
    type: actionTypes.SUBMIT_PARTICIPATION,
    id,
    data,
    calculateScore,
  })
}

export const viewResults = (id, viewResults, dispatch) => {
  dispatch({
    type: actionTypes.VIEW_RESULTS,
    id,
    viewResults,
  })
}

export const checkAnswers = (id, checkAnswers, dispatch) => {
  dispatch({
    type: actionTypes.CHECK_ANSWERS,
    id,
    checkAnswers,
  })
}

export const setSurveyResults = (id, results, dispatch) => {
  dispatch({
    type: actionTypes.SET_SURVEY_RESULTS,
    id,
    results,
  })
}

export const viewCertificates = (id, viewCertificates, dispatch) => {
  dispatch({
    type: actionTypes.VIEW_CERTIFICATES,
    id,
    viewCertificates,
  })
}

export const showSaved = (id, showSaved, dispatch) => {
  dispatch({
    type: actionTypes.SHOW_SAVED,
    id,
    showSaved,
  })
}

export const setSurveyCertificates = (id, certificates, dispatch) => {
  dispatch({
    type: actionTypes.SET_SURVEY_CERTIFICATES,
    id,
    certificates,
  })
}

export const setLocale = (locale, dispatch) => {
  dispatch({
    type: actionTypes.SET_LOCALE,
    locale,
  })
}

export const setActiveCertificate = (certificate, dispatch) => {
  dispatch({
    type: actionTypes.SET_ACTIVE_CERTIFICATE,
    certificate,
  })
}
