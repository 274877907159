import React, { useContext, useState } from 'react'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import fetchCertificates from '../../../services/fetchCertificates'
import { getSurveyIconColor } from '../../../utils/getSurveyIconColor'
import ListItem from '../../shared/ListItem'
import SurveyCertificates from '../SurveyCertificates'
import { StyledBox } from './styled'

const CertificateItem = ({ survey, theme }) => {
  const { dispatch } = useContext(SurveysContext)
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOpen = async () => {
    setIsOpen((open) => !open)

    if (!isOpen && !survey.certificates) {
      setLoading(true)
      await fetchCertificates({ id: survey.id, dispatch })
      setLoading(false)
    }
  }

  return (
    <>
      <StyledBox
        is="button"
        display="block"
        border="none"
        background="white"
        marginBottom={30}
        paddingX={30}
        cursor="pointer"
        width="100%"
        onClick={handleOpen}
      >
        <ListItem
          fontSize={28}
          name={survey.title}
          fontWeight={600}
          fill={getSurveyIconColor(survey, theme)}
          iconStyle={{ transform: !isOpen ? 'rotate(0deg)' : 'rotate(90deg)' }}
        />
      </StyledBox>

      {isOpen && (
        <Box marginBottom={30} paddingX={30}>
          <SurveyCertificates
            certificates={survey.certificates}
            loading={loading}
            surveyTitle={survey.title}
          />
        </Box>
      )}
    </>
  )
}

export default withTheme(CertificateItem)
