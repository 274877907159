import React from 'react'
import Box from 'ui-box'

const FileImage = ({ src, scale }) => {
  return (
    <Box
      is="img"
      maxWidth="100%"
      height="auto"
      src={src}
      transform={`scale(${scale})`}
      transformOrigin="0 0"
    />
  )
}

export default FileImage
