import SuccessCheckIcon from '@wikicrm/backoffice/src/assets/icons/SuccessCheck'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'

const SurveySaved = () => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      height="100%"
      background="white"
      zIndex={20}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width={104} height={104} marginBottom={40}>
        <SuccessCheckIcon width="100%" height="100%" />
      </Box>
      <Box is="p" margin={0} fontSize={24} fontWeight={700} color="#000">
        <FormattedMessage id="surveys.saved" />
      </Box>
    </Box>
  )
}

export default SurveySaved
