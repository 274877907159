import styled from "styled-components";

export const CollapsedButton = styled.div`
  position: absolute;
  bottom: 30px;
  right: -12.5px;
`;

export const WrapMenu = styled.div`
  position: relative;
  width: ${props => props.collapsed && "256px"};
  min-width: ${props => props.collapsed && "256px"};
  left: 0;
  margin-top: 64px;
  

  > ul {
    position: relative;
    /* border-right: 1px solid #e0e0e0; */
    border-right: 1px solid ${({theme}) => theme.colors.borders};
    height: calc(100vh - 64px);
    min-width: 90px;

    > li {
      border-bottom: 0.5px solid ${({theme}) => theme.colors.borders};
      background-color: transparent !important;
      height: 70px !important;
      display: flex;
      align-items: center;
      margin: 0 !important;

      > i {
        > i {
          width:  32px !important;
          height: 32px !important;
          color: ${({ color, theme, themed }) => (color ? color : themed ? theme.colors.icons : undefined)};
        }
      }
    }
  }
  .ant-menu-item-selected {
    background: ${({theme}) => theme.colors.primaryVariant} !important;

    &:after {
      right: initial !important;
      left: 0 !important;
      border-left: 6px solid ${({theme}) => theme.colors.primary} !important;
      border-right: #0000;
      transform: scaleY(1) !important;
      opacity: 1 !important;
    }
  }
  .ant-menu-item {
    &:after {
      right: initial !important;
      left: 0 !important;
    }
  }
`;

export const MenuText = styled.span`
  margin-left: 20px;
`;

