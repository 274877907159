import { Collapse } from "antd";
import React from "react";
import { isEmpty } from 'lodash'

import { withTheme } from "styled-components";

import {
  WrapSidebar,
  Text,
  StyledHeader,
  StyledIcon
} from './styled'


const Header = withTheme(({ icon, title, theme }) => (
  <StyledHeader>
    {typeof icon === "string" ? (
      <StyledIcon
        type={icon}
      />
    ) : (
      icon
    )}
    <Text style={{ textAlign: 'center', userSelect: 'none' }}>{title}</Text>
  </StyledHeader>
));



export const renderCollapsible = (key, title, icon, children) => {
  return (
    <Collapse.Panel
      header={<Header icon={icon} title={title} />}
      key={key}
      showArrow={false}
    >
      {children}
    </Collapse.Panel>
  );
};

export const Sidebar = ({ data, children, ...props }) => {
  const { defaultActiveKey } = props
  return (
    <WrapSidebar>
      <Collapse {...props}>{children}</Collapse>
    </WrapSidebar>
  );
};
