import React, { useState, useEffect } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import { FormattedMessage } from 'react-intl';
import Box from 'ui-box';
import styled from 'styled-components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledBox = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`

const FilePdf = ({ src, scale }) => {
  const [numPages, setNumPages] = useState(null)
  const [file, setFile] = useState(null)

  useEffect(() => {
    // https://github.com/wojtekmaj/react-pdf/issues/353#issuecomment-464712419
    setFile({ url: src })
  }, [src])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const renderPages = () => {
    if (!numPages) {
      return null
    }

    return Array.from(
      new Array(numPages),
      (el, i) => (
        <StyledBox
          key={`page-${i + 1}`}
          marginBottom="20px"
        >
          <Page 
            scale={scale} 
            pageNumber={i + 1} 
          />
        </StyledBox>
      ),
    )
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent={numPages && 'center'}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <Box
              is="h4"
              paddingY="15px"
              paddingX="30px"
              margin="0"
            >
              <FormattedMessage id="wiki.loadingPdf" />
            </Box>
          }
        >
          {renderPages()}
        </Document>
      </Box>
    </>
  )
}

export default FilePdf
