import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { values } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import { WikisContext } from '../../../context/WikisContext'
import fetchSurveysList from '../../../services/fetchSurveysList'
import ListItem from '../../shared/ListItem'
import ActiveCertificate from '../ActiveCertificate'
import CertificateItem from '../CertificateItem'

const CertificatesList = ({ theme }) => {
  const { id } = useParams()
  const { state, dispatch: wikiDispatch } = useContext(WikisContext)
  const { state: surveyState, dispatch: surveyDispatch } =
    useContext(SurveysContext)
  const currentWiki = state.data[id]

  useEffect(() => {
    let didCancel = false

    fetchSurveysList({
      didCancel,
      surveyDispatch,
      id,
      wikiDispatch,
      currentWiki,
    })

    return () => {
      didCancel = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    surveyState.genericSurveysActionType.name === 'FETCH_SURVEYS' &&
    surveyState.genericSurveysActionType.status === 'SUCCESS' &&
    !values(surveyState.data).filter((survey) => survey.wiki === id).length
  ) {
    return (
      <Box is="p" paddingTop="15px" color={theme.textColor} marginLeft="20px">
        <FormattedMessage id="certificates.noContent" />
      </Box>
    )
  }

  if (
    !surveyState.alreadyFetched ||
    !values(surveyState.data).filter((survey) => survey.wiki === id).length
  ) {
    return <StyledSpin size="large" data-bg-color={theme.textColor} />
  }

  return (
    <>
      {surveyState.activeCertificate && <ActiveCertificate />}

      <Box paddingY={80}>
        {currentWiki && (
          <Box paddingX={80} marginBottom={30}>
            <Link to="/">
              <ListItem
                name={currentWiki.name}
                iconStyle={{ transform: 'rotate(180deg)' }}
              />
            </Link>
          </Box>
        )}

        <Box paddingX={80}>
          {currentWiki &&
            surveyState.data &&
            values(surveyState.data)
              .filter((survey) => survey.wiki === id)
              .map((survey) => (
                <CertificateItem key={survey.id} survey={survey} />
              ))}
        </Box>
      </Box>
    </>
  )
}

export default withTheme(CertificatesList)
