import axios from 'axios'
import { BaseObject } from '../BaseObject'

class LecturesApi extends BaseObject {
  constructor(client, options) {
    super(client, options)
  }

  get(lectureId) {
    return this.client.get(`api/${this.versioning}/lectures/${lectureId}/`)
  }

  put(lectureId, data) {
    return this.client.put(`api/${this.versioning}/lectures/${lectureId}`, data)
  }

  patch(lectureId, data) {
    return this.client.patch(`api/${this.versioning}/lectures/${lectureId}/`, data)
  }

  delete(lectureId) {
    return this.client.delete(`api/${this.versioning}/lectures/${lectureId}/`)
  }

  activity(lectureId) {
    return this.client.get(`api/${this.versioning}/lectures/${lectureId}/activity/`)
  }

  getContent(lectureId) {
    return this.client.get(`api/${this.versioning}/lectures/${lectureId}/content/`)
  }

  getDownloadUrl(lectureId) {
    return this.client.get(`api/${this.versioning}/lectures/${lectureId}/download/`)
  }

  move(lectureId, data) {
    return this.client.post(`api/${this.versioning}/lectures/${lectureId}/move/`, data)
  }

  restore(lectureId) {
    return this.client.post(`api/${this.versioning}/lectures/${lectureId}/restore/`)
  }

  getAssignees(id) {
    return this.client.get(`api/${this.versioning}/lectures/${id}/assign/`)
  }

  assign(id, data) {
    return this.client.put(`api/${this.versioning}/lectures/${id}/assign/`, data)
  }
}

export default LecturesApi
