import { isNull } from 'lodash'
import moment from 'moment'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'

const SurveyInfo = ({
  currentSurveyId,
  startDate,
  endDate,
  description,
  questions,
  answers,
  currentQuestionIndex,
  maxAttempts,
  participation,
  currentAttempt,
  theme,
  calculateScore,
  prevAttempts,
  viewResults,
  dispatch,
}) => {
  const getAttempts = () => {
    if (isNull(participation)) {
      return `0/${maxAttempts}`
    } else {
      return `${participation.attempt_number}/${maxAttempts}`
    }
  }

  return (
    <Box paddingX={30} fontSize={24}>
      <Box is="p" marginBottom={20} fontWeight={600}>
        <FormattedMessage id="surveys.about" />
      </Box>

      <Box is="p" marginBottom={20} fontWeight={300}>
        {description || <FormattedMessage id="surveys.noDescription" />}
      </Box>

      <Box is="p" marginBottom={5}>
        <Box is="span" fontWeight={700}>
          <FormattedMessage id="surveys.startDate" />
        </Box>
        :{' '}
        <Box is="span" fontWeight={300}>
          {moment(startDate).format('DD/MM/YY')}
        </Box>
      </Box>

      <Box is="p" marginBottom={5}>
        <Box is="span" fontWeight={700}>
          <FormattedMessage id="surveys.endDate" />
        </Box>
        :{' '}
        <Box is="span" fontWeight={300}>
          {moment(endDate).format('DD/MM/YY')}
        </Box>
      </Box>

      <Box is="p" marginBottom={20}>
        <Box is="span" fontWeight={700}>
          <FormattedMessage id="surveys.attempts" />:
        </Box>{' '}
        <Box is="span" fontWeight={300}>
          {maxAttempts === -1 ? (
            <>
              {!isNull(participation) ? participation.attempt_number : 0}/
              {'&#8734;'.replace(/&#(\d+);/g, (match, dec) => {
                return String.fromCharCode(dec)
              })}
            </>
          ) : (
            getAttempts()
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SurveyInfo
