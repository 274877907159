import React from 'react'
import Box from 'ui-box'

const FileVideo = ({ src }) => {
  return (
    <Box
      is="video"
      width="auto"
      height="100%"
      controls={true}
    >
      <source src={src} type="video/mp4" />
    </Box>
  )
}

export default FileVideo
