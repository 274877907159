import { EAMApi } from '@wikicrm/api'
import { keyBy, map, values, differenceBy } from 'lodash'
import { formatMessage } from 'umi-plugin-locale'
import fromCamelToSnake from '../core/utils/fromCamelToSnake'
export const USER_NAMESPACE = 'users'

export const FETCH_USERS = `${USER_NAMESPACE}/fetchUsers`
export const REQUEST_ADD_NEW_USER = `${USER_NAMESPACE}/requestAddNewUser`
export const ADD_NEW_USER = `${USER_NAMESPACE}/addNewUserEffect`
export const SET_CURRENT_USER = `${USER_NAMESPACE}/setCurrentUser`
export const GET_USER_DETAILS = `${USER_NAMESPACE}/getUserDetailsEffect`
export const DELETE_USER = `${USER_NAMESPACE}/deleteUserEffect`
export const PATCH_USER = `${USER_NAMESPACE}/patchUserEffect`
export const CHANGE_USER_STATUS = `${USER_NAMESPACE}/changeUserStatusEffect`
export const RESET_PASSWORD = `${USER_NAMESPACE}/resetPasswordEffect`

export const FETCH_GROUPS = `${USER_NAMESPACE}/fetchGroups`
export const REQUEST_ADD_NEW_GROUP = `${USER_NAMESPACE}/requestAddNewGroup`
export const ADD_NEW_GROUP = `${USER_NAMESPACE}/addNewGroupEffect`
export const SET_CURRENT_GROUP = `${USER_NAMESPACE}/setCurrentGroup`
export const GET_GROUP_DETAILS = `${USER_NAMESPACE}/getGroupDetailsEffect`
export const DELETE_GROUP = `${USER_NAMESPACE}/deleteGroupEffect`
export const PATCH_GROUP = `${USER_NAMESPACE}/patchGroupEffect`
export const DELETE_SUB_GROUP = `${USER_NAMESPACE}/deleteSubGroupEffect`

export const GET_GROUP_USERS = `${USER_NAMESPACE}/getGroupUsersEffect`
export const GET_USER_GROUPS = `${USER_NAMESPACE}/getUserGroupsEffect`
export const ADD_USERS_TO_GROUP = `${USER_NAMESPACE}/addUsersToGroupEffect`
export const ADD_GROUPS_TO_USER = `${USER_NAMESPACE}/addGroupsToUserEffect`
export const REMOVE_USERS_FROM_GROUP = `${USER_NAMESPACE}/removeUsersFromGroupEffect`
export const REMOVE_GROUPS_FROM_USER = `${USER_NAMESPACE}/removeGroupsFromUserEffect`

export const FETCH_USER_ROLES = `${USER_NAMESPACE}/fetchUserRolesEffect`
export const ASSIGN_USER_ROLES = `${USER_NAMESPACE}/assignUserRolesEffect`
export const REMOVE_USER_ROLES = `${USER_NAMESPACE}/removeUserRolesEffect`
export const FETCH_USER_REALMS = `${USER_NAMESPACE}/fetchUserRealmsEffect`
export const ASSIGN_USER_REALMS = `${USER_NAMESPACE}/assignUserRealmsEffect`
export const REMOVE_USER_REALMS = `${USER_NAMESPACE}/removeUserRealmsEffect`

export const FETCH_GROUP_ROLES = `${USER_NAMESPACE}/fetchGroupRolesEffect`
export const ASSIGN_GROUP_ROLES = `${USER_NAMESPACE}/assignGroupRolesEffect`
export const REMOVE_GROUP_ROLES = `${USER_NAMESPACE}/removeGroupRolesEffect`

export const DELETE_MULTIPLE_USERS = `${USER_NAMESPACE}/deleteMultipleUsersEffect`
export const CHANGE_MULTIPLE_USERS_STATUS = `${USER_NAMESPACE}/changeMultipleUsersStatusEffect`
export const DELETE_MULTIPLE_GROUPS = `${USER_NAMESPACE}/deleteMultipleGroupsEffect`
export const ADD_MULTIPLE_USERS_TO_GROUPS = `${USER_NAMESPACE}/addMultipleUsersToGroupsEffect`

export const FETCH_GROUP_SUPERVISORS = `${USER_NAMESPACE}/fetchGroupSupervisorsEffect`
export const ADD_SUPERVISOR_TO_GROUP = `${USER_NAMESPACE}/addGroupSupervisorEffect`
export const REMOVE_GROUP_SUPERVISOR = `${USER_NAMESPACE}/removeGroupSupervisorEffect`

export const FETCH_SUPERVISED_USERS = `${USER_NAMESPACE}/fetchSupervisedUsers`
export const FETCH_SUPERVISED_GROUPS = `${USER_NAMESPACE}/fetchSupervisedGroups`

const initState = {
  users: {
    data: {},
    alreadyFetched: false,
    status: null,
    currentUserId: ''
  },
  genericUserAction: {
    name: null,
    status: null,
    err: {}
  },
  groups: {
    data: {},
    alreadyFetched: false,
    status: null,
    currentGroupId: ''
  },
  genericGroupAction: {
    name: null,
    status: null,
    err: {}
  },
}

export default {
  namespace: USER_NAMESPACE,
  state: initState,
  reducers: {
    setFetchingUsersStatus(state, { payload: { status } }) {
      return {
        ...state,
        users: {
          ...state.users,
          status: status
        }
      }
    },
    setUsers(state, { payload: users }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: users,
          alreadyFetched: true,
        }
      }
    },
    addNewUser(state, { payload: { newUser } }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [newUser.id]: newUser
          },
          currentUserId: newUser.id
        }
      }
    },
    setCurrentUser(state, { current, currentUserId }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [current.id]: current,
          },
          currentUserId
        }
      }
    },
    setGenericUserStatus(state, { payload: { name, status, err } }) {
      return {
        ...state,
        genericUserAction: {
          name,
          status,
          err,
        }
      }
    },
    setUserStatus(state, { payload: { id, actionType } }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              actionType
            }
          }
        }
      }
    },
    deleteUser(state, { payload: { currentUserId } }) {
      const newData = { ...state.users.data }
      delete newData[currentUserId]

      return {
        ...state,
        users: {
          ...state.users,
          data: newData,
          currentUserId: ''
        },
      }
    },
    patchUser(state, { payload: { id, patchedUser } }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              firstName: patchedUser.firstName,
              lastName: patchedUser.lastName,
              email: patchedUser.email,
            }
          },
        }
      }
    },
    changeUserStatus(state, { id, enabled } ) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              enabled
            }
          },
        }
      }
    },
    setFetchingGroupsStatus(state, { payload: { status } }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          status: status
        }
      }
    },
    setGroups(state, { payload: groups }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: groups,
          alreadyFetched: true,
        }
      }
    },
    setGenericGroupStatus(state, { payload: { name, status, err } }) {
      return {
        ...state,
        genericGroupAction: {
          name,
          status,
          err,
        }
      }
    },
    addNewGroup(state, { payload: { newGroup }}) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [newGroup.id]: newGroup
          },
          currentGroupId: newGroup.id
        }
      }
    },
    setCurrentGroup(state, { current, currentGroupId }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [currentGroupId]: {
              ...state.groups.data[currentGroupId],
              ...current
            },
          },
          currentGroupId
        }
      }
    },
    setGroupStatus(state, { payload: { id, actionType } }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [id]: {
              ...state.groups.data[id],
              actionType
            }
          }
        }
      }
    },
    deleteGroup(state, { payload: { currentGroupId } }) {
      const newData = { ...state.groups.data }
      delete newData[currentGroupId]

      return {
        ...state,
        groups: {
          ...state.groups,
          data: newData,
          currentGroupId: ''
        },
      }
    },
    patchGroup(state, { payload: { id, patchedGroup } }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [id]: {
              ...state.groups.data[id],
              name: patchedGroup.name,
            }
          },
        }
      }
    },
    deleteSubGroup(state, { subGroupId, currentGroupId }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [currentGroupId]: {
              ...state.groups.data[currentGroupId],
              subGroups: state.groups.data[currentGroupId].subGroups.filter(subGroup => subGroup.id !== subGroupId)
            }
          },
        }
      }
    },
    setUserRoles(state, { id, roles, availables, composites }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              roles,
              availables,
              composites,
              userRolesAlreadyFetched: true,
            }
          }
        }
      }
    },
    setUserRealms(state, { id, realms, availableRealms, compositeRealms }) {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              realms,
              availableRealms,
              compositeRealms,
              userRealmsAlreadyFetched: true,
            }
          }
        }
      }
    },
    setGroupRoles(state, { id, roles, availables, composites }) {
      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [id]: {
              ...state.groups.data[id],
              roles,
              availables,
              composites,
              groupRolesAlreadyFetched: true
            }
          }
        }
      }
    },
    assignGroupRoles(state, { id, roles }) {
      const availables = keyBy(differenceBy(values(state.groups.data[id].availables), roles, 'id'), 'id')
      const newRoles = roles.map(role => {
        return {
          ...role,
          disabled: false,
          title: role.name,
          key: role.id,
        }
      })

      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [id]: {
              ...state.groups.data[id],
              availables,
              roles: {
                ...state.groups.data[id].roles,
                ...keyBy(newRoles, 'id')
              }
            }
          }
        }
      }
    },
    assignUserRoles(state, { id, roles }) {
      const availables = keyBy(differenceBy(values(state.users.data[id].availables), roles, 'id'), 'id')
      const newRoles = roles.map(role => {
        return {
          ...role,
          disabled: false,
          title: role.name,
          key: role.id,
        }
      })

      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              availables,
              roles: {
                ...state.users.data[id].roles,
                ...keyBy(newRoles, 'id')
              }
            }
          }
        }
      }
    },
    removeGroupRoles(state, { id, roles }) {
      const newRoles = roles.map(role => {
        return {
          ...role,
          disabled: false,
          title: role.name,
          key: role.id,
        }
      })

      const availables = {
        ...state.groups.data[id].availables,
        ...keyBy(newRoles, 'id')
      }

      const notAvailables = differenceBy(values(state.groups.data[id].composites).concat(values(state.groups.data[id].roles)),
        roles, 'id')

      return {
        ...state,
        groups: {
          ...state.groups,
          data: {
            ...state.groups.data,
            [id]: {
              ...state.groups.data[id],
              roles: keyBy(notAvailables, 'id'),
              availables,
            }
          }
        }
      }
    },
    removeUserRoles(state, { id, roles }) {
      const newRoles = roles.map(role => {
        return {
          ...role,
          disabled: false,
          title: role.name,
          key: role.id,
        }
      })

      const availables = {
        ...state.users.data[id].availables,
        ...keyBy(newRoles, 'id')
      }

      const notAvailables = differenceBy(values(state.users.data[id].composites).concat(values(state.users.data[id].roles)),
        roles, 'id')

      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              roles: keyBy(notAvailables, 'id'),
              availables,
            }
          }
        }
      }
    },
    assignUserRealms(state, { id, roles }) {
      const availableRealms = keyBy(differenceBy(values(state.users.data[id].availableRealms), roles, 'id'), 'id')
      const newRealms = roles.map(role => {
        return {
          ...role,
          disabled: false,
          title: role.name,
          key: role.id,
        }
      })

      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              availableRealms,
              realms: {
                ...state.users.data[id].realms,
                ...keyBy(newRealms, 'id')
              }
            }
          }
        }
      }
    },
    removeUserRealms(state, { id, roles }) {
      const newRealms = roles.map(role => {
        return {
          ...role,
          disabled: false,
          title: role.name,
          key: role.id,
        }
      })

      const availableRealms = {
        ...state.users.data[id].availableRealms,
        ...keyBy(newRealms, 'id')
      }

      const notAvailableRealms = differenceBy(values(state.users.data[id].compositeRealms).concat(values(state.users.data[id].realms)),
        roles, 'id')

      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            [id]: {
              ...state.users.data[id],
              realms: keyBy(notAvailableRealms, 'id'),
              availableRealms,
            }
          }
        }
      }
    }
  },
  effects: {
    fetchUsers: [
      function* (action, { put }) {
        yield put({
          type: 'setFetchingUsersStatus',
          payload: {
            status: 'FETCHING'
          }
        })

        try {
          const response = action.simple ? yield EAMApi.Users.getAllSimple() : yield EAMApi.Users.getAll()

          yield put({
            type: 'setFetchingUsersStatus',
            payload: {
              status: 'SUCCESS'
            }
          })

          const data = map(response.data, obj => {
            obj.actionType = {
              name: '',
              status: '',
              err: {}
            }

            return obj
          })

          yield put({
            type: 'setUsers',
            payload: keyBy(data, 'id')
          })
        } catch(err) {
          yield put({
            type: 'setFetchingUsersStatus',
            payload: {
              status: 'ERROR'
            }
          })

          console.log(err)
        }
      },
      { type: 'takeLatest' },
    ],
    requestAddNewUser: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericUserStatus',
          payload: {
            name: null,
            status: null,
            err: {}
          }
        })
      },
      { type: 'takeLatest' }
    ],
    addNewUserEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericUserStatus',
          payload: {
            name: 'ADD_NEW_USER',
            status: 'RUNNING'
          }
        })

        try {
          const result = yield EAMApi.Users.create(fromCamelToSnake(action.payload.values))

          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'ADD_NEW_USER',
              status: 'SUCCESS'
            }
          })

          const newUser = {
            ...result.data,
            actionType: {
              name: '',
              status: '',
              err: {}
            }
          }

          yield put({
            type: 'addNewUser',
            payload: {
              newUser
            }
          })

          action.payload.callback(
            result.data.id,
            true,
            'success',
            formatMessage({ id: 'message.addUserSuccess' })
          );
        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'ADD_NEW_USER',
              status: 'ERROR',
              err
            }
          })

          action.payload.callback(
            null,
            true,
            'error',
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message ||
            formatMessage({ id: 'message.genericError' })
          );
        }
      },
      { type: 'takeLatest' }
    ],
    deleteUserEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.currentUserId,
            actionType: {
              name: 'DELETE_USER',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        yield put({
          type: 'setGenericUserStatus',
          payload: {
            name: 'DELETE_USER',
            status: 'RUNNING',
            err: {}
          }
        })

        try {
          yield EAMApi.Users.delete(action.currentUserId)

          yield put({
            type: 'deleteUser',
            payload: {
              currentUserId: action.currentUserId,
            }
          })

          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'DELETE_USER',
              status: 'SUCCESS',
              err: {}
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'message.deleteUserSuccess' })
            )
          }
        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'DELETE_USER',
              status: 'ERROR',
              err
            }
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.currentUserId,
              actionType: {
                name: 'DELETE_USER',
                status: 'ERROR',
                err
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    patchUserEffect: [
      function* (action, { put }) {

        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'PATCH_USER',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield EAMApi.Users.patch(action.payload.id, fromCamelToSnake(action.payload.user))

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'PATCH_USER',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'patchUser',
            payload: {
              id: action.payload.id,
              patchedUser: {
                firstName: action.payload.user.firstName,
                lastName: action.payload.user.lastName,
                email: action.payload.user.email,
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'message.patchUserSuccess' })
            )
          }

        } catch(err) {
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'PATCH_USER',
                status: 'ERROR',
                err
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    getUserDetailsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericUserStatus',
          payload: {
            name: 'GET_USER_DETAILS',
            status: 'RUNNING',
            err: {}
          }
        })

        try {
          const result = yield EAMApi.Users.getDetails(action.id)

          result.data.actionType = {
            name: '',
            status: '',
            err: {},
          }

          yield put({
            type: 'setCurrentUser',
            current: result.data,
            currentUserId: result.data.id
          })

          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'GET_USER_DETAILS',
              status: 'SUCCESS',
              err: {}
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'GET_USER_DETAILS',
              status: 'ERROR',
              err
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    changeUserStatusEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'CHANGE_USER_STATUS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'changeUserStatus',
            id: action.payload.id,
            enabled: action.payload.enabled
          })

          yield EAMApi.Users.changeStatus(action.payload.id, { enabled: action.payload.enabled })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'CHANGE_USER_STATUS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'message.changeUserStatusSuccess' })
            )
          }

        } catch(err) {
          yield put({
            type: 'changeUserStatus',
            id: action.payload.id,
            enabled: !action.payload.enabled
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'CHANGE_USER_STATUS',
                status: 'ERROR',
                err
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    fetchGroups: [
      function* (action, { put }) {
        yield put({
          type: 'setFetchingGroupsStatus',
          payload: {
            status: 'FETCHING'
          }
        })

        try {
          const response = action.flat ? yield EAMApi.Groups.getAllFlat() : yield EAMApi.Groups.getAll()

          yield put({
            type: 'setFetchingGroupsStatus',
            payload: {
              status: 'SUCCESS'
            }
          })

          yield put({
            type: 'setGroups',
            payload: keyBy(response.data.groups, 'id')
          })
        } catch(err) {
          yield put({
            type: 'setFetchingGroupsStatus',
            payload: {
              status: 'ERROR'
            }
          })

          console.log(err)
        }
      },
      { type: 'takeLatest' },
    ],
    addNewGroupEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericGroupStatus',
          payload: {
            name: 'ADD_NEW_GROUP',
            status: 'RUNNING',
            err: {}
          }
        })

        try {
          const response = yield EAMApi.Groups.create(action.payload.values)

          const newGroup = {
            ...response.data,
            actionType: {
              name: '',
              status: '',
              err: {}
            }
          }

          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'ADD_NEW_GROUP',
              status: 'SUCCESS',
              err: {}
            }
          })

          yield put({
            type: 'addNewGroup',
            payload: {
              newGroup
            }
          })

          action.payload.callback(
            response.data.id,
            true,
            'success',
            formatMessage({ id: 'message.addGroupSuccess' })
          );
        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'ADD_NEW_GROUP',
              status: 'ERROR',
              err
            }
          })

          action.payload.callback(
            null,
            true,
            'error',
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message ||
            formatMessage({ id: 'message.genericError' })
          );
        }
      },
      { type: 'takeLatest' },
    ],
    requestAddNewGroup: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericGroupStatus',
          payload: {
            name: null,
            status: null,
            err: {}
          }
        })
      },
      { type: 'takeLatest' }
    ],
    getGroupDetailsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGenericGroupStatus',
          payload: {
            name: 'GET_GROUP_DETAILS',
            status: 'RUNNING',
            err: {}
          }
        })

        try {
          const result = yield EAMApi.Groups.getDetails(action.id)

          result.data.actionType = {
            name: '',
            status: '',
            err: {},
          }

          yield put({
            type: 'setCurrentGroup',
            current: result.data,
            currentGroupId: action.id
          })

          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'GET_GROUP_DETAILS',
              status: 'SUCCESS',
              err: {}
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'GET_GROUP_DETAILS',
              status: 'ERROR',
              err
            }
          })
        }
      }
    ],
    deleteGroupEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.currentGroupId,
            actionType: {
              name: 'DELETE_GROUP',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        yield put({
          type: 'setGenericGroupStatus',
          payload: {
            name: 'DELETE_GROUP',
            status: 'RUNNING',
            err: {}
          }
        })

        try {
          yield EAMApi.Groups.delete(action.currentGroupId)

          yield put({
            type: 'deleteGroup',
            payload: {
              currentGroupId: action.currentGroupId,
            }
          })

          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'DELETE_GROUP',
              status: 'SUCCESS',
              err: {}
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'message.deleteGroupSuccess' })
            )
          }
        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'DELETE_GROUP',
              status: 'ERROR',
              err
            }
          })

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.currentGroupId,
              actionType: {
                name: 'DELETE_GROUP',
                status: 'ERROR',
                err
              }
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    patchGroupEffect: [
      function* (action, { put }) {

        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'PATCH_GROUP',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield EAMApi.Groups.patch(action.payload.id, fromCamelToSnake(action.payload.group))

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'PATCH_GROUP',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          yield put({
            type: 'patchGroup',
            payload: {
              id: action.payload.id,
              patchedGroup: {
                name: action.payload.group.name,
              }
            }
          })

        } catch(err) {
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'PATCH_GROUP',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    getGroupUsersEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'GET_GROUP_USERS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield EAMApi.Groups.getUsers(action.id)

          yield put({
            type: 'setCurrentGroup',
            current: {
              ...action.current,
              users: keyBy(response.data, 'id'),
            },
            currentGroupId: action.id
          })

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_GROUP_USERS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_GROUP_USERS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    getUserGroupsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'GET_USER_GROUPS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield EAMApi.Users.getGroups(action.id)

          yield put({
            type: 'setCurrentUser',
            current: {
              ...action.current,
              groups: keyBy(response.data, 'id'),
            },
            currentUserId: action.id
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_USER_GROUPS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'GET_USER_GROUPS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    addUsersToGroupEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'ADD_USERS_TO_GROUPS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const current = {
            ...action.payload.currentGroup,
            users: action.payload.users,
          }

          yield put({
            type: 'setCurrentGroup',
            current: current,
            currentGroupId: current.id
          })

          yield EAMApi.Groups.addUsers(action.payload.id, action.payload.usersIds)

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ADD_USERS_TO_GROUPS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ADD_USERS_TO_GROUPS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    addGroupsToUserEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'ADD_GROUPS_TO_USER',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const current = {
            ...action.payload.currentUser,
            groups: action.payload.groups,
          }

          yield put({
            type: 'setCurrentUser',
            current: current,
            currentUserId: current.id
          })

          yield EAMApi.Groups.addUsers(action.payload.groupId, action.payload.usersIds)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ADD_GROUPS_TO_USER',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ADD_GROUPS_TO_USER',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removeUsersFromGroupEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_USERS_FROM_GROUP',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const current = {
            ...action.payload.currentGroup,
            users: values(action.payload.currentGroup.users).filter(user => user.id !== action.payload.usersIds[0]),
          }

          yield put({
            type: 'setCurrentGroup',
            current: current,
            currentGroupId: current.id
          })

          yield EAMApi.Groups.removeUsers(action.payload.id, action.payload.usersIds)

          yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_USERS_FROM_GROUP',
              status: 'SUCCESS',
              err: {}
            }
          }
        })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_USERS_FROM_GROUP',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removeGroupsFromUserEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_GROUPS_FROM_USER',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const current = {
            ...action.payload.currentUser,
            groups: values(action.payload.currentUser.groups).filter(group => group.id !== action.payload.groupsIds[0]),
          }

          yield put({
            type: 'setCurrentUser',
            current: current,
            currentUserId: current.id
          })

          yield EAMApi.Groups.removeUsers(action.payload.groupId, action.payload.usersIds)

          yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_GROUPS_FROM_USER',
              status: 'SUCCESS',
              err: {}
            }
          }
        })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_GROUPS_FROM_USER',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    deleteSubGroupEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.currentGroupId,
            actionType: {
              name: 'DELETE_SUB_GROUP',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'deleteSubGroup',
            subGroupId: action.subGroupId,
            currentGroupId: action.currentGroupId
          })

          yield EAMApi.Groups.delete(action.subGroupId)

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.currentGroupId,
              actionType: {
                name: 'DELETE_SUB_GROUP',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.currentGroupId,
              actionType: {
                name: 'DELETE_SUB_GROUP',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    fetchGroupRolesEffect: [
      function* (action, { put, all, call }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'FETCH_GROUP_ROLES',
              status: 'FETCHING',
              err: {}
            }
          }
        })

        try {
          const rolesToFetch = [
            {
              method: 'getAvailableRoles',
              role: 'availables',
            },
            {
              method: 'getCompositeRoles',
              role: 'composites',
            },
            {
              method: 'getRoles',
              role: 'roles',
            }
          ]

          function* fetchRoles(roleToFetch, id, clientId) {
            const res = yield EAMApi.Groups[roleToFetch.method](id, clientId)
            return {
              role: roleToFetch.role,
              data: res.data
            }
          }

          const responses = keyBy(
            yield all(rolesToFetch.map(roleToFetch =>
              call(fetchRoles, roleToFetch, action.payload.id, action.payload.clientId)
            )), 'role'
          )

          console.log(responses)

          const availables = responses.availables.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: false,
            }
          })

          const composites = responses.composites.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: true,
            }
          })

          const roles = responses.roles.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: false,
            }
          })

          yield put({
            type: 'setGroupRoles',
            id: action.payload.id,
            roles: keyBy(roles, 'id'),
            availables: keyBy(availables, 'id'),
            composites: keyBy(composites, 'id'),
          })

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'FETCH_GROUP_ROLES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'FETCH_GROUP_ROLES',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    fetchUserRolesEffect: [
      function* (action, { put, all, call }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'FETCH_USER_ROLES',
              status: 'FETCHING',
              err: {}
            }
          }
        })

        try {
          const rolesToFetch = [
            {
              method: 'getAvailableRoles',
              role: 'availables',
            },
            {
              method: 'getCompositeRoles',
              role: 'composites',
            },
            {
              method: 'getRoles',
              role: 'roles',
            }
          ]

          function* fetchRoles(roleToFetch, id, clientId) {
            const res = yield EAMApi.Users[roleToFetch.method](id, clientId)
            return {
              role: roleToFetch.role,
              data: res.data
            }
          }

          const responses = keyBy(
            yield all(rolesToFetch.map(roleToFetch =>
              call(fetchRoles, roleToFetch, action.payload.id, action.payload.clientId)
            )), 'role'
          )

          const availables = responses.availables.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: false,
            }
          })

          const composites = responses.composites.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: true,
            }
          })

          const roles = responses.roles.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: false,
            }
          })

          yield put({
            type: 'setUserRoles',
            id: action.payload.id,
            roles: keyBy(roles, 'id'),
            availables: keyBy(availables, 'id'),
            composites: keyBy(composites, 'id'),
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'FETCH_USER_ROLES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'FETCH_USER_ROLES',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    assignGroupRolesEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'ASSIGN_GROUP_ROLES',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'assignGroupRoles',
            id: action.payload.id,
            roles: action.payload.data,
          })

          yield EAMApi.Groups.assignRoles(action.payload.id, action.payload.clientId, action.payload.data)

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ASSIGN_GROUP_ROLES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch (err) {
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ASSIGN_GROUP_ROLES',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    assignUserRolesEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'ASSIGN_USER_ROLES',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'assignUserRoles',
            id: action.payload.id,
            roles: action.payload.data,
          })

          yield EAMApi.Users.assignRoles(action.payload.id, action.payload.clientId, action.payload.data)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ASSIGN_USER_ROLES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch (err) {
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ASSIGN_USER_ROLES',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removeGroupRolesEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_GROUP_ROLES',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'removeGroupRoles',
            id: action.payload.id,
            roles: action.payload.data,
          })

          yield EAMApi.Groups.removeRoles(action.payload.id, action.payload.clientId, action.payload.data)

          yield put({
            type: 'fetchGroupRolesEffect',
            payload: {
              id: action.payload.id,
              clientId: action.payload.clientId,
            }
          })

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_GROUP_ROLES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })
        } catch (err) {
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_GROUP_ROLES',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removeUserRolesEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_USER_ROLES',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'removeUserRoles',
            id: action.payload.id,
            roles: action.payload.data,
          })

          yield EAMApi.Users.removeRoles(action.payload.id, action.payload.clientId, action.payload.data)

          yield put({
            type: 'fetchUserRolesEffect',
            payload: {
              id: action.payload.id,
              clientId: action.payload.clientId,
            }
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_USER_ROLES',
                status: 'SUCCESS',
                err: {}
              }
            }
          })
        } catch (err) {
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_USER_ROLES',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    resetPasswordEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'RESET_PASSWORD',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield EAMApi.Users.resetPassword(action.payload.id, action.payload.data)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'RESET_PASSWORD',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'success',
              formatMessage({ id: 'users.password.passwordReset' })
            )
          }
        } catch(err) {
          console.log(err)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'RESET_PASSWORD',
                status: 'ERROR',
                err
              }
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    fetchUserRealmsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'FETCH_USER_REALMS',
              status: 'FETCHING',
              err: {}
            }
          }
        })

        try {
          const availableRealmsRes = yield EAMApi.Users.getAvailableRealms(action.payload.id)
          const compositeRealmsRes = yield EAMApi.Users.getCompositeRealms(action.payload.id)
          const realmRes = yield EAMApi.Users.getRealms(action.payload.id)

          const availableRealms = availableRealmsRes.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: false,
            }
          })

          const compositeRealms = compositeRealmsRes.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: true,
            }
          })

          const realms = realmRes.data.map(role => {
            return {
              ...role,
              key: role.id,
              title: role.name,
              disabled: false,
            }
          })

          yield put({
            type: 'setUserRealms',
            id: action.payload.id,
            realms: keyBy(realms, 'id'),
            availableRealms: keyBy(availableRealms, 'id'),
            compositeRealms: keyBy(compositeRealms, 'id'),
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'FETCH_USER_REALMS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'FETCH_USER_REALMS',
                status: 'ERROR',
                err: {}
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    removeUserRealmsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_USER_REALMS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'removeUserRealms',
            id: action.payload.id,
            roles: action.payload.data,
          })

          yield EAMApi.Users.removeRealms(action.payload.id, action.payload.data)

          yield put({
            type: 'fetchUserRealmsEffect',
            payload: {
              id: action.payload.id,
            }
          })

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_USER_REALMS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })
        } catch (err) {
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_USER_REALMS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    assignUserRealmsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'ASSIGN_USER_REALMS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          yield put({
            type: 'assignUserRealms',
            id: action.payload.id,
            roles: action.payload.data,
          })

          yield EAMApi.Users.assignRealms(action.payload.id, action.payload.data)

          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ASSIGN_USER_REALMS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch (err) {
          yield put({
            type: 'setUserStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ASSIGN_USER_REALMS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    deleteMultipleUsersEffect: [
      function* (action, { put, all, call }) {
        yield put({
          type: 'setGenericUserStatus',
          payload: {
            name: 'DELETE_MULTIPLE_USERS',
            status: 'START'
          }
        })

        try {
          const deleteUser = function* (currentUserId) {
            yield EAMApi.Users.delete(currentUserId)
            yield put({
              type: 'deleteUser',
              payload: {
                currentUserId,
              }
            })
          }

          yield all(action.payload.multipleUsersIds.map(id => call(deleteUser, id)))

          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'DELETE_MULTIPLE_USERS',
              status: 'SUCCESS'
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'success',
              formatMessage({ id: 'users.multiple.deleted' })
            )
          }

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'DELETE_MULTIPLE_USERS',
              status: 'ERROR',
              err
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    changeMultipleUsersStatusEffect: [
      function* (action, { put, all, call }) {
        yield put({
          type: 'setGenericUserStatus',
          payload: {
            name: 'CHANGE_MULTIPLE_USERS_STATUS',
            status: 'START'
          }
        })

        try {
          const changeUserStatus = function* (id, enabled) {
            yield EAMApi.Users.changeStatus(id, { enabled })
            yield put({
              type: 'changeUserStatus',
              id,
              enabled,
            })
          }

          yield all(action.payload.multipleUsers.map(user => call(changeUserStatus, user.id, user.enabled)))

          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'CHANGE_MULTIPLE_USERS_STATUS',
              status: 'SUCCESS'
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'success',
              formatMessage({ id: 'users.multiple.statusChanged' })
            )
          }

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericUserStatus',
            payload: {
              name: 'CHANGE_MULTIPLE_USERS_STATUS',
              status: 'ERROR',
              err
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    deleteMultipleGroupsEffect: [
      function* (action, { put, all, call }) {
        yield put({
          type: 'setGenericGroupStatus',
          payload: {
            name: 'DELETE_MULTIPLE_GROUPS',
            status: 'START'
          }
        })

        try {
          const deleteGroup = function* (currentGroupId) {
            yield EAMApi.Groups.delete(currentGroupId)
            yield put({
              type: 'deleteGroup',
              payload: {
                currentGroupId,
              }
            })
          }

          yield all(action.payload.multipleGroupsIds.map(id => call(deleteGroup, id)))

          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'DELETE_MULTIPLE_GROUPS',
              status: 'SUCCESS'
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'success',
              formatMessage({ id: 'groups.multiple.deleted' })
            )
          }

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'DELETE_MULTIPLE_GROUPS',
              status: 'ERROR',
              err
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    addMultipleUsersToGroupsEffect: [
      function* (action, { put, all, call }) {
        yield put({
          type: 'setGenericGroupStatus',
          payload: {
            name: 'ADD_MULTIPLE_USERS_TO_GROUPS',
            status: 'START'
          }
        })

        try {
          const addUsersToGroup = function* (currentGroupId, usersIds) {
            yield EAMApi.Groups.addUsers(currentGroupId, usersIds)
          }

          yield all(action.payload.multipleGroupsIds.map(id => call(addUsersToGroup, id, action.payload.usersIds)))

          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'ADD_MULTIPLE_USERS_TO_GROUPS',
              status: 'SUCCESS'
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'success',
              formatMessage({ id: 'users.multiple.addedToGroup' })
            )
          }

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGenericGroupStatus',
            payload: {
              name: 'ADD_MULTIPLE_USERS_TO_GROUPS',
              status: 'ERROR',
              err
            }
          })

          if (action.payload.callback) {
            action.payload.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    fetchGroupSupervisorsEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.id,
            actionType: {
              name: 'FETCH_GROUP_SUPERVISORS',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const response = yield EAMApi.Groups.getSupervisors(action.id)

          yield put({
            type: 'setCurrentGroup',
            current: {
              ...action.current,
              supervisors: keyBy(response.data, 'id'),
            },
            currentGroupId: action.id
          })

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'FETCH_GROUP_SUPERVISORS',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.id,
              actionType: {
                name: 'FETCH_GROUP_SUPERVISORS',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    addGroupSupervisorEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'ADD_SUPERVISOR_TO_GROUP',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const current = {
            ...action.payload.currentGroup,
            supervisors: action.payload.supervisors,
          }

          yield put({
            type: 'setCurrentGroup',
            current: current,
            currentGroupId: current.id
          })

          yield EAMApi.Groups.addSupervisor(action.payload.id, {
            user_id: action.payload.supervisorId
          })

          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ADD_SUPERVISOR_TO_GROUP',
                status: 'SUCCESS',
                err: {}
              }
            }
          })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'ADD_SUPERVISOR_TO_GROUP',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    removeGroupSupervisorEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_SUPERVISOR_GROUP',
              status: 'RUNNING',
              err: {}
            }
          }
        })

        try {
          const current = {
            ...action.payload.currentGroup,
            supervisors: values(action.payload.currentGroup.supervisors).filter(user => user.id !== action.payload.supervisorsIds[0]),
          }

          yield put({
            type: 'setCurrentGroup',
            current: current,
            currentGroupId: current.id
          })

          yield EAMApi.Groups.removeSupervisor(action.payload.id, action.payload.supervisorsIds[0])

          yield put({
          type: 'setGroupStatus',
          payload: {
            id: action.payload.id,
            actionType: {
              name: 'REMOVE_SUPERVISOR_GROUP',
              status: 'SUCCESS',
              err: {}
            }
          }
        })

        } catch(err) {
          console.log(err)
          yield put({
            type: 'setGroupStatus',
            payload: {
              id: action.payload.id,
              actionType: {
                name: 'REMOVE_SUPERVISOR_GROUP',
                status: 'ERROR',
                err
              }
            }
          })
        }
      },
      { type: 'takeEvery' }
    ],
    fetchSupervisedUsers: [
      function* (action, { put }) {
        yield put({
          type: 'setFetchingUsersStatus',
          payload: {
            status: 'FETCHING'
          }
        })

        try {
          const response = yield EAMApi.Me.getSupervisedUsers()

          yield put({
            type: 'setFetchingUsersStatus',
            payload: {
              status: 'SUCCESS'
            }
          })

          const data = map(response.data, obj => {
            obj.actionType = {
              name: '',
              status: '',
              err: {}
            }

            return obj
          })

          yield put({
            type: 'setUsers',
            payload: keyBy(data, 'id')
          })
        } catch(err) {
          yield put({
            type: 'setFetchingUsersStatus',
            payload: {
              status: 'ERROR'
            }
          })

          console.log(err)
        }
      },
      { type: 'takeLatest' },
    ],
    fetchSupervisedGroups: [
      function* (action, { put }) {
        yield put({
          type: 'setFetchingGroupsStatus',
          payload: {
            status: 'FETCHING'
          }
        })

        try {
          const response = yield EAMApi.Me.getSupervisedGroups()

          yield put({
            type: 'setFetchingGroupsStatus',
            payload: {
              status: 'SUCCESS'
            }
          })

          yield put({
            type: 'setGroups',
            payload: keyBy(response.data.subGroups, 'id')
          })
        } catch(err) {
          yield put({
            type: 'setFetchingGroupsStatus',
            payload: {
              status: 'ERROR'
            }
          })

          console.log(err)
        }
      },
      { type: 'takeLatest' },
    ],
  },
}
