import icon from '@wikicrm/backoffice/src/core/hoc/icon';
import React from 'react';

const Icon = props => (
  <svg
    {...props}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 1.31134e-06C23.2863 5.86931e-07 30 6.71371 30 15C30 23.2863 23.2863 30 15 30C6.71371 30 -5.86931e-07 23.2863 -1.31134e-06 15C-2.03575e-06 6.71371 6.71371 2.03575e-06 15 1.31134e-06ZM13.252 8.68549L17.8185 13.0645L6.77419 13.0645C5.96976 13.0645 5.32258 13.7117 5.32258 14.5161L5.32258 15.4839C5.32258 16.2883 5.96976 16.9355 6.77419 16.9355L17.8185 16.9355L13.252 21.3145C12.6653 21.877 12.6532 22.8145 13.2278 23.3891L13.8931 24.0484C14.4617 24.6169 15.381 24.6169 15.9435 24.0484L23.9698 16.0282C24.5383 15.4597 24.5383 14.5403 23.9698 13.9778L15.9435 5.95161C15.375 5.38307 14.4556 5.38307 13.8931 5.95161L13.2278 6.61089C12.6532 7.18549 12.6653 8.12299 13.252 8.68549Z"
      fill={props.fill || '#565656'}
    />
  </svg>
);

const WikiWebListIcon = icon(Icon);

export default WikiWebListIcon;
