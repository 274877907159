import WikiApi from '@wikicrm/api'
import { isUndefined } from 'lodash'
import * as actions from '../store/actions/actions'
import fetchWikiContent from './fetchWikiContent'

const fetchSurveysList = async ({
  didCancel,
  surveyDispatch,
  id,
  wikiDispatch,
  currentWiki,
}) => {
  try {
    !didCancel &&
      actions.setGenericSurveysActionType(
        {
          name: 'FETCH_SURVEYS',
          status: 'FETCHING',
        },
        surveyDispatch
      )

    if (isUndefined(currentWiki)) {
      await fetchWikiContent({
        didCancel,
        dispatch: wikiDispatch,
        currentWiki,
        id,
      })
    }

    const surveys = await WikiApi.Survey.getLive(id)
    !didCancel && actions.fetchSurveys(surveys, surveyDispatch)
    return surveys
  } catch (err) {
    console.log(err)
    !didCancel &&
      actions.setGenericSurveysActionType(
        {
          name: 'FETCH_SURVEYS',
          status: 'ERROR',
          err,
        },
        surveyDispatch
      )
  } finally {
    !didCancel &&
      actions.setGenericSurveysActionType(
        {
          name: 'FETCH_SURVEYS',
          status: 'SUCCESS',
        },
        surveyDispatch
      )
  }
}

export default fetchSurveysList
