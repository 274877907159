import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  height: 100%;
`

export const StyledButton = styled.button`
  cursor: pointer;
  font-weight: 700;
  background: white;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.greyLight};
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.greyDark};
  }
`
