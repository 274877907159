import WikiApi from '@wikicrm/api'
import { get, isNull, isNumber } from 'lodash'
import React, { useContext } from 'react'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import getSurveyResults from '../../../services/getSurveyResults'
import * as actions from '../../../store/actions/actions'
import ListItem from '../../shared/ListItem'
import SurveyCheckAnswers from '../SurveyCheckAnswers'
import SurveyInfo from '../SurveyInfo'
import SurveyQuestion from '../SurveyQuestion'
import { StyledForm } from '../SurveyQuestion/styled'
import SurveyQuestionButtons from '../SurveyQuestionButtons'
import SurveyResults from '../SurveyResults'

const ActiveSurvey = () => {
  const { state: surveysState, dispatch: surveyDispatch } =
    useContext(SurveysContext)

  const resetCurrentSurvey = () => actions.setCurrentSurvey('', surveyDispatch)

  const currentSurveyId = surveysState.currentSurveyId
  const currentSurvey = surveysState.data[currentSurveyId]

  const {
    id,
    currentQuestionIndex,
    title,
    start_date,
    end_date,
    description,
    questions,
    answers,
    participation,
    current_attempt,
    max_attempts,
    calculate_score,
    previous_attempts,
    checkAnswers,
    viewResults,
  } = currentSurvey

  const getSurveyDetail = () => {
    actions.setCurrentQuestionIndex(id, null, surveyDispatch)
  }

  const saveParticipation = async (id, data) => {
    try {
      actions.saveParticipation(id, data, surveyDispatch)
      await WikiApi.Survey.participate(id, {
        ...get(currentSurvey, 'current_attempt.data', {}),
        ...data,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onCheckAnswers = () => {
    actions.checkAnswers(id, !checkAnswers, surveyDispatch)
  }

  const onViewResults = () => {
    actions.viewResults(
      currentSurvey.id,
      !currentSurvey.viewResults,
      surveyDispatch
    )
  }

  const onGetResults = () =>
    getSurveyResults({ currentSurvey, dispatch: surveyDispatch })

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      background="white"
      zIndex={10}
      overflowY="auto"
    >
      {currentSurvey.viewResults && currentSurvey.previous_attempts && (
        <StyledForm className="results">
          <SurveyResults
            onViewResults={onViewResults}
            onGetResults={onGetResults}
            results={currentSurvey.results}
            questions={currentSurvey.questions}
            answers={currentSurvey.answers}
            prevAttempt={currentSurvey.previous_attempts[0]}
          />
        </StyledForm>
      )}

      {currentSurvey && checkAnswers && (
        <StyledForm className="check-answers">
          <SurveyCheckAnswers
            currentAttempt={current_attempt || previous_attempts[0]}
            questions={questions}
            answers={answers}
            onCheckAnswers={onCheckAnswers}
          />
        </StyledForm>
      )}

      {currentSurvey &&
        isNull(currentQuestionIndex) &&
        !currentSurvey.checkAnswers &&
        !currentSurvey.viewResults && (
          <Box padding={80}>
            <Box
              is="button"
              marginBottom={30}
              cursor="pointer"
              border="none"
              background="white"
              padding={0}
              onClick={resetCurrentSurvey}
            >
              <ListItem
                name={title}
                iconStyle={{ transform: 'rotate(180deg)' }}
              />
            </Box>
            <SurveyInfo
              currentSurveyId={id}
              startDate={start_date}
              endDate={end_date}
              description={description}
              questions={questions}
              answers={answers}
              currentQuestionIndex={currentQuestionIndex}
              participation={participation}
              currentAttempt={current_attempt}
              maxAttempts={max_attempts === 0 ? -1 : max_attempts}
              calculateScore={calculate_score}
              prevAttempts={previous_attempts}
              viewResults={viewResults}
              dispatch={surveyDispatch}
            />
          </Box>
        )}

      {currentSurvey &&
        isNumber(currentQuestionIndex) &&
        !currentSurvey.checkAnswers &&
        !currentSurvey.viewResults && (
          <Box display="flex" flexDirection="column" height="100%">
            <SurveyQuestionButtons
              currentQuestionIndex={currentQuestionIndex}
              participation={participation}
            />

            <Box flex="auto">
              <SurveyQuestion
                currentQuestionIndex={currentQuestionIndex}
                questions={questions}
                answers={answers}
                getSurveyDetail={getSurveyDetail}
                currentSurveyId={currentSurveyId}
                currentAttempt={current_attempt}
                saveParticipation={saveParticipation}
              />
            </Box>
          </Box>
        )}
    </Box>
  )
}

export default ActiveSurvey
