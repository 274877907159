import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'

const ActiveCertificate = ({ theme }) => {
  const { state: surveyState } = useContext(SurveysContext)

  const downloadCertificate = () =>
    window.open(surveyState.activeCertificate.url, '_blank')

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      background="white"
      zIndex={10}
      overflowY="auto"
      paddingX={80}
      paddingY={15}
      display="flex"
      flexDirection="column"
    >
      <Box>
        <Box
          is="button"
          display="inline-block"
          paddingX={20}
          paddingY={10}
          fontWeight="700"
          fontSize={16}
          marginBottom={12}
          backgroundColor={theme.greyLight}
          borderRadius={4}
          border="none"
          cursor="pointer"
          onClick={downloadCertificate}
        >
          <FormattedMessage id="download" />
        </Box>
      </Box>

      <Box
        is="iframe"
        src={surveyState.activeCertificate.url}
        title="Certificate"
        frameBorder="none"
        display="block"
        width="100%"
        height="100%"
      />
    </Box>
  )
}

export default withTheme(ActiveCertificate)
