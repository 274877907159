import styled from "styled-components";
import { Icon } from "antd";

export const WrapSidebar = styled.div`
  margin-top: 64px;
  width: 500px;
  right: 0;
  border-left: 1px solid ${({ theme }) => theme.colors.borders};
  height: calc(100vh - 64px);
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Chrome/Safari/Webkit */
  }
  
  > .ant-collapse {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  
  .ant-collapse {
    border: none;
    background-color: #fff;

    .ant-collapse-content {
      border-top: 1px solid ${({ theme }) => theme.colors.borders};
    }

    .ant-collapse-item:last-child {
      border-bottom: 0px;
    }
    .ant-collapse-header {
      display: flex;
      align-items: center;
      min-height: 69px;
      background: #fff;
      padding-left: 24px !important;
    }
    .ant-collapse-item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borders};

      .ant-collapse-content-active {
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent; /* Chrome/Safari/Webkit */
        }
        flex: 1;
      }
    }
    
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 3px 0px 0px 20px;
  
`;

export const StyledHeader = styled.div`
  display: flex;
  alignItems: center;
  width: 100%;
`

export const StyledIcon = styled(Icon)`
  font-size: 32px !important;
  color: ${({ theme }) => theme.colors.icons};
`

