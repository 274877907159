import styled from 'styled-components'
import {Button} from 'antd'
export const CircularButton = styled(Button)`
	&& {
		border-radius: 50% !important;
		width: 25px;
		height: 25px;
		min-height: 25px;
		padding: 0%;
		z-index: 1;
	}
`
