import { HeaderSection } from '@wikicrm/layout';
import { Icon } from 'antd';
import { debounce, isEmpty, values } from 'lodash';
import React, { useEffect, useState } from 'react';
import { formatMessage } from 'umi-plugin-locale';
import SvgArrowDown from '../assets/icons/ArrowDown';
import SvgEmpty from '../assets/icons/Empty';
import SvgLecture from '../assets/icons/Lecture';
import WikiWebListIcon from '../assets/icons/WikiWebListIcon';
import { WrapSearchBar } from '../components/styled';
import { Search } from '../containers/projects/styled';
import { GET_ALL_LECTURES } from '../models/projects';
import {
  EmptySection,
  Lecture,
  MainSection,
  NestedSection,
  StyledIcon,
  StyledTree,
} from './styled';

const SectionsTree = ({
  selectedKeys,
  expandedKeys,
  draggable,
  showHeader,
  sections,
  lectures,
  onSelect,
  onLoad,
  onDrop,
  onExpand,
  showIcon,
  showEmptyNodeForMainSection,
  showEmptyNodeForNestedSection,
  className,
  emptyNodeTitle,
  handleFilterItems,
  autoExpandParent,
  setAutoExpandParent,
  dispatch,
  context = 'backoffice',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [firstSearch, setFirstSearch] = useState(false);
  const isWikiWeb = context === 'wiki-web';

  useEffect(() => {
    if (!isEmpty(searchFilter.trim())) {
      const filteredLectures = values(lectures)
        .filter(lecture => lecture.name.toLowerCase().includes(searchFilter))
        .map(lecture => lecture.section);

      const filteredSections = values(sections)
        .filter(section => section.name.toLowerCase().includes(searchFilter))
        .map(section => section.parent);

      handleFilterItems(filteredLectures.concat(filteredSections));
    }
  }, [handleFilterItems, lectures, searchFilter, sections]);

  const showEmptyNode = condition => {
    if (condition) {
      return (
        <EmptySection
          className="empty-section"
          title={emptyNodeTitle}
          type="lecture"
          icon={<Icon component={SvgEmpty} />}
          isLeaf
        />
      );
    }
  };

  const CreateSectionsTree = data => {
    const { type, items } = data;

    switch (type) {
      case 'mainSections':
        return values(items)
          .filter(item => !item.parent)
          .map(section => {
            return (
              <MainSection
                className="main-section"
                key={section.id}
                title={section.name}
                type="section"
              >
                {CreateSectionsTree({
                  type: 'nestedSections',
                  items: values(sections).filter(nested => nested.parent === section.id),
                })}

                {CreateSectionsTree({
                  type: 'lectures',
                  items: values(lectures).filter(nested => nested.section === section.id),
                })}

                {showEmptyNode(showEmptyNodeForMainSection(section.id))}
              </MainSection>
            );
          });

      case 'nestedSections':
        return values(items)
          .filter(item => item.parent)
          .map(section => {
            return (
              <NestedSection
                className="nested-section"
                key={section.id}
                title={section.name}
                type="section"
              >
                {CreateSectionsTree({
                  type: 'nestedSections',
                  items: values(sections).filter(nested => nested.parent === section.id),
                })}

                {CreateSectionsTree({
                  type: 'lectures',
                  items: values(lectures).filter(nested => nested.section === section.id),
                })}

                {showEmptyNode(showEmptyNodeForNestedSection(section.id))}
              </NestedSection>
            );
          });

      case 'lectures':
        return values(items).map(lecture => {
          return (
            <Lecture
              className="lecture"
              key={lecture.id}
              title={lecture.name}
              type="lecture"
              icon={<Icon component={SvgLecture} />}
              isLeaf
            />
          );
        });

      default:
        return null;
    }
  };

  const handleFilter = e => {
    setFirstSearch(true);

    if (!firstSearch) {
      dispatch({
        type: GET_ALL_LECTURES,
        callback: debounceFilter(e.target.value.toLowerCase()),
      });
    } else {
      debounceFilter(e.target.value.toLowerCase());
    }
  };

  const debounceFilter = debounce(val => {
    setSearchFilter(val);
    if (!autoExpandParent) {
      setAutoExpandParent(true);
    }
  }, 500);

  return (
    <>
      {showHeader && (
        <HeaderSection padding="0 70px">
          <WrapSearchBar>
            <Icon type="search" />
            <Search
              background="#fff"
              placeholder={formatMessage({ id: 'projects.detail.searchPlaceholder' })}
              onChange={e => {
                setIsOpen(!isEmpty(e.target.value));
              }}
              onKeyUp={handleFilter}
              isOpen={isOpen}
            />
          </WrapSearchBar>
        </HeaderSection>
      )}

      <StyledTree
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        className={className ? `sections-tree ${className}` : 'sections-tree'}
        draggable={draggable}
        onSelect={onSelect}
        loadData={onLoad}
        onDrop={onDrop}
        onExpand={onExpand}
        showIcon={showIcon}
        switcherIcon={
          !isWikiWeb ? (
            <StyledIcon component={SvgArrowDown} />
          ) : (
            <WikiWebListIcon size={30} fill="#C4C4C4" />
          )
        }
        autoExpandParent={autoExpandParent}
      >
        {CreateSectionsTree({ type: 'mainSections', items: sections })}
      </StyledTree>
    </>
  );
};

export default SectionsTree;
