import WikiApi from '@wikicrm/api'
import { isUndefined } from 'lodash'
import * as actions from '../store/actions/actions'

const fetchWikiContent = async ({ didCancel, dispatch, currentWiki, id }) => {
  try {
    !didCancel &&
      actions.setGenericWikisActionType(
        {
          name: 'FETCH_WIKI_SECTIONS',
          status: 'FETCHING',
          err: {},
        },
        dispatch
      )

    if (isUndefined(currentWiki)) {
      const res = await WikiApi.Wikis.get(id)
      !didCancel && actions.setCurrentWiki(id, res, dispatch)
    } else {
      !didCancel && actions.setCurrentWiki(id, currentWiki, dispatch)
    }

    let sectionsRes = await WikiApi.Wikis.getAllSection(id)
    const lecturesRes = await WikiApi.Wikis.getAllLecture(id)
    actions.setCurrentLectures(id, lecturesRes, dispatch)

    !didCancel && actions.setCurrentSections(id, sectionsRes, dispatch)
  } catch (err) {
    console.log(err)
    !didCancel &&
      actions.setGenericWikisActionType(
        {
          name: 'FETCH_WIKI_SECTIONS',
          status: 'ERROR',
          err,
        },
        dispatch
      )
  } finally {
    !didCancel &&
      actions.setGenericWikisActionType(
        {
          name: 'FETCH_WIKI_SECTIONS',
          status: 'SUCCESS',
          err: {},
        },
        dispatch
      )
  }
}

export default fetchWikiContent
