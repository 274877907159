import { WikiApiClient } from './Api'
import { EamApiClient } from './EamApiClient'

const WikiApi = new WikiApiClient()

export const EAMApi = new EamApiClient()

export default WikiApi

export function setAccessTokenAuthorizationHeader(token) {
  if (typeof token !== 'string') {
    return new Error('You must provide a valid token')
  }

  WikiApi.client.defaults.headers.common.Authorization = `Bearer ${token}`
  EAMApi.client.defaults.headers.common.Authorization = `Bearer ${token}`

  return true
}

export function setCustomHeader(key, value) {
  WikiApi.client.defaults.headers[key] = value
  EAMApi.client.defaults.headers[key] = value
}

export function setResponseErrorHandler(errorHandler) {
  if (typeof errorHandler !== 'function'
  ) {
    return new Error("errorHandler must be a function")
  }
  WikiApi.client.interceptors.response.use(null, error => {
    errorHandler(error)
  })

  EAMApi.client.interceptors.response.use(null, error => {
    errorHandler(error)
  })
}