import styled from 'styled-components';
import { Button, Card } from 'antd';

export const WrapContent = styled.div`
    /* padding: 20px; */
    margin-top: 64px;
    height: calc(100vh - 64px);
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F2F5F7;
    /* -webkit-overflow-scrolling: touch; */
  `;

export const Content = styled.div`
  background: #f2f5f7;
  height: 100%;
  overflow: hidden;
`;

export const CardContainer = styled.ul`
  height: 100%;
  overflow: auto;
  margin: 0px -17px 0px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  padding: 0 70px;
`
export const CardItem = styled.li`
  margin: 0 30px 32px 0;
  width: 30%;

  &:nth-child(3n) {
    margin-right: 0;
  }

  @media(max-width: 1149px) {
    width: 45%;
    margin-right: 30px;

    &:nth-child(3n) {
      margin-right: 30px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`

export const Search = styled.input`
    border: none;
    background: ${({theme, background}) => background ? background : theme.colors.background};
    &::placeholder {
      color: ${({theme}) => theme.colors.secondaryAccent};
    }
    color: ${({theme}) => theme.colors.primaryText};
    font-size: 16px;
    width: ${({isOpen}) => (isOpen ? '50%' : '0px')};
    transition: all 0.3s ease-in-out;
    &:active,
    &:focus {
      outline: none;
    }
  `;

export const WrapButtons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  > button {
    width: 50%;
    margin-right: 5px;
  }
`;

export const StyledCard = styled(Card)`
  && {
    > ul.ant-card-actions {
      padding-right: 6px;
      > li {

        > span {
          > button {
            margin-left: 16px;
            height: 20px;
            font-family: Roboto;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
  }
`

