import WikiApi from '@wikicrm/api'
import * as actions from '../store/actions/actions'

const fetchCertificates = async ({ id, dispatch }) => {
  try {
    const res = await WikiApi.Survey.getCertificates(id)
    actions.setSurveyCertificates(id, res.data, dispatch)
  } catch (err) {
    console.log(err)
  }
}

export default fetchCertificates
