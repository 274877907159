import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { GlobalContext } from '../../../context/GlobalContext'
import { withKeycloakSessionContext } from '../../../context/KeycloakSessionContext'
import { WikisContext } from '../../../context/WikisContext'
import fetchWikiContent from '../../../services/fetchWikiContent'
import * as actions from '../../../store/actions/actions'
import ListItem from '../../shared/ListItem'
import WikiContentLecture from '../WikiContentLecture/WikiContentLecture'
import WikiContentTree from '../WikiContentTree'

const WikiContent = ({ theme }) => {
  const { id } = useParams()
  const { state, dispatch } = useContext(WikisContext)
  const { dispatch: globalDispatch } = useContext(GlobalContext)

  const currentWiki = state.data[id]

  useEffect(() => {
    let didCancel = false
    fetchWikiContent({ didCancel, dispatch, currentWiki, id })

    return () => {
      actions.setActiveItem('', globalDispatch)
      didCancel = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    actions.resetCurrentFile(id, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentWiki || !currentWiki.alreadyFetched) {
    return <StyledSpin size="large" data-bg-color={theme.textColor} />
  }

  if (isEmpty(currentWiki.allSections.data)) {
    return (
      <Box is="p" paddingTop="15px" color={theme.textColor} marginLeft="20px">
        <FormattedMessage id="wiki.empty" />
      </Box>
    )
  }

  return (
    <>
      {state.currentSectionId && state.currentLectureId && (
        <WikiContentLecture />
      )}

      <Box paddingY={80}>
        <Box paddingX={80} marginBottom={30}>
          <Link to="/">
            <ListItem
              name={currentWiki.name}
              iconStyle={{ transform: 'rotate(180deg)' }}
            />
          </Link>
        </Box>

        <WikiContentTree currentWiki={currentWiki} />
      </Box>
    </>
  )
}

export default withKeycloakSessionContext(withTheme(WikiContent))
