import { get, isNull } from 'lodash'

class AppConfig {
  constructor() {
    this._config = {}
  }

  set(config) {
    return this._config = config
  }

  get(key = null, defaultValue = undefined) {
    if (isNull(key)) {
      return this._config
    }

    return get(this._config, key, defaultValue)
  }
}

const Config = new AppConfig()

export default Config
