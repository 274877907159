import { BaseObject } from '../BaseObject'

class EventsApi extends BaseObject {
  constructor(client, options) {
    super(client, options)
  }

  send(data = {}) {
    return this.client.post(`api/${this.versioning}/events/`, data)
  }
}

export default EventsApi
