import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { values } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { WikisContext } from '../../../context/WikisContext'
import fetchWikiList from '../../../services/fetchWikiList'
import ListItem from '../../shared/ListItem'
import { StyledLink } from './styled'

const WikiList = ({ theme }) => {
  const { state, dispatch } = useContext(WikisContext)

  useEffect(() => {
    let didCancel = false

    fetchWikiList({ didCancel, dispatch })

    return () => {
      didCancel = true
    }
  }, [dispatch])

  const renderContent = () => {
    return !state.alreadyFetched ? (
      <StyledSpin size="large" data-bg-color={theme.textColor} />
    ) : (
      <Box listStyle="none" padding="0" margin="0">
        {values(state.data).map((wiki) => (
          <StyledLink key={wiki.id} to={`/wiki/${wiki.unique_id}`}>
            <ListItem name={wiki.name} />
          </StyledLink>
        ))}
      </Box>
    )
  }

  return <Box padding={80}>{renderContent()}</Box>
}

export default withTheme(WikiList)
