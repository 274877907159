import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { get, isNull, values } from 'lodash'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { GlobalContext } from '../../../context/GlobalContext'
import { SurveysContext } from '../../../context/SurveysContext'
import { WikisContext } from '../../../context/WikisContext'
import fetchSurveysList from '../../../services/fetchSurveysList'
import * as actions from '../../../store/actions/actions'
import { getSurveyIconColor } from '../../../utils/getSurveyIconColor'
import ListItem from '../../shared/ListItem'
import ActiveSurvey from '../ActiveSurvey'
import SurveyPassed from '../SurveyPassed'

const SurveysList = ({ theme }) => {
  const { id } = useParams()
  const { dispatch: globalDispatch } = useContext(GlobalContext)
  const { state: wikiState, dispatch: wikiDispatch } = useContext(WikisContext)
  const { state: surveyState, dispatch: surveyDispatch } =
    useContext(SurveysContext)
  const currentSurvey = get(
    surveyState.data,
    `[${surveyState.currentSurveyId}]`,
    ''
  )

  const currentWiki = wikiState.data[id]

  useEffect(() => {
    let didCancel = false

    fetchSurveysList({
      didCancel,
      surveyDispatch,
      id,
      wikiDispatch,
      currentWiki,
    })

    return () => {
      actions.setActiveItem('', globalDispatch)
      didCancel = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickSurvey = (id) => actions.setCurrentSurvey(id, surveyDispatch)

  if (
    surveyState.genericSurveysActionType.name === 'FETCH_SURVEYS' &&
    surveyState.genericSurveysActionType.status === 'SUCCESS' &&
    !values(surveyState.data).filter((survey) => survey.wiki === id).length
  ) {
    return (
      <Box is="p" paddingTop="15px" color={theme.textColor} marginLeft="20px">
        <FormattedMessage id="surveys.noContent" />
      </Box>
    )
  }

  if (
    !surveyState.alreadyFetched ||
    !values(surveyState.data).filter((survey) => survey.wiki === id).length
  ) {
    return <StyledSpin size="large" data-bg-color={theme.textColor} />
  }

  return (
    <>
      {!isNull(currentSurvey.participation) &&
        get(currentSurvey, 'participation.is_passed', false) && (
          <SurveyPassed
            participation={currentSurvey.participation}
            calculate_score={currentSurvey.calculate_score}
          />
        )}

      {surveyState.currentSurveyId && <ActiveSurvey />}

      <Box padding={80}>
        <Box marginBottom={30}>
          <Link to={`/wiki/${id}`}>
            <ListItem
              name={currentWiki.name}
              iconStyle={{ transform: 'rotate(180deg)' }}
            />
          </Link>
        </Box>

        {values(surveyState.data)
          .filter((survey) => survey.wiki === id)
          .map((survey) => (
            <Box
              is="button"
              display="block"
              border="none"
              background="white"
              key={survey.id}
              marginBottom={30}
              paddingX={30}
              cursor="pointer"
              onClick={() => onClickSurvey(survey.id)}
            >
              <ListItem
                fontSize={28}
                name={survey.title}
                fontWeight={600}
                fill={getSurveyIconColor(survey, theme)}
              />
            </Box>
          ))}
      </Box>
    </>
  )
}

export default withTheme(SurveysList)
