import { BaseObject } from '../BaseObject'

class OrganizationApi extends BaseObject {
  constructor(client) {
    super(client)
  }

  my() {
    return this.client.get(`api/v1/organization/`)
  }

  clients() {
    return this.client.get(`api/v1/clients/`)
  }
}

export default OrganizationApi
