import { Breadcrumb, Typography } from 'antd'
import { get, isUndefined } from 'lodash'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Box from 'ui-box'
import { WikisContext } from '../../../context/WikisContext'
import * as actions from '../../../store/actions/actions'
import { StyledBreadcrumb } from '../../shared/Breadcrumbs/styled'

const BreadcrumbsWiki = () => {
  const { state, dispatch } = useContext(WikisContext)
  const id = state.currentWikiId
  const wikiName = get(state, `data[${id}].name`, '')
  const currentWiki = state.data[id]
  const currentSection =
    !isUndefined(currentWiki) &&
    currentWiki.allSections.data[state.currentSectionId]
  const currentLecture =
    !isUndefined(currentWiki) &&
    currentWiki.allLectures.data[state.currentLectureId]

  const resetFile = () => actions.resetCurrentFile(id, dispatch)

  return (
    <StyledBreadcrumb>
      <Breadcrumb.Item>
        <Link to="/">
          <Typography.Paragraph>Wiki</Typography.Paragraph>
        </Link>
      </Breadcrumb.Item>

      {wikiName && (
        <Breadcrumb.Item onClick={resetFile}>
          <Box is={Typography.Paragraph} ellipsis cursor="pointer">
            {wikiName}
          </Box>
        </Breadcrumb.Item>
      )}

      {currentSection && (
        <Breadcrumb.Item onClick={resetFile}>
          <Box is={Typography.Paragraph} ellipsis cursor="pointer">
            {currentSection.name}
          </Box>
        </Breadcrumb.Item>
      )}

      {currentLecture && (
        <Breadcrumb.Item>
          <Typography.Paragraph ellipsis>
            {currentLecture.name}
          </Typography.Paragraph>
        </Breadcrumb.Item>
      )}
    </StyledBreadcrumb>
  )
}

export default BreadcrumbsWiki
