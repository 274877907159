import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'
import { withTheme } from 'styled-components'
import { WikisContext } from '../../../context/WikisContext'
import * as actions from '../../../store/actions/actions'

const SearchFooterButtons = ({ theme }) => {
  const { state, dispatch } = useContext(WikisContext)
  const id = state.currentWikiId
  const resetFile = () => actions.resetCurrentFile(id, dispatch)

  return (
    <Box display="flex" justifyContent="end" width="100%">
      <Box
        is="button"
        border="none"
        background="white"
        cursor="pointer"
        color={theme.greyDark}
        textTransform="uppercase"
        fontWeight={700}
        padding={0}
        onClick={resetFile}
      >
        <FormattedMessage id="close" />
      </Box>
    </Box>
  )
}

export default withTheme(SearchFooterButtons)