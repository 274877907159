export const locales = [
  {
    id: 'it-IT',
    label: 'ITA'
  },
  {
    id: 'en-US',
    label: 'ENG'
  },
]

export const defaultLocale = 'en-US'
