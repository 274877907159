import { isNull } from 'lodash'

export const getSurveyIconColor = (survey, theme) => {
  const maxAttempts = survey.max_attempts === 0 ? -1 : survey.max_attempts

  if (!survey.calculate_score) {
    if (!isNull(survey.participation) && survey.participation.is_passed) {
      return theme.green
    }

    if (!isNull(survey.participation) && !survey.participation.is_passed) {
      return theme.yellow
    }
  }

  if (survey.calculate_score) {
    if (
      !isNull(survey.participation) &&
      !survey.participation.is_passed &&
      survey.participation.attempt_number === maxAttempts
    ) {
      return theme.red
    }

    if (
      !isNull(survey.previous_attempts) &&
      survey.previous_attempts.length === 0
    ) {
      return theme.greyLight
    }

    if (!isNull(survey.participation) && !survey.participation.is_passed) {
      return theme.yellow
    }

    if (!isNull(survey.participation) && survey.participation.is_passed) {
      return theme.green
    }
  }

  return theme.greyLight
}
