import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { Checkbox, Radio } from 'antd'
import { get, isNull, isUndefined } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { StyledQuestion } from './styled'

const SurveyResults = ({
  onGetResults,
  results,
  questions,
  answers,
  prevAttempt,
  theme,
}) => {
  const [correctAnswers, setCorrectAnswers] = useState(null)

  useEffect(() => {
    onGetResults()
  }, [onGetResults])

  useEffect(() => {
    if (!isUndefined(results)) {
      setCorrectAnswers(results.user_correct_answers.map((answer) => answer.id))
    }
  }, [results])

  const renderAnswers = (question) => {
    const questionAnswers = answers.filter(
      (answer) => answer.question === question.id
    )

    const getAnswerResultClassName = (answerId) => {
      if (!isNull(correctAnswers)) {
        const givenAnswer = get(prevAttempt, `data[${question.id}]`, [])

        if (givenAnswer.indexOf(answerId) !== -1) {
          if (correctAnswers.indexOf(answerId) !== -1) {
            return 'correct'
            // return <SvgCheck style={{ color: '#7ED321', marginLeft: 10 }} />
          } else {
            return 'wrong'
            // return <SvgCross style={{ color: '#DF6D11', marginLeft: 10 }} />
          }
        }

        return ''
      }

      return ''
    }

    switch (question.type) {
      case 1:
        return questionAnswers.map((answer) => {
          return (
            <Radio
              key={answer.id}
              checked={
                get(prevAttempt, `data[${question.id}][0]`, []).indexOf(
                  answer.id
                ) !== -1
              }
              disabled
              className={getAnswerResultClassName(answer.id)}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                fontSize={22}
              >
                {answer.text}
              </Box>
            </Radio>
          )
        })

      case 2:
        return questionAnswers.map((answer) => {
          return (
            <Checkbox
              key={answer.id}
              checked={
                get(prevAttempt, `data[${question.id}]`, []).indexOf(
                  answer.id
                ) !== -1
              }
              disabled
              className={getAnswerResultClassName(answer.id)}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                fontSize={22}
              >
                {answer.text}
              </Box>
            </Checkbox>
          )
        })

      default:
        return null
    }
  }

  return (
    <Box
      width="100%"
      maxWidth="100%"
      paddingX={80}
      paddingBottom={80}
      paddingTop={15}
    >
      {!isUndefined(results) && (
        <Box
          display="inline-block"
          paddingX={20}
          paddingY={10}
          fontWeight="700"
          fontSize={16}
          marginBottom={80}
          backgroundColor={theme.greyLight}
          borderRadius={4}
        >
          <FormattedMessage id="surveys.score" />: {Math.round(results.score)}%
        </Box>
      )}

      {isUndefined(results) ? (
        <StyledSpin size="large" data-bg-color={theme.textColor} />
      ) : (
        <div>
          {questions.map((question, i) => {
            return (
              <StyledQuestion key={question.id}>
                <Box fontSize={24}>
                  <Box is="span" fontWeight={300} marginRight={10}>
                    {i + 1}
                  </Box>{' '}
                  <Box is="span" fontWeight={600}>
                    {question.text}
                  </Box>
                </Box>

                {renderAnswers(question)}
              </StyledQuestion>
            )
          })}
        </div>
      )}
    </Box>
  )
}

export default withTheme(SurveyResults)
