import React, { createContext, useReducer } from 'react'
import * as actionTypes from '../store/actions/actionTypes'
import * as reducers from '../store/reducers/reducers'

const initialState = {
  data: {},
  currentWikiId: '',
  alreadyFetched: false,
  genericWikiActionType: {
    name: null,
    status: null,
    err: {},
  },
}

export const WikisContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_GENERIC_WIKIS_ACTION_TYPE:
      return reducers.setGenerictWikisActionType(state, action.actionType)

    case actionTypes.FETCH_WIKIS:
      return reducers.fetchWikis(state, action.wikis)

    case actionTypes.SET_CURRENT_WIKI:
      return reducers.setCurrentWiki(
        state,
        action.currentWikiId,
        action.current
      )

    case actionTypes.SET_CURRENT_SECTIONS:
      return reducers.setCurrentSections(
        state,
        action.currentWikiId,
        action.sections
      )

    case actionTypes.SET_CURRENT_LECTURES:
      return reducers.setCurrentLectures(
        state,
        action.currentWikiId,
        action.lectures
      )

    case actionTypes.SET_CURRENT_FILE:
      return reducers.setCurrentFile(
        state,
        action.currentWikiId,
        action.currentLectureId,
        action.contentUrl,
        action.url,
        action.previewUrl
      )

    case actionTypes.SET_SECTION_STATUS:
      return reducers.setSectionStatus(
        state,
        action.currentWikiId,
        action.currentSectionId,
        action.actionType
      )

    case actionTypes.RESET_CURRENT_FILE:
      return reducers.resetCurrentFile(state, action.currentWikiId)

    case actionTypes.SET_CURRENT_LECTURE:
      return reducers.setCurrentLecture(
        state,
        action.currentLectureId,
        action.currentSectionId
      )

    default:
      return state
  }
}

export const WikisContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <WikisContext.Provider value={{ state, dispatch }}>
      {children}
    </WikisContext.Provider>
  )
}
