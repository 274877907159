import { BaseObject } from '../BaseObject'

class MeApi extends BaseObject {
  constructor(client) {
    super(client)
  }

  changePassword(data) {
    return this.client.post('api/v1/me/change-password/', data)
  }

  getSupervisedUsers() {
    return this.client.get('api/v1/me/supervised-users/')
  }

  getSupervisedGroups() {
    console.log('FETCH')
    return this.client.get('api/v1/me/supervised-groups/')
  }
}

export default MeApi
