import createConsumerContext from '@wikicrm/core/utils/createConsumerContext'
import PropTypes from 'prop-types'
import React, { Component, createContext } from 'react'
import Keycloak from 'keycloak-js'
import { isUndefined } from 'lodash'
import WikiApi, { EAMApi } from '@wikicrm/api'
import { SET_USER_PRIVILEGES } from '../../models/global'
import { StyledSpin } from '../Spin'

const { Consumer, Provider } = createContext()

class KeycloakSessionContext extends Component {
  state = {
    isSessionLoading: true,
    isLoggedIn: false,
    kc: null,
  }

  initKeycloak = () => {
    return new Promise((resolve, reject) => {
      const { keycloakConfig } = this.props

      const kc = Keycloak(keycloakConfig)

      kc.onAuthSuccess = this.handleAuthSuccess
      kc.onAuthRefreshSuccess = this.handleAuthRefreshSuccess
      kc.onAuthRefreshError = this.handleAuthRefreshError
      kc.onTokenExpired = this.handleTokenExpired

      this.setState({
        kc: kc,
      }, () => {
        resolve()
      })
    })
  }

  handleAuthSuccess = async () => {
    const { kc } = this.state
    const { onLoadUserProfile, dispatch } = this.props

    // TODO: load app data

    WikiApi.client.interceptors.request.use((config) => {
      const { kc } = this.state

      if (kc.isTokenExpired(10)) {
        kc.updateToken(11)
          .success(() => {
            config.headers.Authorization = `Bearer ${kc.token}`


          })
          .error(() => {
            return kc.logout()
          })
      } else {
        config.headers.Authorization = `Bearer ${kc.token}`

        return config
      }
    }, error => {
      return Promise.reject(error)
    })

    EAMApi.client.interceptors.request.use((config) => {
      const { kc } = this.state

      if (kc.isTokenExpired(10)) {
        kc.updateToken(11)
          .success(() => {
            config.headers.Authorization = `Bearer ${kc.token}`
          })
          .error(() => {
            return kc.logout()
          })
      } else {
        config.headers.Authorization = `Bearer ${kc.token}`

        return config
      }
    }, error => {
      return Promise.reject(error)
    })


    kc
      .loadUserInfo()
      .success(userInfo => {

        if (!isUndefined(onLoadUserProfile)) {
          onLoadUserProfile(userInfo, kc)
        }

        dispatch({
          type: SET_USER_PRIVILEGES,
          payload: kc.resourceAccess,
        })

        return this.setState({
          isSessionLoading: false,
          isLoggedIn: true,
        })
      })
      .error(() => {
        return this.setState({
          isSessionLoading: false,
          error: {
            message: 'Error loading user profile.',
          },
          isError: true,
        })
      })


  }


  handleAuthRefreshSuccess = () => {

  }

  handleAuthRefreshError = () => {

  }

  handleTokenExpired = () => {

  }

  init = async () => {
    const { kc } = this.state
    const { initOptions } = this.props

    try {
      kc
        .init({
          onLoad: 'login-required',
          ...initOptions,
        })
    } catch (e) {
      this.setState(() => {
        throw e
      })
    }
  }

  logout = () => {
    const { kc } = this.state

    return kc.logout()
  }

  componentDidMount = async () => {
    await this.initKeycloak()
    this.init()
  }

  render() {
    const {
      children,
      dispatch,
    } = this.props

    const {
      isSessionLoading,
      kc
    } = this.state

    if (isSessionLoading) {
      return <StyledSpin size="large" />
    }

    return (
      <Provider value={{
        kc,
        session: {
          logout: this.logout,
        },
      }}>
        {children}
      </Provider>
    )
  }
}

KeycloakSessionContext.propTypes = {
  keycloakConfig: PropTypes.object.isRequired,
  initOptions: PropTypes.object.isRequired,
  onLoadUserProfile: PropTypes.func,
}

export const withKeycloakSessionContext = createConsumerContext(Consumer)()

export default KeycloakSessionContext
