import React from 'react'
import { FormattedMessage } from 'react-intl'
import Box from 'ui-box'
import { isEmpty } from 'lodash'
import FilePdf from './FilePdf'
import FileImage from './FileImage'

const FileDocument = ({ previewExtension, previewUrl, downloadable, scale }) => {
  // no preview no download
  if (isEmpty(previewUrl) && !downloadable) {
    return (
      <Box
        is="p" 
        textAlign="center"
        margin="0px"
      >
        <FormattedMessage id="wiki.noPreviewNoDownload" />
      </Box>
    )
  }

  // si preview no download
  if (!isEmpty(previewUrl) && !downloadable) {
    switch(previewExtension) {
      case 'pdf':
        return <FilePdf src={previewUrl} key={previewUrl} scale={scale} />
      case 'png':
      case 'jpg':
      case 'jpeg':
      default:
        return (
          <FileImage 
            src={previewUrl} 
            key={previewUrl}
            scale={scale}
          />
        )
    }
  }

  // no preview e si download
  if (isEmpty(previewUrl) && downloadable) {
    return <FormattedMessage id="wiki.previewNotAvailable" />
  }

  // si preview e si download
  if (!isEmpty(previewUrl) && downloadable) {
    switch(previewExtension) {
      case 'pdf':
        return <FilePdf src={previewUrl} key={previewUrl} scale={scale} />
      case 'png':
      case 'jpg':
      case 'jpeg':
      default:
        return (
          <FileImage 
            src={previewUrl} 
            key={previewUrl}
            scale={scale}
          />
        )
    }
  }

  return <FormattedMessage id="wiki.noData" />
}

export default FileDocument
