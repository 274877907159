import Api from '@wikicrm/api'
import { StyledSpin } from '@wikicrm/backoffice/src/components/Spin'
import { get, isEmpty, isUndefined } from 'lodash'
import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import moment from 'moment'
import { GlobalContext } from '../../../context/GlobalContext'
import FileDocument from './FileDocument'
import FileIframe from './FileIframe'
import FileImage from './FileImage'
import FilePdf from './FilePdf'
import FileVideo from './FileVideo'
import { extractLectureData } from '../../../utils/tracking'
import { LECTURE_CLOSE, LECTURE_OPEN } from '../../../constants'

const FileViewer = ({ currentLecture, loading, scale, theme }) => {
  const { state } = useContext(GlobalContext)

  useEffect(() => {
    const openingTime = Date.now()

    const trackOpeningLecture = async () => {
      const trackedEvent = {
        type: LECTURE_OPEN,
        data: {
          ...extractLectureData(currentLecture),
          time: moment().format(),
        },
      }

      await Api.Events.send(trackedEvent)
    }

    trackOpeningLecture()

    return () => {
      const trackClosingLecture = async () => {
        const trackedEvent = {
          type: LECTURE_CLOSE,
          data: {
            ...extractLectureData(currentLecture),
            duration: Date.now() - openingTime,
            time: moment().format(),
          },
        }
  
        await Api.Events.send(trackedEvent)
      }

      trackClosingLecture()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLecture.id])

  useEffect(() => {
    const lectureName = get(currentLecture, 'name', 'undefined')
    
    // TO DO: FIX OR REMOVE
    if (get(state, 'organization.client_id', null) === 'sanofi') {
      ReactGA.event('view_lecture', {
        category: 'Lecture Screen',
        action: 'View Lecture',
        label: lectureName
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLecture.id])

  const renderFile = () => {
    if (loading) {
      return <StyledSpin size="large" data-bg-color={theme.textColor} />
    }

    if (isUndefined(currentLecture)) {
      return null
    }

    switch (currentLecture.format) {
      case 'html_package':
        return (
          <FileIframe
            currentLecture={currentLecture}
            key={currentLecture.contentUrl}
          />
        )

      case 'video':
        return <FileVideo src={currentLecture.url} key={currentLecture.url} />

      case 'image':
        return (
          <FileImage
            src={currentLecture.url}
            scale={scale}
            key={currentLecture.url}
          />
        )

      case 'pdf':
        return (
          <FilePdf
            src={currentLecture.url}
            scale={scale}
            key={currentLecture.url}
          />
        )

      case 'document':
      case 'presentation':
        return (
          <FileDocument
            key={currentLecture.url}
            previewExtension={currentLecture.preview_file_extension}
            previewUrl={currentLecture.previewUrl}
            downloadable={currentLecture.downloadable}
            scale={scale}
          />
        )

      default:
        return (
          <Box is="p" textAlign="center">
            <FormattedMessage id="wiki.noData" />
          </Box>
        )
    }
  }

  const getOverflowY = () => {
    if (isUndefined(currentLecture)) {
      return null
    }

    switch (currentLecture.format) {
      case 'presentation':
      case 'document':
      case 'image':
      case 'pdf':
        return 'auto'

      case 'html_package':
      case 'video':
      default:
        return 'hidden'
    }
  }

  const getFlex = () => {
    if (isUndefined(currentLecture)) {
      return {
        alignItems: null,
        justifyContent: null,
      }
    }

    if (loading) {
      return {
        alignItems: null,
        justifyContent: null,
      }
    }

    switch (currentLecture.format) {
      case 'image':
        return {
          alignItems: 'center',
          justifyContent: 'flex-start',
        }

      case 'pdf':
        return {
          alignItems: 'center',
          justifyContent: 'flex-start',
        }

      case 'document':
      case 'presentation':
        if (
          isEmpty(currentLecture.previewUrl) &&
          !currentLecture.downloadable
        ) {
          return {
            alignItems: 'center',
            justifyContent: 'center',
          }
        }

        if (
          !isEmpty(currentLecture.previewUrl) &&
          !currentLecture.downloadable
        ) {
          return {
            alignItems: 'center',
            justifyContent: 'flex-start',
          }
        }

        if (isEmpty(currentLecture.previewUrl) && currentLecture.downloadable) {
          return {
            alignItems: 'center',
            justifyContent: 'center',
          }
        }

        if (
          !isEmpty(currentLecture.previewUrl) &&
          currentLecture.downloadable
        ) {
          return {
            alignItems: 'center',
            justifyContent: 'flex-start',
          }
        }

        return {
          alignItems: null,
          justifyContent: null,
        }

      case 'html_package':
        return {
          alignItems: 'center',
          justifyContent: 'center',
        }

      case 'video':
        return {
          alignItems: null,
          justifyContent: null,
        }

      default:
        return {
          alignItems: 'center',
          justifyContent: 'center',
        }
    }
  }

  // <Button
  //         paddingX="50px"
  //         paddingY="10px"
  //         is="a"
  //         content={<FormattedMessage id="download" />}
  //         href={url}
  //         target="_blank"
  //       />

  return (
    <Box
      flex="auto"
      width="100%"
      height="100%"
      maxWidth="100%"
      paddingX="20px"
      paddingY="20px"
      display="flex"
      flexDirection="column"
      alignItems={getFlex().alignItems}
      justifyContent={getFlex().justifyContent}
      overflowY={getOverflowY()}
    >
      {
        currentLecture.downloadable && 
        <Box alignSelf="flex-start">
          <Box
            is="a"
            href={currentLecture.url}
            target="_blank"
            display="inline-block"
            paddingX={20}
            paddingY={10}
            fontWeight="700"
            fontSize={16}
            marginBottom={12}
            backgroundColor={theme.greyLight}
            borderRadius={4}
            border="none"
            cursor="pointer"
            color={theme.greyDark}
          >
            <FormattedMessage id="download" />
          </Box>
        </Box>
      }

      {renderFile()}
    </Box>
  )
}

export default withTheme(FileViewer)
