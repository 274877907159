import styled from "styled-components";

export const WrapField = styled.div`
  margin-bottom: 0px;
  > div {
    margin-bottom: 0px;
  }
`;

export const WrapContentSidebar = styled.div`
  padding: 7px;
  position: relative;
`;
