function extractLectureData(lecture) {
  return {
    wiki: {
      id: lecture.wiki,
      unique_id: lecture.wiki_unique_id,
    },
    lecture: {
      id: lecture.id,
      unique_id: lecture.unique_id,
      format: lecture.format,
    },
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  extractLectureData,
}
