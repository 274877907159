import { setLocale, getLocale, formatMessage } from 'umi-plugin-locale'
import { keyBy, isEmpty, isUndefined } from 'lodash'
import store from 'store'
import { EAMApi } from '@wikicrm/api'
import fromCamelToSnake from '../core/utils/fromCamelToSnake'
import { FETCH_USER_ROLES, FETCH_GROUP_ROLES, FETCH_USER_REALMS } from './users'

export const GLOBAL_NAMESPACE = 'global'
export const FETCH_CLIENTS = `${GLOBAL_NAMESPACE}/fetchClients`
export const SET_CURRENT_CLIENT = `${GLOBAL_NAMESPACE}/setCurrentClient`
export const SET_USER_PRIVILEGES = `${GLOBAL_NAMESPACE}/setUserPrivileges`
export const CHANGE_PASSWORD = `${GLOBAL_NAMESPACE}/changePasswordEffect`
export const PATCH_PROFILE = `${GLOBAL_NAMESPACE}/patchProfileEffect`
export const SET_LOCALE = `${GLOBAL_NAMESPACE}/setLocaleEffect`

const initState = {
  bootstrapping: true,
  locale: null,
  organization: {
    status: 'NONE',
    data: {}
  },
  user: {
    actionType: {
      name: '',
      status: '',
      err: {}
    }
  },
  clients: {
    data: {},
    currentClientId: '',
    actionType: {
      name: '',
      status: '',
      err: {}
    }
  },
}

export default {
  namespace: 'global',
  state: initState,
  reducers: {
    setBootstrap(state, action) {
      return {
        ...state,
        bootstrapping: action.payload,
      }
    },
    setLocale(state, action) {
      return {
        ...state,
        locale: action.payload,
      }
    },
    setUserProfile(state, { userInfo }) {
      return {
        ...state,
        user: {
          ...state.user,
          ...userInfo,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
        },
      }
    },
    setUserPrivileges(state, action) {
      return {
        ...state,
        user: {
          ...state.user,
          privileges: action.payload
        }
      }
    },
    setOrganizationStatus(state, action) {
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload,
        }
      }
    },
    setOrganizationData(state, action) {
      return {
        ...state,
        organization: {
          ...state.organization,
          data: action.payload
        }
      }
    },
    setClientsStatus(state, action) {
      return {
        ...state,
        clients: {
          ...state.clients,
          actionType: action.actionType
        }
      }
    },
    setClients(state, action) {
      return {
        ...state,
        clients: {
          ...state.clients,
          data: {
            ...state.clients.data,
            ...action.clients
          },
          currentClientId: state.clients.currentClientId
        }
      }
    },
    setCurrentClient(state, { currentClientId }) {
      return {
        ...state,
        clients: {
          ...state.clients,
          data: {
            ...state.clients.data
          },
          currentClientId,
        }
      }
    },
    setUserProfileStatus(state, { actionType }) {
      return {
        ...state,
        user: {
          ...state.user,
          actionType
        }
      }
    },
    patchProfile(state, { payload: { email, firstName, lastName } }) {
      return {
        ...state,
        user: {
          ...state.user,
          email,
          firstName,
          lastName,
          name: `${firstName} ${lastName}`,
          preferred_username: email,
          given_name: firstName,
          family_name: lastName,
        }
      }
    }
  },
  effects: {
    bootstrap: [
      function* (action, { put }) {
        yield put({
          type: 'fetchOrganization'
        })

        yield put({
          type: 'setUserProfile',
          userInfo: action.payload.userInfo,
        })

        try {
          yield put({
            type: 'setBootstrap',
            payload: false
          })

          const storeLocale = store.get('wiki-locale')
          const defaultLocale = 'en-US'
          const locale = getLocale()
          const lang = locale !== 'en-US' && locale !== 'it-IT'
            ? defaultLocale
            : locale

          if (isUndefined(storeLocale)) {
            store.set('wiki-locale', lang)
          }

          setLocale(storeLocale || lang, false)

          yield put({
            type: 'setLocale',
            payload: storeLocale || lang
          })

        } catch(err) {
          console.log(err)
        }
      },
      { type: 'takeLatest' },
    ],
    fetchOrganization: [
      function* (action, { put }) {
        try {
          yield put({
            type: 'setOrganizationStatus',
            payload: {
              status: 'FETCHING'
            }
          })

          const organizationRes = yield EAMApi.Organization.my()

          yield put({
            type: 'setOrganizationStatus',
            payload: {
              status: 'SUCCESS'
            }
          })

          yield put({
            type: 'setOrganizationData',
            payload: organizationRes.data
          })
        } catch(err) {
          yield put({
            type: 'setOrganizationStatus',
            payload: {
              status: 'ERROR',
              errorMessage: err
            },
          })
          console.log(err)
        }
      },
      { type: 'takeLatest' }
    ],
    fetchClients: [
      function* (action, { put }) {
        yield put({
          type: 'setClientsStatus',
          actionType: {
            name: 'FETCH_CLIENTS',
            status: 'FETCHING',
            err: {}
          }
        })

        try {
          if (action.payload.elType === 'users') {
            yield put({
              type: FETCH_USER_REALMS,
              payload: {
                id: action.payload.currentElId,
              }
            })
          }

          const response = yield EAMApi.Organization.clients()

          yield put({
            type: 'setClients',
            clients: keyBy(response.data, 'id')
          })

          yield put({
            type: 'setCurrentClient',
            currentClientId: !isEmpty(response.data) ? response.data[0].id : '',
          })

          if (action.payload.elType === 'users') {
            yield put({
              type: FETCH_USER_ROLES,
              payload: {
                id: action.payload.currentElId,
                clientId: !isEmpty(response.data) ? response.data[0].id : '',
              }
            })
          } else if (action.payload.elType === 'groups') {
            yield put({
              type: FETCH_GROUP_ROLES,
              payload: {
                id: action.payload.currentElId,
                clientId: !isEmpty(response.data) ? response.data[0].id : '',
              }
            })
          }

          yield put({
            type: 'setClientsStatus',
            actionType: {
              name: 'FETCH_CLIENTS',
              status: 'SUCCESS',
              err: {}
            }
          })

        } catch(err) {
          console.log(err)

          yield put({
            type: 'setClientsStatus',
            actionType: {
              name: 'FETCH_CLIENTS',
              status: 'ERROR',
              err
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    changePasswordEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserProfileStatus',
          actionType: {
            name: 'CHANGE_PASSWORD',
            status: 'RUNNING',
            err: {},
          }
        })

        try {
          const response = yield EAMApi.Me.changePassword(action.payload)

          yield put({
            type: 'setUserProfileStatus',
            actionType: {
              name: 'CHANGE_PASSWORD',
              status: 'SUCCESS',
              err: {},
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'success',
              formatMessage({ id: 'profile.passwordChanged' })
            )
          }
        } catch(err) {
          console.log(err)

          yield put({
            type: 'setUserProfileStatus',
            actionType: {
              name: 'CHANGE_PASSWORD',
              status: 'ERROR',
              err,
            }
          })

          if (action.callback) {
            action.callback(
              true,
              'error',
              err &&
              err.response &&
              err.response.data &&
              err.response.data.detail ||
              formatMessage({ id: 'message.genericError' })
            )
          }
        }
      },
      { type: 'takeLatest' }
    ],
    patchProfileEffect: [
      function* (action, { put }) {
        yield put({
          type: 'setUserProfileStatus',
          actionType: {
            name: 'PATCH_PROFILE',
            status: 'RUNNING',
            err: {},
          }
        })

        try {
          const { payload: { id, user, user: { email, firstName, lastName } } } = action

          yield put({
            type: 'patchProfile',
            payload: {
              email,
              lastName,
              firstName,
            }
          })

          yield EAMApi.Users.patch(id, fromCamelToSnake(user))

          yield put({
            type: 'setUserProfileStatus',
            actionType: {
              name: 'PATCH_PROFILE',
              status: 'SUCCESS',
              err: {},
            }
          })
        } catch(err) {
          console.log(err)

          yield put({
            type: 'setUserProfileStatus',
            actionType: {
              name: 'PATCH_PROFILE',
              status: 'ERROR',
              err,
            }
          })
        }
      },
      { type: 'takeLatest' }
    ],
    setLocaleEffect: [
      function* (action, { put }) {
        try {
          store.set('wiki-locale', action.payload)
          setLocale(action.payload, false)

          yield put({
            type: 'setLocale',
            payload: action.payload,
          })

        } catch (err) {
          console.log(err)
        }
      },
      { type: 'takeLatest' }
    ]
  }
}
