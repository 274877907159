import { Breadcrumb, Typography } from 'antd'
import { get } from 'lodash'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import { WikisContext } from '../../../context/WikisContext'
import * as actions from '../../../store/actions/actions'
import { StyledBreadcrumb } from '../../shared/Breadcrumbs/styled'

const BreadcrumbsSurvey = () => {
  const { state: wikiState } = useContext(WikisContext)
  const { state: surveyState, dispatch: surveyDispatch } =
    useContext(SurveysContext)
  const id = wikiState.currentWikiId
  const wikiName = get(wikiState, `data[${id}].name`, '')
  const currentSurveyId = surveyState.currentSurveyId
  const surveyTitle = get(surveyState, `data[${currentSurveyId}].title`, '')

  const resetCurrentSurvey = () => actions.setCurrentSurvey('', surveyDispatch)

  return (
    <StyledBreadcrumb>
      <Breadcrumb.Item>
        <Link to="/">
          <Typography.Paragraph>Wiki</Typography.Paragraph>
        </Link>
      </Breadcrumb.Item>

      {wikiName && (
        <Breadcrumb.Item onClick={resetCurrentSurvey}>
          <Box is={Typography.Paragraph} ellipsis cursor="pointer">
            {wikiName}
          </Box>
        </Breadcrumb.Item>
      )}

      {surveyTitle && (
        <Breadcrumb.Item>
          <Box is={Typography.Paragraph} ellipsis>
            {surveyTitle}
          </Box>
        </Breadcrumb.Item>
      )}
    </StyledBreadcrumb>
  )
}

export default BreadcrumbsSurvey
