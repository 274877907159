import { get, isNull, isNumber } from 'lodash'
import moment from 'moment'
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { SurveysContext } from '../../../context/SurveysContext'
import * as actions from '../../../store/actions/actions'

const SurveyFooterButtons = ({ theme }) => {
  const { state: surveyState, dispatch } = useContext(SurveysContext)
  const currentSurvey = surveyState.data[surveyState.currentSurveyId]

  const {
    id,
    currentQuestionIndex,
    participation,
    start_date: startDate,
    questions,
    is_expired,
    calculate_score,
    max_attempts,
    showSaved,
    previous_attempts,
    viewResults,
    checkAnswers,
  } = currentSurvey

  const maxAttempts = max_attempts === 0 ? -1 : max_attempts

  const canPartecipate = get(currentSurvey, 'can_partecipate', false)

  const startSurvey = () => {
    actions.setCurrentQuestionIndex(id, 0, dispatch)
    actions.viewResults(id, false, dispatch)
    actions.checkAnswers(id, false, dispatch)
  }

  const prevQuestion = () => {
    actions.setCurrentQuestionIndex(id, currentQuestionIndex - 1, dispatch)
  }

  const nextQuestion = () => {
    actions.setCurrentQuestionIndex(id, currentQuestionIndex + 1, dispatch)
  }

  const onCloseSaved = () => {
    actions.showSaved(id, false, dispatch)
  }

  const resetCurrentSurvey = () => actions.setCurrentSurvey('', dispatch)

  const onViewResults = () => {
    actions.viewResults(id, !viewResults, dispatch)
  }

  const onCheckAnswers = () => {
    actions.checkAnswers(id, !checkAnswers, dispatch)
  }

  // Survey Not Passed
  if (
    calculate_score &&
    isNull(currentQuestionIndex) &&
    !isNull(participation) &&
    !participation.is_passed &&
    participation.attempt_number === maxAttempts
  ) {
    return (
      <Box display="flex" alignItems="center">
        <Box fontWeight="700" color={theme.greyDark} marginRight={27}>
          <FormattedMessage id="surveys.notPassed" />
        </Box>

        <Box
          is="button"
          onClick={resetCurrentSurvey}
          padding={0}
          backgroundColor="white"
          border="none"
          cursor="pointer"
          fontWeight={700}
          textTransform="uppercase"
        >
          <FormattedMessage id="close" />
        </Box>
      </Box>
    )
  }

  // Survey Passed
  if (!isNull(participation) && participation.is_passed) {
    return (
      <Box
        is="button"
        onClick={resetCurrentSurvey}
        padding={0}
        backgroundColor="white"
        border="none"
        cursor="pointer"
        fontWeight={700}
        textTransform="uppercase"
      >
        <FormattedMessage id="close" />
      </Box>
    )
  }

  // Survey Expired
  if (is_expired) {
    return (
      <Box fontWeight="700">
        <FormattedMessage id="surveys.expired" />
      </Box>
    )
  }

  // Attempts finished for Survey without score
  if (
    !calculate_score &&
    !isNull(participation) &&
    participation.attempt_number === maxAttempts &&
    isNull(currentQuestionIndex) &&
    !isNull(participation)
  ) {
    return (
      <Box fontWeight="700" color={theme.greyDark}>
        <FormattedMessage id="surveys.attemptsFinished" />
      </Box>
    )
  }

  // Close check answers
  if (checkAnswers) {
    return (
      <Box
        is="button"
        cursor="pointer"
        fontWeight={700}
        padding={0}
        border="none"
        backgroundColor="white"
        textTransform="uppercase"
        onClick={onCheckAnswers}
      >
        <FormattedMessage id="close" />
      </Box>
    )
  }

  // Close results
  if (viewResults) {
    return (
      <Box
        is="button"
        cursor="pointer"
        fontWeight={700}
        padding={0}
        border="none"
        backgroundColor="white"
        textTransform="uppercase"
        onClick={onViewResults}
      >
        <FormattedMessage id="close" />
      </Box>
    )
  }

  // Start Survey
  if (
    (isNull(currentQuestionIndex) && isNull(participation)) ||
    (isNull(currentQuestionIndex) &&
      !isNull(participation) &&
      !participation.is_passed)
  ) {
    const hasStartDatePassed = () => moment().endOf('day') >= moment(startDate)

    return (
      <div>
        {calculate_score &&
          !isNull(previous_attempts) &&
          previous_attempts.length > 0 && (
            <Box
              is="button"
              cursor="pointer"
              fontWeight={700}
              padding={0}
              marginRight={27}
              border="none"
              backgroundColor="white"
              textTransform="uppercase"
              onClick={onViewResults}
            >
              <FormattedMessage id="surveys.results" />
            </Box>
          )}

        {(canPartecipate || hasStartDatePassed()) && (
          <Box
            is="button"
            padding={0}
            backgroundColor="white"
            border="none"
            cursor="pointer"
            fontWeight={700}
            textTransform="uppercase"
            onClick={startSurvey}
          >
            <FormattedMessage id="surveys.start" />
          </Box>
        )}
      </div>
    )
  }

  // Survey Saved
  if (showSaved) {
    return (
      <Box
        is="button"
        onClick={onCloseSaved}
        padding={0}
        backgroundColor="white"
        border="none"
        cursor="pointer"
        fontWeight={700}
        textTransform="uppercase"
      >
        <FormattedMessage id="close" />
      </Box>
    )
  }

  // Previos and Next Buttons
  if (isNumber(currentQuestionIndex)) {
    return (
      <div>
        <Box
          is="button"
          onClick={prevQuestion}
          padding={0}
          backgroundColor="white"
          border="none"
          cursor="pointer"
          fontWeight={700}
          textTransform="uppercase"
          marginRight={27}
          disabled={currentQuestionIndex === 0}
          color={currentQuestionIndex === 0 ? theme.lightGrey : theme.darkGrey}
        >
          <FormattedMessage id="prev" />
        </Box>

        <Box
          is="button"
          onClick={nextQuestion}
          padding={0}
          backgroundColor="white"
          border="none"
          cursor="pointer"
          fontWeight={700}
          textTransform="uppercase"
          disabled={currentQuestionIndex + 1 === questions.length}
          color={
            currentQuestionIndex + 1 === questions.length
              ? theme.lightGrey
              : theme.darkGrey
          }
        >
          <FormattedMessage id="next" />
        </Box>
      </div>
    )
  }

  return null
}

export default withTheme(SurveyFooterButtons)
