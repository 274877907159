import axios from 'axios'

class BaseObject {
  #client = axios.create()
  #options = null

  constructor(client, options) {
    this.#client = client
    this.#options = options
  }

  get client() {
    return this.#client
  }

  get versioning() {
    try {
      return this.#options.versioning
    } catch {
      return null
    }
  }
}

export {
  BaseObject,
}
