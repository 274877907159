import React, { createContext, useReducer } from 'react'
import * as actionTypes from '../store/actions/actionTypes'
import * as reducers from '../store/reducers/reducers'

const initialState = {
  data: {},
  activeCertificate: null,
  currentSurveyId: '',
  alreadyFetched: false,
  genericSurveysActionType: {
    name: null,
    status: null,
    err: null,
  },
}

export const SurveysContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_GENERIC_WIKIS_ACTION_TYPE:
      return reducers.setGenerictSurveysActionType(state, action.actionType)

    case actionTypes.FETCH_SURVEYS:
      return reducers.fetchSurveys(state, action.surveys)

    case actionTypes.SET_CURRENT_SURVEY:
      return reducers.setCurrentSurvey(state, action.currentSurveyId)

    case actionTypes.SET_CURRENT_QUESTION:
      return reducers.setCurrentQuestionIndex(
        state,
        action.id,
        action.currentQuestionIndex
      )

    case actionTypes.SAVE_PARTICIPATION:
      return reducers.saveParticipation(state, action.id, action.data)

    case actionTypes.SUBMIT_PARTICIPATION:
      return reducers.submitParticipation(
        state,
        action.id,
        action.data,
        action.calculateScore
      )

    case actionTypes.VIEW_RESULTS:
      return reducers.viewResults(state, action.id, action.viewResults)

    case actionTypes.CHECK_ANSWERS:
      return reducers.checkAnswers(state, action.id, action.checkAnswers)

    case actionTypes.SET_SURVEY_RESULTS:
      return reducers.setSurveyResults(state, action.id, action.results)

    case actionTypes.VIEW_CERTIFICATES:
      return reducers.viewCertificates(
        state,
        action.id,
        action.viewCertificates
      )

    case actionTypes.SET_SURVEY_CERTIFICATES:
      return reducers.setSurveyCertificates(
        state,
        action.id,
        action.certificates
      )

    case actionTypes.SHOW_SAVED:
      return reducers.showSaved(state, action.id, action.showSaved)

    case actionTypes.SET_ACTIVE_CERTIFICATE:
      return reducers.setActiveCertificate(state, action.certificate)

    default:
      return state
  }
}

export const SurveysContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <SurveysContext.Provider value={{ state, dispatch }}>
      {children}
    </SurveysContext.Provider>
  )
}
