import { isUndefined } from 'lodash'
import React, { createContext, useReducer } from 'react'
import store from 'store'
import * as actionTypes from '../store/actions/actionTypes'
import * as reducers from '../store/reducers/reducers'
import { defaultLocale } from '../utils/locales'

const storeLocale = store.get('wiki-locale')
const locale = navigator.language

const lang = locale !== 'en-US' && locale !== 'it-IT' ? defaultLocale : locale

if (isUndefined(storeLocale)) {
  store.set('wiki-locale', lang)
}

const initialState = {
  bootstrapped: false,
  organization: {},
  activeItem: '',
  locale: 'it-IT',
  // locale: storeLocale || lang
}

export const GlobalContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORGANIZATION:
      return reducers.bootstrap(state, action.organization, action.theme)

    case actionTypes.SET_WIKI_ACTIVE_ITEM:
      return reducers.setActiveItem(state, action.activeItem)

    case actionTypes.SET_LOCALE:
      return reducers.setLocale(state, action.locale)

    default:
      return state
  }
}

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  )
}
