import WikiApi from '@wikicrm/api'
import ShowMessage from '@wikicrm/backoffice/src/core/utils/showMessage'
import { get } from 'lodash'
import * as actions from '../store/actions/actions'

const saveSurvey = async ({ currentSurvey, savedErrorMessage, dispatch }) => {
  try {
    await WikiApi.Survey.participate(
      currentSurvey.id,
      get(currentSurvey, 'current_attempt.data', {})
    )

    actions.showSaved(currentSurvey.id, true, dispatch)
  } catch (err) {
    console.log(err)
    ShowMessage(true, 'error', savedErrorMessage)
  }
}

export default saveSurvey
