import styled from 'styled-components';
import { Spin } from 'antd';

export const StyledSpin = styled(Spin)`
  && {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${(props) => {
      if (props['data-no-margin']) {
        return 0
      }

      if (props['data-margin-top']) {
        return `${props['data-margin-top']}px`
      }

      return '120px'
    }}

    .ant-spin-dot-item {
      background-color: ${(props) => props['data-bg-color'] ? props['data-bg-color'] : '#1890ff'};
    }
  }
`;
