import React, { useContext } from 'react'
import { Route } from 'react-router'
import { SurveysContext } from '../../../context/SurveysContext'
import { WikisContext } from '../../../context/WikisContext'
import CertificateFooterButtons from '../../certificates/CertificateFooterButtons'
import SearchFooterButtons from '../../search/SearchFooterButtons'
import SurveyFooterButtons from '../../surveys/SurveyFooterButtons'
import WikiLectureNavigationArrows from '../../wikis/WikiLectureNavigationArrows'

const FooterButtons = () => {
  const { state: wikiState } = useContext(WikisContext)
  const { state: surveyState } = useContext(SurveysContext)

  return (
    <>
      <Route exact path="/wiki/:id">
        {wikiState.currentLectureId && <WikiLectureNavigationArrows />}
      </Route>

      <Route exact path="/wiki/:id/survey">
        {surveyState.currentSurveyId && <SurveyFooterButtons />}
      </Route>
      
      <Route exact path="/wiki/:id/certificates">  
        {surveyState.activeCertificate && <CertificateFooterButtons />}
      </Route>

      <Route exact path="/wiki/:id/search">  
        {wikiState.currentLectureId && <SearchFooterButtons />}
      </Route>
    </>
  )
}

export default FooterButtons
