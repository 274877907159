import React from 'react';
import icon from '../../core/hoc/icon';

const SvgInfo = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
      <g
        transform="translate(4.000000, 4.000000)"
        fillRule="nonzero"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path d="M15.8,0.2 L0.2,15.8" id="Path" />
      </g>
      <g
        transform="translate(1.000000, 1.000000)"
        fillRule="nonzero"
        stroke="currentColor"
        strokeWidth={2}
      >
        <rect x="0" y="0" width="22" height="22" />
      </g>
    </g>
  </svg>
);

export default icon(SvgInfo);
