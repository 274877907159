import { Breadcrumb } from 'antd'
import styled from 'styled-components'

export const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 16px;
  font-weight: 700;

  span {
    display: inline-flex;
  }

  div {
    margin-bottom: 0;
    max-width: 200px;
  }
`
