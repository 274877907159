import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Route } from 'react-router'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import Navigation from '../Navigation'
import { StyledButton, StyledLink } from './styled'

const Header = ({ logout, theme, logo }) => {
  return (
    <Box
      is="header"
      gridArea="Header"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="80px"
      minHeight="80px"
      width="100%"
      borderBottom={`1px solid ${theme.borderColor}`}
      padding={22}
    >
      <StyledLink to="/">
        <Box is="img" height="100%" width="auto" src={logo} />
      </StyledLink>
      <Box display="flex" alignItems="center">
        <Route path="/wiki/:id">
          <Navigation />
        </Route>

        <StyledButton onClick={logout}>
          <FormattedMessage id="header.logout" />
        </StyledButton>
      </Box>
    </Box>
  )
}

export default withTheme(Header)
