import { Checkbox, Radio } from 'antd'
import { get, isNull } from 'lodash'
import React from 'react'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { StyledQuestion } from '../SurveyResults/styled'

const SurveyCheckAnwers = ({ currentAttempt, questions, answers }) => {
  const renderAnswers = (question) => {
    const questionAnswers = answers.filter(
      (answer) => answer.question === question.id
    )
    const options = questionAnswers.map((answer) => {
      return {
        label: answer.text,
        value: answer.id,
        key: answer.id,
      }
    })

    switch (question.type) {
      case 1:
        return (
          <Radio.Group
            value={get(currentAttempt, `data[${question.id}][0]`, [])}
            key={!isNull(currentAttempt) ? currentAttempt.id : '-'}
            options={options}
            disabled
          />
        )

      case 2:
        return (
          <Checkbox.Group
            value={get(currentAttempt, `data[${question.id}]`, [])}
            key={!isNull(currentAttempt) ? currentAttempt.id : '-'}
            options={options}
            disabled
          />
        )

      default:
        return null
    }
  }

  return (
    <Box width="100%" maxWidth="100%" padding={80}>
      <div>
        {questions.map((question, i) => {
          return (
            <StyledQuestion key={question.id}>
              <Box fontSize={24}>
                <Box is="span" fontWeight={300} marginRight={10}>
                  {i + 1}
                </Box>{' '}
                <Box is="span" fontWeight={600}>
                  {question.text}
                </Box>
              </Box>

              {renderAnswers(question)}
            </StyledQuestion>
          )
        })}
      </div>
    </Box>
  )
}

export default withTheme(SurveyCheckAnwers)
