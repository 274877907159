import { isNull, isNumber } from 'lodash'
import React, { useContext, useMemo } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withTheme } from 'styled-components'
import Box from 'ui-box'
import { withKeycloakSessionContext } from '../../../context/KeycloakSessionContext'
import { SurveysContext } from '../../../context/SurveysContext'
import saveSurvey from '../../../services/saveSurvey'
import sendSurvey from '../../../services/sendSurvey'
import * as actions from '../../../store/actions/actions'
import SurveySaved from '../SurveySaved'

const SurveyQuestionButtons = ({ intl, theme, kc }) => {
  const { state: surveyState, dispatch } = useContext(SurveysContext)
  const currentSurvey = surveyState.data[surveyState.currentSurveyId]

  const surveysActionType = surveyState.genericSurveysActionType

  const isAdmin = kc.hasRealmRole('organization_admin')

  const {
    id,
    checkAnswers,
    currentQuestionIndex,
    questions,
    calculate_score,
    current_attempt,
    showSaved,
  } = currentSurvey

  const onCheckAnswers = () => {
    actions.checkAnswers(id, !checkAnswers, dispatch)
  }

  const onSendSurvey = () => {
    sendSurvey({
      dispatch,
      id,
      calculate_score,
      surveySentMessage: intl.formatMessage({ id: 'surveys.sent' }),
      surveySentErrorMessage: intl.formatMessage({ id: 'surveys.sent.error' }),
      isAdmin,
    })
  }

  const onSaveSurvey = async () => {
    await saveSurvey({
      currentSurvey,
      savedErrorMessage: intl.formatMessage({ id: 'surveys.saved.error' }),
      dispatch,
    })
  }

  const isBtnDisabled = useMemo(() => {
    if (isNull(current_attempt)) {
      return true
    }

    if (
      surveysActionType.name === 'SUBMIT_PARTICIPATION' &&
      surveysActionType.status === 'RUNNING'
    ) {
      return true
    }

    return false
  }, [current_attempt, surveysActionType.name, surveysActionType.status])

  if (isNumber(currentQuestionIndex)) {
    return (
      <>
        {showSaved && <SurveySaved />}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          padding={20}
        >
          {currentQuestionIndex + 1 === questions.length && (
            <>
              <Box
                is="button"
                cursor="pointer"
                fontWeight={700}
                padding={0}
                border="none"
                backgroundColor="white"
                textTransform="uppercase"
                marginRight={21}
                onClick={onCheckAnswers}
                color={isBtnDisabled ? theme.greyLight : theme.greyDark}
                disabled={isBtnDisabled}
              >
                <FormattedMessage id="surveys.check" />
              </Box>
              <Box
                is="button"
                cursor="pointer"
                fontWeight={700}
                padding={0}
                border="none"
                backgroundColor="white"
                textTransform="uppercase"
                marginRight={21}
                onClick={onSendSurvey}
                color={isBtnDisabled ? theme.greyLight : theme.greyDark}
                disabled={isBtnDisabled}
              >
                <FormattedMessage id="surveys.send" />
              </Box>
            </>
          )}

          <Box
            is="button"
            cursor="pointer"
            fontWeight={700}
            padding={0}
            border="none"
            backgroundColor="white"
            textTransform="uppercase"
            color={isBtnDisabled ? theme.greyLight : theme.greyDark}
            disabled={isBtnDisabled}
            onClick={onSaveSurvey}
          >
            <FormattedMessage id="surveys.save" />
          </Box>
        </Box>
      </>
    )
  }

  return null
}

export default withKeycloakSessionContext(
  injectIntl(withTheme(SurveyQuestionButtons))
)
