import {Icon, Menu} from "antd";
import React, { useState } from "react";
import {withRouter} from "react-router";
import {
  CollapsedButton,
  WrapMenu,
  MenuText
} from './styled'
import {isNullOrUndefined} from "util";
import {CircularButton} from "../../components/CircularButton";

const renderItems = (listItems, history, count = 1) => {	
	const goTo = (path) => {
		history.push({pathname: path});
	}

	return listItems.map(item => {
		if (item.visible) {
			return (
				isNullOrUndefined(item.submenu) ? (
					<Menu.Item key={`sub${item.link}`} onClick={() => goTo(item.link)} onItemHover={() => {}}>
						{item.icon}
						<MenuText>{item.title}</MenuText>
					</Menu.Item>
		
				) : (
					<Menu.SubMenu
						key={`sub${item.link}`}
						style={{zIndex: 1}}
						title={
							<span>
								{item.icon}
								<MenuText>{item.title}</MenuText>
							</span>
						}
					>
						{renderItems(item.submenu, history)}
					</Menu.SubMenu>
				)
			)
		}

		return null
	}
)};

export const NavigationMenu = withRouter(({listItems, history}) => {
	const [collapsed, toggleCollapsed] = useState(false);
	const pathname = history.location.pathname
	let tab = pathname.split('/')[1]

	const getSelectedKeys = () => {
		switch (tab) {
			case '':
				return ['sub/']
			case 'groups':
				tab = 'users'
				return [`sub/${tab}/`]
			default:
				return	[`sub/${tab}/`]
		}
	}

		return (
			<WrapMenu collapsed={collapsed}>
				<Menu
					mode="inline"
					inlineCollapsed={!collapsed}
					selectedKeys={getSelectedKeys()}
				>
					{renderItems(listItems, history, 1)}
					<CollapsedButton>
						<CircularButton onClick={() => toggleCollapsed(!collapsed)}>
							<Icon type={collapsed ? "left" : "right"}/>
						</CircularButton>
					</CollapsedButton>
				</Menu>
			</WrapMenu>
		);
	})
;
