export default {
  'app.title': 'WikiWeb Ita',
  'header.logout': 'Logout',
  wikis: 'Wikis',
  download: 'Download',
  'download.notAvailable': 'Download non disponibile',
  error: 'Errore',
  'access.denied': 'Accesso non consentito',
  'wiki.emptyNode': 'Sezione Vuota',
  'wiki.noData': 'Nessun dato disponibile',
  'wiki.empty': 'Non ci sono sezioni in questa wiki.',
  'wiki.empty.lectures': 'Non ci sono contenuti in questa wiki.',
  'wiki.empty.lectures.filtered': 'Non ci sono contenuti per questa ricerca.',
  'wiki.loadingPdf': 'Caricamento PDF...',
  'wiki.loadingIframe': 'Caricamento lettura...',
  'wiki.loadingPdfPage': 'Caricamento Pagina...',
  'wiki.pdfPrevPage': 'Pagina Precedente',
  'wiki.pdfNextPage': 'Pagina Successiva',
  'wiki.pdfPageOfPages': 'Pagina {pageNumber} di {numPages}',
  'wiki.noPreviewNoDownload':
    'Questo file non dispone della preview e non può essere scaricato.',
  'wiki.previewNotAvailable': 'Preview non disponibile.',
  'wiki.content': 'Contenuto',
  'wiki.selectContent':
    'Seleziona una lettura o una survey per visualizzarne il contenuto.',
  'wiki.noSectionsOrSurveys': 'Non ci sono sezioni o survey in questa wiki.',
  surveys: 'Survey',
  'surveys.noContent': 'Non ci sono survey associate a questa Wiki.',
  'surveys.startDate': 'Data di inizio',
  'surveys.endDate': 'Data di fine',
  'surveys.about': 'Riguardo la survey',
  'surveys.start': 'Inizia',
  'surveys.results': 'Risultati',
  'surveys.send': 'Invia',
  'surveys.check': 'Controlla',
  'surveys.save': 'Salva',
  'surveys.attempts': 'Tentativi',
  'surveys.saved': 'Survey salvata con successo.',
  'surveys.saved.error':
    'Si è verificato un errore durante il salvataggio. Si prega di riprovare.',
  'surveys.sent': 'Survey inviata con successo.',
  'surveys.sent.error':
    "Si è verificato un errore durante l'invio. Si prega di riprovare.",
  'surveys.noDescription': 'La survey non presenta una descrizione.',
  'surveys.passed': 'Survey passata.',
  'surveys.notPassed': 'Survey non passata.',
  'surveys.attemptsFinished': 'Tentativi terminati.',
  'surveys.expired': 'Questa survey è terminata.',
  'surveys.yourAnswers': 'Le tue risposte',
  'surveys.previousAttempt': 'Il tuo tentativo precedente',
  'surveys.answer.correct': 'Corretto',
  'surveys.answer.wrong': 'Sbagliato',
  'surveys.score': 'Punteggio',
  'surveys.certificates': 'Certificati',
  'surveys.passed.congratulations': 'Complimenti hai superato il test',
  'surveys.passed.text': 'Hai risposto correttamente al {score}% delle domande',
  'certificates.noContent':
    'I certificati per questa survey non sono disponibili.',
  'certificates.download': 'Scarica il certificato',
  'Certificate of completion': 'Certificato survey',
  'Personal certificate': 'Certificato personale',
  prev: 'Indietro',
  next: 'Avanti',
  close: 'Chiudi',
  search: 'Cerca',
  'search.keyword': 'Parola chiave',
}
