import React from 'react'
import { Route } from 'react-router'
import BreadcrumbsCertificates from '../../certificates/BreadcrumbsCertificates'
import BreadcrumbsSurvey from '../../surveys/BreadcrumbsSurvey'
import BreadcrumbsWiki from '../../wikis/BreadcrumbsWiki'

const Breadcrumbs = () => {
  return (
    <>
      <Route exact path="/wiki/:id">
        <BreadcrumbsWiki />
      </Route>

      <Route exact path="/wiki/:id/survey">
        <BreadcrumbsSurvey />
      </Route>

      <Route exact path="/wiki/:id/certificates">
        <BreadcrumbsCertificates />
      </Route>
    </>
  )
}

export default Breadcrumbs
