import WikiApi from '@wikicrm/api'
import * as actions from '../store/actions/actions'

const getSurveyResults = async ({ currentSurvey, dispatch }) => {
  try {
    const res = await WikiApi.Survey.getPreviousAttemptResults(
      currentSurvey.id,
      currentSurvey.previous_attempts[0].id
    )
    actions.setSurveyResults(currentSurvey.id, res.data, dispatch)
  } catch (err) {
    console.log(err)
  }
}

export default getSurveyResults
