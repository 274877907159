import React from 'react';
import icon from '../../core/hoc/icon';

const SuccessIcon = props => (
  <svg
    {...props}
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_67_245)">
      <circle cx="56" cy="52" r="52" fill="#63C779" />
      <path
        d="M47.7598 76.9787L25.7897 54.3158C24.4698 52.9543 24.4698 50.7467 25.7897 49.385L30.5696 44.4542C31.8896 43.0925 34.0298 43.0925 35.3497 44.4542L50.1498 59.7209L65.9998 43.371L81.8499 27.0212C83.1698 25.6596 85.31 25.6596 86.63 27.0212L91.4099 31.952C92.7298 33.3135 92.7298 35.5211 91.4099 36.8828L52.5398 76.9789C51.2198 78.3404 49.0797 78.3404 47.7598 76.9787Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_67_245"
        x="0"
        y="0"
        width="112"
        height="112"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_67_245" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_67_245" result="shape" />
      </filter>
    </defs>
  </svg>
);

const SuccessCheckIcon = icon(SuccessIcon);

export default SuccessCheckIcon;
