import { BaseObject } from '../BaseObject'

class SurveyApi extends BaseObject {
  constructor(client, options) {
    super(client, options)
  }

  getAll() {
    return this.client.get(`api/${this.versioning}/surveys/`)
  }

  getLive(wiki = null) {
    return this.client.get(`api/${this.versioning}/surveys/live/${wiki && `?wiki=${wiki}`}`)
  }

  participate(surveyId, answers) {
    return this.client.put(`api/${this.versioning}/surveys/${surveyId}/participation/`, {
      answers: answers,
    })
  }

  getAttemptResults(surveyId, attemptId) {
    return this.client.get(`api/${this.versioning}/surveys/${surveyId}/attempts/${attemptId}/`)
  }

  submit(surveyId) {
    return this.client.post(`api/${this.versioning}/surveys/${surveyId}/participation/submit/`)
  }

  create(data) {
    return this.client.post(`api/${this.versioning}/surveys/`, data)
  }

  delete(surveyId) {
    return this.client.delete(`api/${this.versioning}/surveys/${surveyId}/`)
  }

  patch(surveyId, data) {
    return this.client.patch(`api/${this.versioning}/surveys/${surveyId}/`, data)
  }

  getDetails(surveyId) {
    return this.client.get(`api/${this.versioning}/surveys/${surveyId}/`)
  }

  getAssignees(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/assign/`)
  }

  assign(id, data) {
    return this.client.put(`api/${this.versioning}/surveys/${id}/assign/`, data)
  }

  getQuestions(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/questions/`)
  }

  createQuestion(id, data) {
    return this.client.post(`api/${this.versioning}/surveys/${id}/questions/`, data)
  }

  createAnswer(id, questionId, data) {
    return this.client.post(`api/${this.versioning}/surveys/${id}/questions/${questionId}/answers/`, data)
  }

  getAnswers(id, questionId) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/questions/${questionId}/answers/`)
  }

  deleteQuestion(id, questionId) {
    return this.client.delete(`api/${this.versioning}/surveys/${id}/questions/${questionId}/`)
  }

  patchQuestion(id, questionId, data) {
    return this.client.patch(`api/${this.versioning}/surveys/${id}/questions/${questionId}/`, data)
  }

  patchAnswer(id, questionId, answerId, data) {
    return this.client.patch(`api/${this.versioning}/surveys/${id}/questions/${questionId}/answers/${answerId}/`, data)
  }

  deleteAnswer(id, questionId, answerId) {
    return this.client.delete(`api/${this.versioning}/surveys/${id}/questions/${questionId}/answers/${answerId}/`)
  }

  publish(id, data) {
    return this.client.post(`api/${this.versioning}/surveys/${id}/publish/`, data)
  }

  unpublish(id, data) {
    return this.client.post(`api/${this.versioning}/surveys/${id}/unpublish/`, data)
  }

  getResults(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/analytics/results/`)
  }

  downloadResults(id, type) {
    if (type === 'csv') {
      return this.client.get(`api/${this.versioning}/surveys/${id}/analytics/results/download/`, {
        headers: {
          "Accept": "text/csv"
        }
      })
    }

    return this.client.get(`api/${this.versioning}/surveys/${id}/analytics/results/download/excel/`, {
      responseType: 'blob',
      exposedHeaders: ['Content-Disposition'],
    })
  }

  getParticipations(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/analytics/participations/`)
  }

  getCertificates(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/participation/certificates/`)
  }

  downloadParticipations(id, type) {
    if (type === 'csv') {
      return this.client.get(`api/${this.versioning}/surveys/${id}/analytics/participations/download/`, {
        headers: {
          "Accept": "text/csv"
        }
      })
    }

    return this.client.get(`api/${this.versioning}/surveys/${id}/analytics/participations/download/excel/`, {
      responseType: 'blob',
      exposedHeaders: ['Content-Disposition'],
    })
  }

  optionsSettingsNotifications(id) {
    return this.client.options(`api/${this.versioning}/surveys/${id}/settings/notifications/`)
  }

  getSettingsNotifications(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/settings/notifications/`)
  }

  patchSettingsNotifications(id, data) {
    return this.client.patch(`api/${this.versioning}/surveys/${id}/settings/notifications/`, data)
  }

  getPreviousAttemptResults(id, attemptId) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/attempts/${attemptId}/`)
  }

  getLiveSurveyDetail(id) {
    return this.client.get(`api/${this.versioning}/surveys/${id}/live/`)
  }

  getCertificates(surveyId) {
    return this.client.get(`api/${this.versioning}/surveys/${surveyId}/participation/certificates/`)
  }
}

export default SurveyApi
