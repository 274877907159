export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION'
export const SET_GENERIC_WIKIS_ACTION_TYPE = 'SET_GENERIC_WIKIS_ACTION_TYPE'
export const FETCH_WIKIS = 'FETCH_WIKIS'
export const SET_CURRENT_WIKI = 'SET_CURRENT_WIKI'
export const SET_CURRENT_SECTIONS = 'SET_CURRENT_SECTIONS'
export const SET_CURRENT_LECTURES = 'SET_CURRENT_LECTURES'
export const SET_CURRENT_FILE = 'SET_CURRENT_FILE'
export const SET_SECTION_STATUS = 'SET_SECTION_STATUS'
export const RESET_CURRENT_FILE = 'RESET_CURRENT_FILE'
export const SET_CURRENT_LECTURE = 'SET_CURRENT_LECTURE'
export const FETCH_SURVEYS = 'FETCH_SURVEYS'
export const SET_GENERIC_SURVEYS_ACTION_TYPE = 'SET_GENERIC_SURVEYS_ACTION_TYPE'
export const SET_WIKI_ACTIVE_ITEM = 'SET_WIKI_ACTIVE_ITEM'
export const SET_CURRENT_SURVEY = 'SET_CURRENT_SURVEY'
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION'
export const SAVE_PARTICIPATION = 'SAVE_PARTICIPATION'
export const SUBMIT_PARTICIPATION = 'SUBMIT_PARTICIPATION'
export const VIEW_RESULTS = 'VIEW_RESULTS'
export const SHOW_SAVED = 'SHOW_SAVED'
export const CHECK_ANSWERS = 'CHECK_ANSWERS'
export const SET_SURVEY_RESULTS = 'SET_SURVEY_RESULTS'
export const VIEW_CERTIFICATES = 'VIEW_CERTIFICATES'
export const SET_SURVEY_CERTIFICATES = 'SET_SURVEY_CERTIFICATES'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_ACTIVE_CERTIFICATE = 'SET_ACTIVE_CERTIFICATE'
